
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './styles.module.scss'
import expertise from "../../../assets/images/WebDevelopment/whychoose/expertise.webp";
import innovative from "../../../assets/images/WebDevelopment/whychoose/innovative.webp";
import customize from "../../../assets/images/WebDevelopment/whychoose/custom.webp";
import quality from "../../../assets/images/WebDevelopment/whychoose/reliability.webp";
import delivery from "../../../assets/images/WebDevelopment/whychoose/on-time.webp";
import responsive from "../../../assets/images/WebDevelopment/whychoose/responsive-design.webp";
import seo from "../../../assets/images/WebDevelopment/whychoose/analysis.webp";
import customerservice from "../../../assets/images/WebDevelopment/whychoose/customer-service.webp";


export default function SoftwareWhyChoose() {

const [whychooseArr,SetWhyChooseArr] = useState([
    {
        image:expertise,
        heading:"Software Expertise",
        para:"AYB Infotech has invested immensely in Software team expansion and has a well-established team of Software experts." 
    },
    {
        image:innovative,
        heading:"Innovative Approach",
        para:" We employ innovative strategies, enabling the creation of cutting-edge websites that stand out.", 
    },
    {
        image:customize,
        heading:"Customization",
        para:" Our team tailors each website meticulously, ensuring they precisely meet unique client requirements.", 
    },
    {
        image:quality,
        heading:"Quality Assurance",
        para:" AYB Infotech ensures top-notch quality across all aspects of development, guaranteeing client satisfaction.", 
    },
    {
        image:delivery,
        heading:"Timely Delivery",
        para:"We prioritize timely project delivery, maintaining high standards without compromising speed.", 
    },
    {
        image:responsive,
        heading:"Responsive Designs",
        para:"  Our websites boast responsive designs, optimizing user experience across various devices seamlessly.", 
    },
    {
        image:seo,
        heading:"SEO Integration",
        para:"  We integrate effective SEO strategies, maximizing online visibility and expanding reach for clients.", 
    },
    {
        image:customerservice,
        heading:"Customer Support",
        para:"AYB Infotech offers dedicated customer support throughout the development journey, ensuring client peace of mind.", 
    },
])

  return (
   <>
      <section className={styles.why_choose_sec}>
        <Container>
          <Row>
            
              <div className={styles.heading_box}>
                <h2 className={styles.main_heading}>
                Why Choose <span> Ebslon Infotech </span>  for Software Development
                </h2>
                <p className={styles.desc}>
                  Choose Ebslon Infotech as your Software Development company, and
                  experience the difference of a dedicated team committed to
                  elevating your online presence and driving tangible business
                  results.
                </p>
              </div>
            
          </Row>

          <Row className={styles.pt_40}>

{
    whychooseArr && whychooseArr?.map((el,index)=>(
        <Col xl={3} lg={3} md={6} key={index}>
        <div className={styles.card}>
          <div className={styles.icon}>
          <img
            src={el.image}
            alt=""
            
          />
          </div>
          <div className={styles.content}>
            <h5>{el.heading}</h5>
            <p className={styles.desc}>
             {el.para}
            </p>
          </div>
        </div>
      </Col>
    ))
}

           

          
          </Row>
        </Container>
      </section>
   
   
   </>
  )
}
