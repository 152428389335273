import React from 'react'
import { Col, Row, Container, Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
function SupplyChainFAQ() {
  return (
   <>
   
   <section className="faq-area py-40">
        <Container>
          <Row className="py-2">
            <Col xl={8} className="mx-auto features-head">
              <h2>
                Frequently Asked <span>Questions</span>
              </h2>
              {/* <p className="desc">
              </p> */}
            </Col>
          </Row>

          <Row className="pt-3 faq-accordion">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                What is supply chain management (SCM)?
         


                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
                SCM is the coordination and management of a network of interconnected businesses involved in the provision of product and service packages. It encompasses the flow of goods, information, and finances as products move from origin to consumer.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                What are the key components of supply chain management?
                 


                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
                Key components include planning, sourcing, production, delivery, and returns. These elements ensure that products are produced and distributed efficiently and effectively.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                How can technology improve supply chain management?
              


                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
                Technology enhances SCM through automation, data analytics, and real-time tracking. Tools like ERP systems, IoT, and AI can improve efficiency, reduce costs, and enhance visibility across the supply chain.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                What is the role of logistics in supply chain management?
                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
              
                 Logistics involves the transportation, warehousing, and distribution of goods. It is crucial for ensuring that products are delivered to the right place at the right time, optimizing inventory levels, and minimizing costs.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                How do companies manage supply chain risks?
               
                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
                Companies manage risks through diversification of suppliers, maintaining safety stock, developing contingency plans, and utilizing risk assessment tools to identify and mitigate potential disruptions.

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                What is the importance of sustainability in supply chain management?

                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
            
                Sustainability in SCM is vital for reducing environmental impact, improving brand reputation, and meeting consumer demand for ethical practices. It includes responsible sourcing, efficient logistics, and waste reduction.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                How can supply chain performance be measured?
            

                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
                Performance can be measured using key performance indicators (KPIs) such as order accuracy, delivery time, inventory turnover, and customer satisfaction. These metrics help assess efficiency and effectiveness.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7">
                <Accordion.Header>
                What are some common challenges in supply chain management?
              

                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
                Common challenges include supply chain disruptions (e.g., due to natural disasters), fluctuating demand, supplier reliability, inventory management, and maintaining visibility across the supply chain.
                </Accordion.Body>
              </Accordion.Item>
     
           
            </Accordion>
          </Row>
        </Container>
      </section>
   </>
  )
}

export default SupplyChainFAQ
