import React, { useEffect, useState } from "react";

import { Container, Row, Col } from "react-bootstrap";
import styles from './ourwork.module.scss'


import Anillo from "../../assets/images/EbslonLandingPage/img/portfolio/Anillo.webp";
import Aahilya from "../../assets/images/EbslonLandingPage/img/portfolio/Aahilya.webp";
import Artisaga from "../../assets/images/EbslonLandingPage/img/portfolio/Artisaga.webp";
import Belmonk from "../../assets/images/EbslonLandingPage/img/portfolio/Belmonk.webp";
import Meraki from "../../assets/images/EbslonLandingPage/img/portfolio/Meraki.webp";

import Scentoria from "../../assets/images/EbslonLandingPage/img/portfolio/Scentoria.webp";
import toneitup from "../../assets/images/EbslonLandingPage/img/portfolio/toneitup.webp";
import SabkaAddress from "../../assets/images/EbslonLandingPage/img/portfolio/SabkaAddress.webp";
import DSR from "../../assets/images/EbslonLandingPage/img/portfolio/DSR.webp";
import fasto from "../../assets/images/EbslonLandingPage/img/portfolio/fasto.webp";
import ModernMart from "../../assets/images/EbslonLandingPage/img/portfolio/Modernmart.webp";
import Nikkypore from "../../assets/images/EbslonLandingPage/img/portfolio/Nikkypore.webp";
import plywoodbazar from "../../assets/images/EbslonLandingPage/img/portfolio/plywoodbazar.webp";
import rocare from "../../assets/images/EbslonLandingPage/img/portfolio/Rocare.webp";
import samagri from "../../assets/images/EbslonLandingPage/img/portfolio/samagri.webp";
import turningpoint from "../../assets/images/EbslonLandingPage/img/portfolio/Turningpoint.webp";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay } from "swiper/modules";
import AOS from "aos";
import ContactModalBox from "./ContactModalBox";

function HomepageWork() {

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

  const workArr = [
    {
      image: Anillo,
      heading: "Anillo",
      para: " Showcasing our tailor-made eCommerce solution for a remarkable team of jewelers, who turn dreams into reality.",
    },
    {
      image: Meraki,
      heading: "Meeraki",
      para: "  Meraki is a non-toxic holistic hair care brand. Our products are inspired by the practice of ancient crystal healing.",
    },
    {
      image: toneitup,
      heading: "ToneItUp",
      para: "Tone It Up’s nutrition line is made for women, by  women.Tone It Up nutrition products are dairy-free, gluten-free, and never made with sugar alcohols. TIU is the top selling protein at Target nationwide!",
    },
    {
      image: Aahilya,
      heading: "Aahilya",
      para: " We started Aahilya to help provide a source of income for the rural craftspeople who have been effected by the COVID-19 Virus and lockdown. We want to encourage people to buy the handmade",
    },
    {
      image: Artisaga,
      heading: "Artisaga",
      para: " Artisaga is a design and sourcing platform enabling  small and medium businesses present globally to source wholesale artisanal and consciously made Home & Living goods.",
    },
    {
      image: Belmonk,
      heading: "Belmonk",
      para: "Belmonk is ready-to-wear brand with an experience surpassing two generations believes in creating versatile yet powerful apparels that offers the bestselection of Quirky.",
    },
    {
      image: Scentoria,
      heading: "Scentoria",
      para: "Scentoria, a renowned fragrance platform. We crafted their user-friendly and immersive website, enhancing the experience for perfume connoisseurs worldwide.",
    },
    {
      image: Scentoria,
      heading: "Scentoria",
      para: "Scentoria, a renowned fragrance platform. We crafted their user-friendly and immersive website, enhancing the experience for perfume connoisseurs worldwide.",
    },
    {
      image: DSR,
      heading: "DSR",
      para: "Enhance your style with our exquisite collection of watches. From elegant timepieces to sporty designs,find the perfect watch to complement your personality and elevate your fashion statement.",
    },
    {
      image: ModernMart,
      heading: "Modern Mart",
      para: "Discover a world of endless shopping possibilities on our e-commerce site. Explore a vast range of products, enjoy seamless browsing, and indulge in convenient online shopping from the comfort of your home.",
    },
    {
      image: fasto,
      heading: "Fasto",
      para: "Fasto and reliable product delivery right to your  doorstep. We ensure prompt and secure delivery of your items, so you can enjoy your purchase without any hassle.",
    },
    {
      image: plywoodbazar,
      heading: "Plywood Bazar",
      para: "Premium plywood for all your construction and  woodworking needs. Choose from a variety of grades and thicknesses. Experience the quality difference today!",
    },
    {
      image: rocare,
      heading: "RoCare India",
      para: "Professional water purifier service for clean and healthy drinking water. Our experts will ensure your  water purifier is properly maintained and serviced to keep your family safe and hydrated.",
    },
    {
      image: Nikkypore,
      heading: "Nikkypore",
      para: "Wide range of filters available for various applications. From water filters to air filters, we have the right solution to meet your needs. Ensure clean and pure air and water with our high-quality filter options.",
    },
    {
      image: samagri,
      heading: "Samagri",
      para: "Complete your religious rituals with our premium pooja samagri. From incense sticks to sacred oils, we offer a wide selection of authentic products to enhance your spiritual practices.",
    },
    {
      image: turningpoint,
      heading: "Samagri",
      para: "Transform your space with our exquisite furniture collection. From elegant sofas to stylish tables, we  offer a wide range of high-quality furniture that combines comfort, functionality, and aesthetic appeal.",
    },
    {
      image: SabkaAddress,
      heading: "Sabka Address",
      para: "Never forget a location again with our address-saving app. Easily save and organize addresses of your favorite stores, restaurants, and more. Effortlessly access directions whenever you need them, making navigation a breeze. Stay organized and always find your way!",
    },
  ];

  // AOS.init();
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      {/* portfolio-sec */}
      <section className={styles.work_sec}>
        <Container>
        <div className={styles.heading_box}>
                <h2
                  className={styles.main_heading}
                  data-aos="fade-up"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  A Glimpse of <span>Our Work</span>
                </h2>
              </div>
          <Row className={styles.pt_50}>
          <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                speed={3000}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: true,
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                {
                    workArr && workArr.map((el,index)=>(
<SwiperSlide key={index}>
                  <Row className={`align-items-center justify-content-around`}>
                    <Col xl={6} lg={6} md={7}>
                      <div className={styles.work_img} data-aos="flip-up">
                        <img src={el.image} alt={`${el.heading}`} loading="lazy"  />
                      </div>
                    </Col>
                    <Col xl={5} lg={5} md={5}>
                      <div className={styles.work_content} data-aos="flip-up">
                        <h3 className={styles.heading}>
                          <span> {el.heading}</span>{" "}
                        </h3>
                        <p className={styles.desc}>
                          {el.para}
                        </p>
                        <p className={styles.mt_25}>
                          <button
                            type="button"
                            className={styles.demo_buttn}
                            onClick={handleShow}
                          >
                            Request For Demo
                          </button>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </SwiperSlide>
                    ))
                }
                


        
              </Swiper>
          </Row>
        </Container>
      </section>

      <ContactModalBox show={show} setShow={setShow}  handleShow={handleShow} handleClose={handleClose} />
    </>
  );
}

export default HomepageWork;
