import React from 'react'
import styles from './styles.module.scss'

export default function SoftwareBenefits() {
  return (
    <>
    <section className={styles.software_benefits}>
<div className="container">
    <div className="row">
   
            
            <div className={styles.heading_box}>
              <h2 className={styles.main_heading}>
              Benefits of  <span> Ebslon Infotech </span> Custom Software Development Services
              </h2>
              <p className={styles.desc}>
              With technical excellence and extensive engineering experience, Ebslon Infotech cultivates a culture of engagement to deliver a solution tailored to your specific goals and objectives.
              </p>
            </div>
          
       
    </div>

    <div className={`row ${styles.pt_40}`}>
        <div className={`col-xl-4 col-lg-4 col-md-6 ${styles.b_r} ${styles.b_d}`}>
            <div className={styles.card}>
<h5>END-TO-END SOFTWARE SOLUTIONS</h5>
<p>
created via a full spectrum of IT services, extensive technical expertise, and well-adjusted solution design
</p>
            </div>
        </div>
        <div className={`col-xl-4 col-lg-4 col-md-6 ${styles.b_r} ${styles.b_d}`}>
            <div className={styles.card}>
<h5>QUALITY SOFTWARE PRODUCTS</h5>
<p>
as a result of effective service delivery, certified proficiency, cutting-edge technology, and an efficient SDLC
</p>
            </div>
        </div>
        <div className={`col-xl-4 col-lg-4 col-md-6  ${styles.b_d}`}>
            <div className={styles.card}>
<h5>COST EFFICIENCY
</h5>
<p>
achieved with optimized resource provisioning and a lean technical design that is tailored to each scenario
</p>
            </div>
        </div>
        <div className={`col-xl-4 col-lg-4 col-md-6 ${styles.b_r}`}>
            <div className={styles.card}>
<h5>CONSTRAINT MANAGEMENT
</h5>
<p>
with a unique framework for accelerated development that brings extra value and minimizes project risks
</p>
            </div>
        </div>
        <div className={`col-xl-4 col-lg-4 col-md-6 ${styles.b_r} `}>
            <div className={styles.card}>
<h5>TECHNICAL EXCELLENCE
</h5>
<p>
gained by virtue of strategic talent management and an extensive talent pool for fast team scaling
</p>
            </div>
        </div>
        <div className={`col-xl-4 col-lg-4 col-md-6 ${styles.b_rr}  `}>
            <div className={styles.card}>
<h5>OPERATIONAL FLEXIBILITY AND VISIBILITY
</h5>
<p>

ensured via well-organized change management, proactive problem solving, and transparent communication

</p>
            </div>
        </div>
    </div>
</div>
    </section>
    
    
    </>
  )
}
