import React,{useState} from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import aboutimg from '../../../../assets/images/supplychainmanagement/about/aboutimg.png'
import styles from './styles.module.scss'
// import { Link } from 'react-router-dom'
import ContactModalBox from '../../../HomePageComponents/ContactModalBox'

function SupplyAboutus() {

    
  const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
  return (
   <>
    <section className={styles.digital_aboutus}>
        <Container>
          <Row className={styles.py_20}>
            <Col xl={6} lg={6}>
              <div className={styles.image}>
                <img
                  src={aboutimg}
               
                  alt="aboutimg"
                />
              </div>
            </Col>

            <Col xl={6} lg={6} >
              <div className={styles.content}>
                <h2 className={styles.main_heading}>
                  {" "}
                  Enhancing Supply Chain Performance with Tailored Solutions
                </h2>
              

               <p className={styles.desc}>
               At Ebslon Infotech, we enhance supply chain performance with tailored solutions that meet the unique needs of our clients. With experience empowering over 50 businesses, our dedicated team of experts collaborates with you at every stage, ensuring optimal integration of supply chain processes. We specialize in developing custom applications that streamline operations, improve visibility, and enhance decision-making. From eCommerce platforms to ERP systems, our solutions drive value and efficiency. If your organization values technological advancement, we are the ideal partner to elevate your supply chain management strategy.
                </p>
              
                <p className={styles.buttn_holder}>
                  {" "}
                  <button type='button' onClick={handleShow} className={styles.rm_buttn}>
                  Get A Free Demo
                  </button>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
   
<ContactModalBox show={show} setShow={setShow}  handleShow={handleShow} handleClose={handleClose} />
   
   </>
  )
}

export default SupplyAboutus