import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { images } from "./Utility/Images";
import AOS from "aos";
import { FaCheck } from "react-icons/fa";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay } from "swiper/modules";
import ContactModalBox from "./HomePageComponents/ContactModalBox";

function NikkyPoreCaseStudy() {

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  AOS.init();

  return (
    <>
      {/* breadcrumb-for-caseStudy */}

      <section className="breadcrumb-area">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <div className="breadcrumb-casestudy-box">
                <ul className="breadcrumb-casestudy-list">
                  <li>
                    <Link to="/">Home </Link> <span>/</span>{" "}
                  </li>
                  <li>
                    <Link to="/case-studies">Case Study</Link> <span>/</span>{" "}
                  </li>
                  <li>
                    <Link to="/ROCareIndiaCaseStudy">NikkyPore Filters </Link>{" "}
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Nikkipore-main-banner-sec */}

      <section className="NikkyPore-banner-sec">
        <Container>
          <Row className="j-c-sb">
            <Col
              xl={4}
              lg={5}
              md={6}
              className="NikkyPore-banner-content"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="logo-img">
                <img
                  src={images.NikkyPoremainlogo}
                  alt=""
                  className="img-fluid"
                />
              </div>

              <h1 className="main-heading">
                Customized eCommerce Platform for Diverse User Types
              </h1>

              <p className="desc">
                <span className="sub-head">NikkyPore Filters: </span> NIKKYPORE
                FILTRATION SYSTEMS PVT. LTD. is a leading provider of high
                quality filtration, separation and purification products for a
                wide range of industries and applications.
              </p>

              <div className="NikkyPore-intro-list-box">
                <ul className="NikkyPore-intro-list">
                  <li>
                    {" "}
                    <span className="sub-head"> Product : </span>Filters
                  </li>
                  <li>
                    {" "}
                    <span className="sub-head"> Industry : </span> Ecommerce
                  </li>
                  <li>
                    {" "}
                    <span className="sub-head"> Services : </span> UI/UX Design,
                    App Development
                  </li>
                  <li>
                    {" "}
                    <span className="sub-head"> Technology Stack : </span> React
                    Js, React Native, Node.js, MongoDB
                  </li>
                </ul>
              </div>

              <p className='mt-30'>
              <button type='button' onClick={handleShow} className='transform-button'>Contact Us</button> </p>  
            </Col>

            <Col
              xl={8}
              lg={7}
              md={6}
              className="NikkyPore-banner-img"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <img
                src={images.NikkyPoremainbanner}
                alt=""
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      </section>

      {/* project-outline-sec */}
      <section className="Nikkypore-project-summary py-40">
        <Container>
          <Row className="pb-50">
            <Col xl={4}>
              <div className="Nikkypore-main-head">
                <h2 className="main-heading">
                  Project <span>Summary</span>{" "}
                </h2>
              </div>
            </Col>
            <Col xl={8}>
              <div className="Nikkypore-content">
                <p className="desc">
                  This project aimed to deliver a robust, feature-rich
                  e-commerce platform catering to multiple user types including
                  admins, sub-admins, customers, wholesalers, retailers, and
                  factory employees. With an in-depth approach to role
                  definition, pricing, order management, and user interaction,
                  the application was built to ensure streamlined operations,
                  clear communication, and user-specific access privileges.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className="Nikkypore-project-summary-img">
                <img
                  src={images.NikkyPoreaboutus}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* goals and objectives  */}
      <section className="Nikkypore-goals-objectives py-40">
        <Container>
          <Row className="pb-3">
            <Col xl={10} className="mx-auto">
              <div className="Nikkypore-goals-objectives-main-head">
                <h2 className="main-heading">
                  {" "}
                  <span>Goals </span> and Objectives
                </h2>
                {/* <p className="desc">The app focuses on managing children's needs and simplifying communication between co-parents, either resident or non-resident of UK. Our main aim is to provide our users with a simple, effective and secure platform that can help them keep track of transactions and visitation made for their child.</p> */}
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col xl={4} lg={4}>
              <div className="goals-obj-content-box">
                <div className="goals-obj-content-card">
                  <h5>User Differentiation</h5>
                  <p className="desc">
                    Creation of unique interfaces for admins, sub-admins,
                    factory employees, customers, and other users, ensuring each
                    has access only to relevant features.
                  </p>
                </div>
                <div className="goals-obj-content-card">
                  <h5>Streamlined Operations</h5>
                  <p className="desc">
                    Empower admins with full CRUD operations on sub-admins,
                    products, categories, and user types while ensuring a smooth
                    user journey for other user types.
                  </p>
                </div>
              </div>
            </Col>

            <Col xl={4} lg={4}>
              <div className="goals-obj-img-box">
                <img src={images.NikkyPoregoals} alt="" className="img-fluid" />
              </div>
            </Col>

            <Col xl={4} lg={4}>
              <div className="goals-obj-content-box">
                <div className="goals-obj-content-card">
                  <h5>Effective Communication</h5>
                  <p className="desc">
                    A chat module was embedded to enable real-time
                    communication, with added functionality to share files,
                    bolstering the user-admin relationship.
                  </p>
                </div>
                <div className="goals-obj-content-card">
                  <h5>Dynamic Pricing & Discounts</h5>
                  <p className="desc">
                    Admins were equipped to define prices for different user
                    categories and apply both global and user-specific
                    discounts, optimizing sales and revenue.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* features-sec */}

      <section className="Nikkypore-features-sec py-40">
        <div className="overlay-Nikkypore-features"></div>
        <div className="position-relative-z-index-1">
          <Container>
            <Row className="pb-3">
              <Col xl={10} className="mx-auto">
                <div className="features-sec-main-head">
                  <h2 className="main-heading">Features (CustomersApp)</h2>
                </div>
              </Col>
            </Row>

            <Row className="pt-3">
              <Col xl={4} lg={4} className="mt-3">
                <div className="customer-app-features-content">
                  <div className="white-box">
                    <div className="featured-list-box">
                      <ul className="featured-list">
                        <li>
                          {" "}
                          <span>1</span>{" "}
                        </li>
                        <li>
                          <ul className="inner-featured-list">
                            <li>
                              {" "}
                              <h4>Customized User Profiles : </h4>
                            </li>
                            <li>
                              {" "}
                              <p className="desc">
                                Tailored views for each user, emphasizing
                                relevant functionalities like CRUD operations
                                for admins and product searching for users.
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="white-box">
                    <div className="featured-list-box">
                      <ul className="featured-list">
                        <li>
                          {" "}
                          <span>2</span>{" "}
                        </li>
                        <li>
                          <ul className="inner-featured-list">
                            <li>
                              {" "}
                              <h4>Interactive Chat Module : </h4>
                            </li>
                            <li>
                              {" "}
                              <p className="desc">
                                Enabled real-time discussion, query resolution,
                                and file sharing, enhancing user trust and
                                satisfaction.
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xl={4} lg={4} className="mt-3">
                <div className="customer-app-features-img">
                  <img
                    src={images.NikkyPorefeatures1}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </Col>

              <Col xl={4} lg={4} className="mt-3">
                <div className="customer-app-features-content">
                  <div className="white-box">
                    <div className="featured-list-box">
                      <ul className="featured-list">
                        <li>
                          {" "}
                          <span>3</span>{" "}
                        </li>
                        <li>
                          <ul className="inner-featured-list">
                            <li>
                              {" "}
                              <h4>Dynamic Product Search:</h4>
                            </li>
                            <li>
                              {" "}
                              <p className="desc">
                                Using advanced algorithms, the search function
                                yields accurate and swift results, refining the
                                shopping experience.
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="white-box">
                    <div className="featured-list-box">
                      <ul className="featured-list">
                        <li>
                          {" "}
                          <span>4</span>{" "}
                        </li>
                        <li>
                          <ul className="inner-featured-list">
                            <li>
                              {" "}
                              <h4>Offline Payment System:</h4>
                            </li>
                            <li>
                              {" "}
                              <p className="desc">
                                By integrating an offline payment system, the
                                platform ensures flexibility and trust,
                                especially for large, bulk orders.
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            {/* <Row className='pt-3'>

  <Col xl={4} lg={4} className='mt-3'>
    <div className="customer-app-features-content">
<div className="white-box">
  
<div className="featured-list-box">
<ul className="featured-list">
  <li> <span>1</span> </li>
  <li>
    <ul className="inner-featured-list">
      <li> <h4>Login : </h4></li>
      <li> <p className="desc">Users can log in by entering their phone number.</p></li>
    </ul>
      </li>
</ul>
</div>


</div>
<div className="white-box">
  
<div className="featured-list-box">
<ul className="featured-list">
  <li> <span>2</span> </li>
  <li>
    <ul className="inner-featured-list">
      <li> <h4>Sign up : </h4></li>
      <li> <p className="desc">Sign up with the necessary details like name, email, phone number, city, state, etc.</p></li>
    </ul>
      </li>
</ul>
</div>


</div>
<div className="white-box">
  
<div className="featured-list-box">
<ul className="featured-list">
  <li> <span>3</span> </li>
  <li>
    <ul className="inner-featured-list">
      <li> <h4>OTP Verification:</h4></li>
      <li> <p className="desc">Users can enter the OTP from their mobile number for validation.</p></li>
    </ul>
      </li>
</ul>
</div>


</div>


    </div>
  </Col>

  <Col xl={4} lg={4} className='mt-3'>
    <div className="customer-app-features-img">
      <img src={images.NikkyPorefeatures2} alt="" className='img-fluid' />
    </div>
  </Col>

  <Col xl={4} lg={4} className='mt-3'>
    <div className="customer-app-features-content">
<div className="white-box">
  
<div className="featured-list-box">
<ul className="featured-list">
  <li> <span>1</span> </li>
  <li>
    <ul className="inner-featured-list">
      <li> <h4>Forgot Password :</h4></li>
      <li> <p className="desc">OTP will be sent to the user's mobile number to verify their account.</p></li>
    </ul>
      </li>
</ul>
</div>


</div>
<div className="white-box">
  
<div className="featured-list-box">
<ul className="featured-list">
  <li> <span>2</span> </li>
  <li>
    <ul className="inner-featured-list">
      <li> <h4>Home :</h4></li>
      <li> <p className="desc">On the Home page, users can see their level, points, offers, and newsletters.</p></li>
    </ul>
      </li>
</ul>
</div>


</div>
<div className="white-box">
  
<div className="featured-list-box">
<ul className="featured-list">
  <li> <span>3</span> </li>
  <li>
    <ul className="inner-featured-list">
      <li> <h4>Gift Store :</h4></li>
      <li> <p className="desc">Users can redeem their points from the gift store. The gift will be delivered to the address or can pick up from nearby gift centers by the user's choice.</p></li>
    </ul>
      </li>
</ul>
</div>


</div>


    </div>
  </Col>


</Row> */}
          </Container>
        </div>
      </section>

      {/* challenges-solutions */}

      <section className="challenges-and-solutions-sec py-40">
        <Container>
          <Row className="pb-3">
            <Col xl={10} className="mx-auto">
              <div className="challenges-and-solutions-main-head">
                <h2 className="main-heading"> Challenges & Solutions </h2>
              </div>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col xl={4} lg={4}>
              <div className="challenges-sec">
                {/* <h4>Challenges and Solutions :</h4> */}
                <ul className="challenges-content-list">
                  <li>
                    <FaCheck /> <h6>Multiple User Types</h6>
                    <p className="desc">
                    A tiered role-based access control was implemented to handle different user types without compromising on data security or user experience.
                    </p>
                  </li>
                  <li>
                    {" "}
                    <FaCheck />
                    <h6>Real-time Communication
 </h6>
                    <p className="desc">
                    The integrated chat module was optimized for low latency and high responsiveness, ensuring smooth and immediate conversations.
                    </p>
                  </li>
                  <li>
                    {" "}
                    <FaCheck />
                    <h6>Dynamic Pricing
 </h6>
                    <p className="desc">
                    An adaptable pricing matrix was developed to allow admins to easily adjust prices based on user types or promotions.
                    </p>
                  </li>
                  <li>
                    {" "}
                    <FaCheck />
                    <h6>Handling a Vast Product Catalog
</h6>
                    <p className="desc">
                    Implemented advanced database optimizations and indexing to ensure fast, efficient CRUD operations and search results.
                    </p>
                  </li>
                </ul>
              </div>
            </Col>

            <Col xl={4} lg={4}>
              <div className="challenges-solutions-img">
                <img
                  src={images.NikkyPorechallengesandsolutions}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Col>

            <Col xl={4} lg={4}>
              <div className="challenges-sec">
                {/* <h4>Solutions :</h4> */}
                <ul className="challenges-content-list">
                  <li>
                    <FaCheck /> <h6>User Approval Mechanism
 </h6>
                    <p className="desc">
                    A notification system was developed to alert admins of new business user registrations, streamlining the approval process.
                    </p>
                  </li>
                  <li>
                    {" "}
                    <FaCheck />
                    <h6>Offline Payments
 </h6>
                    <p className="desc">
                    Developed a robust order tracking and verification system that operates seamlessly with offline payment confirmations.
                    </p>
                  </li>
                  <li>
                    {" "}
                    <FaCheck />
                    <h6>Unique SKU but Non-unique Product Names
 </h6>
                    <p className="desc">
                    The database design ensured SKU uniqueness while allowing flexibility in product naming, aided by the dynamic search function.
                    </p>
                  </li>
                  <li>
                    {" "}
                    <FaCheck />
                    <h6>Multiple Currency Support
</h6>
                    <p className="desc">
                    Integrated a currency conversion tool and localized billing for transactions made outside India, ensuring clear and transparent pricing for all users.
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* visual-designs-sec */}

      <section className="visual-designs-sec">
        <Container>
          <Row className="py-4">
            <Col xl={10} className="mx-auto">
              <div className="visual-designs-main-head">
                <h2 className="main-heading"> Visual Design </h2>
                <p className="desc">
                The customized eCommerce platform developed successfully tackled the multifaceted needs of a diverse user base. By addressing unique challenges with innovative solutions, the application not only streamlined operations but also fostered trust and enhanced user experience. With its dynamic pricing, efficient search functionality, and role-specific customization, the platform stands as a testament to the power of tailored application development.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="p-0">
          <div className="visual-designs-wrapper">
            <Row className="pt-3">
              <Col xl={12}>
                <Swiper
                  slidesPerView={4}
                  spaceBetween={20}
                  speed={900}
                  loop={true}
                  // pagination={{
                  //   clickable: true,
                  // }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 30,
                    },

                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                  }}
                  className="mySwiper py-5"
                >
                  <SwiperSlide>
                    <div className="visual-designs-slide">
                      <img
                        src={images.NikkyPorevdslide1}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="visual-designs-slide">
                      <img
                        src={images.NikkyPorevdslide2}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="visual-designs-slide">
                      <img
                        src={images.NikkyPorevdslide3}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="visual-designs-slide">
                      <img
                        src={images.NikkyPorevdslide4}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="visual-designs-slide">
                      <img
                        src={images.NikkyPorevdslide5}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="visual-designs-slide">
                      <img
                        src={images.NikkyPorevdslide6}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="visual-designs-slide">
                      <img
                        src={images.NikkyPorevdslide7}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="visual-designs-slide">
                      <img
                        src={images.NikkyPorevdslide8}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="visual-designs-slide">
                      <img
                        src={images.NikkyPorevdslide9}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="visual-designs-slide">
                      <img
                        src={images.NikkyPorevdslide10}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
  
  
      <ContactModalBox show={show} setShow={setShow}  handleShow={handleShow} handleClose={handleClose} />
    </>
  );
}

export default NikkyPoreCaseStudy;
