import React, {  useState } from 'react'
import "./indexpopup.css";
import rocket from '../../assets/images/popupmodal/rocket.gif'
import popupimg from '../../assets/images/popupmodal/popupimg2.gif'
import { toastError, toastSuccess } from "../../utils/toast";
import { InputGroup, Form, Modal } from "react-bootstrap";
import { addLandingPage } from '../../services/landingPage.service';

function ContactModalBox({show,handleClose,setShow}) {


  
  
  
    const [firstName, setFirstName] = useState("");
  
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
  
     const [solution,setSolution]= useState("");
  
    const handleSubmit = async () => {
      try {
        if (firstName === "") {
          toastError("Please Enter Your Name!");
          return;
        }
       
        if (phone === "") {
          toastError("Please Enter Your Phone Number");
          return;
        }
        if (phone && phone.length !== 10) {
          toastError(
            "Invalid Phone Number provided , phone number must be 10 digits !!!"
          );
          return;
        }
        if (email === "") {
          toastError("Please Enter Your Email");
          return;
        }
       
        if (email && !(email.includes("@") && email.includes("."))) {
          toastError("Invalid Email provided !!!");
          return;
        }
        
      if (solution === "" || solution === undefined) {
        toastError("Please Select Your Solution");
        return
  
      }
  
        let obj = {
          firstName,
        
          phone,
          email,
         
          solution,
          page: window.location.pathname,
        };
  
        let { data: res } = await addLandingPage(obj);
  
  
  
        if (res.message) {
          toastSuccess(res.message);
          setFirstName("");
          setEmail("");
          setPhone("");
          setEmail("");
          setSolution("");
         
          handleClose(true);
        }else {
        toastError(res.message);
  
        }
  
     
  
      } catch (err) {
  
        toastError(err);
      }
    };

 return (
    <>
     <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="popup_contact_form_box">
            <h4 className="form-head">Request a callback</h4>
            <p className="desc">
              Request a callback for a quick discussion on your software
              development needs.
            </p>
            <img src={rocket}  className="popup_rocket" alt="popup_rocket"/>

            <div className="row">
            <div className="col-xl-7 col-lg-7 col-md-7 align-items-center d-flex">
                <div className="rocket_img">
                <img src={popupimg} alt="rocketimg" />
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5">
              <div className="contact-form-body">
              <div className="row">
                <div className="col-xl-12">
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Name"
                      aria-label="Username"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </div>
                <div className="col-xl-12">
                  <InputGroup className="mb-3">
                    <Form.Control
                    type="number"
                      placeholder="Phone Number"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                    />
                  </InputGroup>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12">
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Email"
                      aria-label="Username"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </div>
                {/* <div className="col-xl-12">
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Company Name"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </div> */}
              </div>

              <div className="row">
                <div className="col-xl-12 footer-form mb-3 select_box">

                <select onChange={(e) => setSolution(e.target.value)} value={solution}  >
                              <option selected>Select Solution</option>
                              <option value="SoftwareDevelopment">Software Development</option>
                              <option value="WebAppDevelopment">Web App Development</option>
                              <option value="CustomCRM">Custom CRM</option>
                              <option value="ERPSoftwareDevelopment"> ERP Software Development</option>
                              <option value="AR/VRDevelopment ">AR/VR Development</option>
                              <option value="IoTDevelopment"> IoT Development </option>
                              <option value="Microservices"> Microservices </option>
                              <option value="DigitalMarketing"> Digital Marketing </option>
                              <option value="AppDevelopment"> App Development </option>
                              <option value="Ecommerce">Ecommerce</option>
                              <option value="RetailPOS">Retail POS</option>
                              <option value="Others">Others</option>
                            </select>
                 
                </div>
              </div>

              {/* <div className="row">
                <div className="col-xl-12">
                  <Form.Control
                    as="textarea"
                    placeholder="Message"
                    style={{ minHeight: "100px" }}
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    className="mb-3 message-textarea"
                  />
                </div>
              </div> */}

              <p className="t-c">
                <button
                type="submit"
                  onClick={() => handleSubmit()}
                  className="submit_buttn"
                  
                >
                  Go
                </button>
              </p>
            </div>
              </div>
             
            </div>
         
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ContactModalBox
