import React from "react";
import styles from  './styles.module.scss'
// import { Link } from "react-router-dom";
import softwarebanner from '../../../assets/images/softwaredevelopment/Banner/bannerimg1.webp'


function SoftwareBanner() {
  return (
    <>
      <section className={styles.about_banner}>
        <div className="container">
          <div className={`row ${styles.j_c_sb} align-items-center`}>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className={styles.content}>
                <h1 className={styles.heading}>
                Custom  <span>  Software Development </span>  Company in India
                
                </h1>
             
                <p className={styles.desc}>
                Ebslon Infotech's software development services In India are designed to create custom, scalable, and robust solutions that cater to the unique needs of each client. Our expert team of developers, utilizing the latest technologies and methodologies, collaborates closely with clients to deliver software applications that drive efficiency, innovation, and competitive advantage.
                
                </p>

                <p className={styles.buttn_holder}>
                  <a href="#contactform" className={styles.buttn}>
                   Reach Out Now
                  </a>
                </p>
              </div>
            </div>
            <div className="col-xl-5 col-md-6">
              <div className={styles.image}>
                <img src={softwarebanner} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

    
    </>
  );
}

export default SoftwareBanner;
