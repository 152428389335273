import React from 'react'
import styles from './styles.module.scss';
import { images } from '../../../Utility/Images';
export default function WebTechnologies() {
  return (
    <>
    <section className={styles.web_tech}>
        <div className="container">
          <div className="row">
            <div className={styles.heading_box}>
              <h2 className={styles.main_heading}>
            <span> Web Technologies</span> , We've Expertise In
              </h2>
            </div>
          </div>
          <ul className={`row ${styles.pt_30}`}>
          <li className={styles.col_auto}>
              <div className={styles.box}>
               
                 <div className={styles.image}>
                <img src={images.reactjs}  alt="" />
               
                </div>
                <h6>React js</h6>
              </div>
            </li>
            <li className={styles.col_auto}>
              <div className={styles.box}>
                 <div className={styles.image}>
                <img src={images.angular}  alt="" />
                </div>
                <h6>ANGULAR</h6>
              </div>
            </li>
            <li className={styles.col_auto}>
              <div className={styles.box}>
                 <div className={styles.image}>
                <img src={images.techimgicon5}  alt="" />
                </div>
                <h6>Javascript</h6>
              </div>
            </li>
            <li className={styles.col_auto}>
              <div className={styles.box}>
                 <div className={styles.image}>
                <img src={images.html}  alt="" />
                </div>
                <h6>HTML</h6>
              </div>
            </li>
            <li className={styles.col_auto}>
              <div className={styles.box}>
                 <div className={styles.image}>
                <img src={images.css}  alt="" />
                </div>
                <h6>Css</h6>
              </div>
            </li>
           
            
            <li className={styles.col_auto}>
              <div className={styles.box}>
                 <div className={styles.image}>
                <img src={images.mysql}  alt="" />
                </div>
                <h6>Mysql</h6>
              </div>
            </li>
            <li className={styles.col_auto}>
              <div className={styles.box}>
                 <div className={styles.image}>
                <img src={images.nodejs}  alt="" />
                </div>
                <h6>Node js</h6>
              </div>
            </li>
            <li className={styles.col_auto}>
              <div className={styles.box}>
                 <div className={styles.image}>
                <img src={images.php}  alt="" />
                </div>
                <h6>PHP</h6>
              </div>
            </li>
            <li className={styles.col_auto}>
              <div className={styles.box}>
                 <div className={styles.image}>
                <img src={images.postgresql}  alt="" />
                </div>
                <h6>Postgresql</h6>
              </div>
            </li>
            <li className={styles.col_auto}>
              <div className={styles.box}>
                 <div className={styles.image}>
                <img src={images.python}  alt="" />
                </div>
                <h6>Python</h6>
              </div>
            </li>
          
            
            <li className={styles.col_auto}>
              <div className={styles.box}>
                 <div className={styles.image}>
                <img src={images.mongo}  alt="" />
                </div>
                <h6>Mongo DB</h6>
              </div>
            </li>
            <li className={styles.col_auto}>
              <div className={styles.box}>
                 <div className={styles.image}>
                <img src={images.experessjs}  alt="" />
                </div>
                <h6>Experess Js</h6>
              </div>
            </li>
           
          </ul>
        </div>
      </section>
    
    </>
  )
}
