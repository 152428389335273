import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { images } from "./Utility/Images";
import { FaUserCheck } from "react-icons/fa";
import { TiLocation } from "react-icons/ti";
import { BiCategory } from "react-icons/bi";
import { FiUsers, FiSettings } from "react-icons/fi";
import ContactModalBox from "./HomePageComponents/ContactModalBox";

function SamagriCaseStudy() {


    
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <>
      {/* breadcrumb-for-caseStudy */}

      <section className="breadcrumb-area">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <div className="breadcrumb-casestudy-box">
                <ul className="breadcrumb-casestudy-list">
                  <li>
                    <Link to="/">Home </Link> <span>/</span>{" "}
                  </li>
                  <li>
                    <Link to="/case-studies">Case Study</Link> <span>/</span>{" "}
                  </li>
                  <li>
                    <Link to="/PlywoodBazarCaseStudy">Samagri</Link>{" "}
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* samagri-banner-sec */}

      <section className="samagri-banner-sec">
        <Container>
          <div className="samagri-banner-shape">
            <img src={images.Samagrihalfelipse} alt="" className="img-fluid" />
          </div>
          <Row className="j-c-sb">
            <Col xl={6} lg={6}>
              <div className="samagri-banner-img">
                <img
                  src={images.Samagribannerimg}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Col>
            <Col xl={5} lg={5}>
              <div className="samagri-banner-content">
                <h1 className="main-heading">
                  <span>Samagri :</span> Welcome to Our Exquisite Collection of
                  Online Puja Items
                </h1>
                <p className="desc">
                  Ebslon Infotech has partnered with Samagri, a leading Puja
                  Items brand in India, since 2016 to craft the end-to-end
                  digital experience - from the website to native apps.
                </p>
                <p className="desc1">
                  Our partnership involving design, development and testing
                  powers the ecommerce experience of a retail giant.
                </p>

            
                <p className='mt-25'>
                <button type='button' onClick={handleShow} className='samagri-contactus-buttnn'>Contact Us</button> </p>  
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* samagri-aboutus-sec */}

      <section className="samagri-aboutus-sec">
        <Container>
<Row className="pb-50">
    <Col xl={12} className="samagri-aboutus-wrap-box">
    <Row>
            <Col xl={4}>
              <div className="aboutus-client-content">
                <ul className="aboutus-client-content-list">
                  <li>
                    <span>
                      {" "}
                      <FaUserCheck />
                    </span>
                    <span className="sub-heading">Client :</span>{" "}
                    <span className="main-heading">Samagri</span>
                  </li>
                  <li>
                    <span>
                      {" "}
                      <TiLocation />
                    </span>
                    <span className="sub-heading">Location :</span>{" "}
                    <span className="main-heading">India</span>
                  </li>
                  <li>
                    <span>
                      {" "}
                      <BiCategory />
                    </span>
                    <span className="sub-heading">Category :</span>{" "}
                    <span className="main-heading">Retail & Ecommerce</span>
                  </li>
                  <li>
                    <span>
                      {" "}
                      <FiUsers />
                    </span>
                    <span className="sub-heading">Peak team size :</span>{" "}
                    <span className="main-heading">20</span>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={7}>
              <div className="aboutus-our-content">
                <ul className="aboutus-our-content-list">
                  <li>
                    <span>
                      <FiSettings />
                    </span>{" "}
                    <Link className="sub-heading">Our Services :</Link>
                    <Link className="main-heading">Design </Link>
                    <Link className="main-heading">
                      | Project Management{" "}
                    </Link>{" "}
                    <Link className="main-heading">| Application </Link>
                    <Link className="main-heading">
                      Development & Maintenance |
                    </Link>
                    <Link className="main-heading"> Application Security</Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
    </Col>
</Row>

         
<Row className="pb-50">
    <Col xl={12} className="samagri-aboutus-wrap-box1">
        <Row className="j-c-sb">
            <Col xl={6} lg={6}>
<div className="samagri-aboutus-content-box">
<h2 className="main-heading">
    <span className="d-block">Samagri</span> 
    Project Summary
</h2>
<p className="desc">
Our client sought to create a robust online platform to cater to the spiritual needs of the vast diaspora of devotees globally. The primary aim was to provide an intuitive e-commerce platform offering an exquisite collection of Puja items, making it convenient for users to find and purchase items crucial for their religious ceremonies and rituals.
</p>

</div>
            </Col>
            <Col xl={5} lg={5}>
<div className="samagri-aboutus-img-box">
<div className="samagri-logo-img">
    <img src={images.Samagriaboutuslogo} alt="" className="img-fluid" />
</div>
<div className="samagri-big-img">
<img src={images.Samagriaboutusimg} alt="" className="img-fluid" />
</div>





    </div>



            </Col>
        </Row>
    </Col>
</Row>

        </Container>
      </section>


{/* samagri-project-highlights */}

<section className="samagri-project-highlights py-40">
  <Container>
    <Row className="py-5">
      <Col xl={12}>
        <div className="samagri-project-highlights-wrapbox">
          <Row>
          <Col xl={5} lg={5}>
  <div className="samagri-project-highlights-img">
    
<img src={images.Samagriprojecthighlights} alt="" className="img-fluid" />
<div className="circle">
<img src={images.Samagriphighlightcircle} alt="" className="img-fluid" />
<div className="circle-content">
  <h2 className="main-heading">
    <span>Project</span>
  Highlights
  </h2>
</div>
</div>

  </div>

</Col>
<Col xl={7} lg={7} className="samagri-project-highlights-content">
<Row>

<div className="project-highlights-content-list">
  <Row className="align-items-center">
    <Col xl={2}>
      <div className="project-highlights-content-icon">
<img src={images.Samagrihighlight1} alt="" className="img-fluid" />
      </div>
    </Col>
    <Col xl={10}>
      <div className="project-highlights-content-area">
        <h4 className="text-white">User-Friendly Interface</h4>
<p className="desc">The platform is designed with a simplistic and intuitive UI to ensure that users of all age groups can effortlessly navigate and make purchases.</p>
      </div>
    </Col>
  </Row>
</div>

<div className="project-highlights-content-list">
  <Row className="align-items-center">
    <Col xl={2}>
      <div className="project-highlights-content-icon">
<img src={images.Samagrihighlight2} alt="" className="img-fluid" />
      </div>
    </Col>
    <Col xl={10}>
      <div className="project-highlights-content-area">
        <h4 className="text-white">
        Diverse Catalogue
        </h4>
<p className="desc">The e-commerce site boasts an expansive inventory of Puja items, catering to various rituals, ceremonies, and religious events.</p>
      </div>
    </Col>
  </Row>
</div>

<div className="project-highlights-content-list">
  <Row className="align-items-center">
    <Col xl={2}>
      <div className="project-highlights-content-icon">
<img src={images.Samagrihighlight3} alt="" className="img-fluid" />
      </div>
    </Col>
    <Col xl={10}>
      <div className="project-highlights-content-area">
        <h4 className="text-white">
        Secure Payment Gateway
        </h4>
<p className="desc">To enhance trust and ensure a smooth transaction process, the platform integrates a highly secure payment gateway.</p>
      </div>
    </Col>
  </Row>
</div>

<div className="project-highlights-content-list">
  <Row className="align-items-center">
    <Col xl={2}>
      <div className="project-highlights-content-icon">
<img src={images.Samagrihighlight4} alt="" className="img-fluid" />
      </div>
    </Col>
    <Col xl={10}>
      <div className="project-highlights-content-area">
        <h4 className="text-white">Multilingual Support</h4>
<p className="desc">Recognizing the diverse user base, the site provides multilingual support, catering to devotees from different linguistic backgrounds.</p>
      </div>
    </Col>
  </Row>
</div>

</Row>


</Col>
          </Row>
        </div>
      </Col>

    </Row>
  </Container>
</section>

{/* our-screens-sec */}

<section className="samagri-work-screens py-40">
<Container>
  <Row>
<Col xl={4} md={5}>
  <div className="samagri-work-screen-content">
    <h2 className="main-heading">
      
  <span> More than 50</span> 
          Screens and Journeys
    </h2>
    <p className="desc">In this section, visual representations of the website's design can be showcased, giving stakeholders a feel for the aesthetic and functional elements of the platform.</p>
  </div>
</Col>
  </Row>
</Container>
</section>

{/* samagri-features */}

<section className="samagri-features-sec py-40">
  <Container>
    <Row className="pb-2 j-c-sb">
<Col xl={6} lg={6}>
<div className="samagri-features-main-head">
  <h2 className="main-heading"><span>Samagri</span>  Features</h2>
  {/* <p className="desc">Vouching for feature integration with the agile development approach makes Hanchens is a revolutionary food ordering & delivery app.</p> */}
</div>

<Row className="pt-3">

  <Col xl={4} md={4} xs={6}>
    <div className="samagri-features-card">
      <div className="samagri-features-icon">
<img src={images.Samagrifeatures3step} alt="" className="img-fluid" />
      </div>
       <h6 className="card-heading">Advanced Search & Filter</h6>
    </div>
  </Col>

  <Col xl={4} md={4} xs={6}>
    <div className="samagri-features-card">
      <div className="samagri-features-icon">
<img src={images.Samagrifeatureseasyreplacement} alt="" className="img-fluid" />
      </div>
       <h6 className="card-heading">Virtual Puja Kit Customization</h6>
    </div>
  </Col>

  <Col xl={4} md={4} xs={6}>
    <div className="samagri-features-card">
      <div className="samagri-features-icon">
<img src={images.Samagrifeaturesoffers} alt="" className="img-fluid" />
      </div>
       <h6 className="card-heading">User Reviews & Ratings</h6>
    </div>
  </Col>

  <Col xl={4} md={4} xs={6}>
    <div className="samagri-features-card">
      <div className="samagri-features-icon">
<img src={images.Samagrifeaturessecure} alt="" className="img-fluid" />
      </div>
       <h6 className="card-heading">Subscription Model</h6>
    </div>
  </Col>

  <Col xl={4} md={4} xs={6}>
    <div className="samagri-features-card">
      <div className="samagri-features-icon">
<img src={images.Samagrifeaturesreasonableprice} alt="" className="img-fluid" />
      </div>
       <h6 className="card-heading">Real-time Inventory Management</h6>
    </div>
  </Col>

  <Col xl={4} md={4} xs={6}>
    <div className="samagri-features-card">
      <div className="samagri-features-icon">
<img src={images.Samagrifeaturescustomerservice} alt="" className="img-fluid" />
      </div>
       <h6 className="card-heading">Customer Service</h6>
    </div>
  </Col>
 
</Row>
</Col>

<Col xl={5} lg={5}>
  <div className="samagri-features-img">
    <img src={images.Samagrifeaturesimg} alt="" className="img-fluid" />
  </div>
</Col>
    </Row>
  </Container>
</section>

{/* samagri-challenges-sec */}

<section className="samagri-challenges-sec py-40">
<Container>
  <Row className="">
<Col xl={10} className="mx-auto">
  <div className="samagri-challenges-main-head">
    <h2 className="main-heading">Challenges and Solutions</h2>
  </div>
</Col>
  </Row>
  <Row className="pt-3 j-c-sb">
    <Col xl={4} lg={4}>
      <div className="samagri-challenges-content">
<div className="samagri-challenges-card">
<div className="samagri-challenges-card-heading">
  <h4 className="main-heading">
  Diverse Product Catalogue Management.

  </h4>
 
</div>
<p className="desc">
Implemented an AI-powered categorization tool that automatically sorts and lists products in relevant categories.
  </p>
</div>

<div className="samagri-challenges-card">
<div className="samagri-challenges-card-heading">
  <h4 className="main-heading">
  Maintaining Cultural Authenticity.

  </h4>
 
</div>
<p className="desc">
Collaborated with religious scholars and practitioners to ensure the items and descriptions remain true to traditions.
  </p>
</div>

<div className="samagri-challenges-card">
<div className="samagri-challenges-card-heading">
  <h4 className="main-heading">
  Maintaining Cultural Authenticity.

  </h4>
 
</div>
<p className="desc">
Collaborated with religious scholars and practitioners to ensure the items and descriptions remain true to traditions.
  </p>
</div>





      </div>
    </Col>

    <Col xl={4} lg={4}>
      <div className="samagri-challenges-img">
<img src={images.Samagrichallenges} alt="" className="img-fluid" />
      </div>
    </Col>

    <Col xl={4} lg={4}>
      <div className="samagri-challenges-content">
<div className="samagri-challenges-card">
<div className="samagri-challenges-card-heading">
  <h4 className="main-heading">
  Diverse Product Catalogue Management.

  </h4>
 
</div>
<p className="desc">
Implemented an AI-powered categorization tool that automatically sorts and lists products in relevant categories.
  </p>
</div>

<div className="samagri-challenges-card">
<div className="samagri-challenges-card-heading">
  <h4 className="main-heading">
  Maintaining Cultural Authenticity.

  </h4>
 
</div>
<p className="desc">
Collaborated with religious scholars and practitioners to ensure the items and descriptions remain true to traditions.
  </p>
</div>

<div className="samagri-challenges-card">
<div className="samagri-challenges-card-heading">
  <h4 className="main-heading">
  Maintaining Cultural Authenticity.

  </h4>
 
</div>
<p className="desc">
Collaborated with religious scholars and practitioners to ensure the items and descriptions remain true to traditions.
  </p>
</div>


      </div>
    </Col>

  </Row>
</Container>
</section>

{/* samagri-contact-banner */}

<section className="samagri-contact-banner">
<div className="position-relative-z-index-1">
      <Container>
        <Row>
<Col xl={10} className="mx-auto">
<div className="samagri-contact-banner-content">
  <h2 className="main-heading">Other Splendid Work By Us</h2>
  <p className="desc">The development of the Exquisite Collection of Online Puja Items platform represents a synergy of tradition and technology. By addressing specific challenges with tailored solutions, the platform has become a bridge connecting devotees with the spiritual essentials they seek. Its success underscores the importance of user-centric design, cultural sensitivity, and leveraging technology to cater to niche market needs.</p>
  <p className="mt-30"> <Link to="/contact-us" className="visit-site-buttn">Get Started</Link></p>
</div>
</Col>
        </Row>
      </Container>
      </div>
    </section>


    <ContactModalBox show={show} setShow={setShow}  handleShow={handleShow} handleClose={handleClose} />
    </>
  );
}

export default SamagriCaseStudy;
