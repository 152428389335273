import React from "react";
import bannerplywood from "../../assets/images/topbanner/bannerimgplywod.webp";
import certifi11 from "../../assets/images/topbanner/certificate1.webp";
import certificate2 from "../../assets/images/topbanner/certificate2.webp";
import certificate3 from "../../assets/images/topbanner/certificate3.webp";
import certificate4 from "../../assets/images/topbanner/certificate4.webp";


function Homebannersectoin({ handleShow }) {
  return (
    <>
      <section className="homebannersectoin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 col-sm-7 col-md-7 col-12 ">
              <div className="bannerheading">
                <h1>Empowering Businesses with Cutting-Edge Solutions</h1>
                <h2>Web and App Development Strategies</h2>
                <p>
                  We assist individuals and businesses in creating Web And
                  Mobile Applications swiftly, Cost-Effectively, and with
                  greater efficiency by linking startups with pre- screened
                  development teams, specifically curated for their projects.
                </p>
                <h6>Our Certificate</h6>
                <ul className="certificate_img_list">
                  <li>
                    <img src={certifi11} alt="certifi11" />{" "}
                  </li>
                  <li>
                    <img src={certificate2} alt="certificate2" />{" "}
                  </li>
                  <li>
                    <img src={certificate3} alt="certificate3" />{" "}
                  </li>
                  <li>
                    <img src={certificate4} alt="certificate4" />{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-sm-5 col-md-5 col-12">
              <div className="home_banner_img">
                <img
                  src={bannerplywood}
                  alt="bannerplywood"
                  fetchpriority="high"
                />
              </div>
            </div>
            <p className="text-center  animate_buttn">
              <button
                type="button"
                onClick={() => handleShow()}
                className="submit_buttn"
              >
                Book A Free Demo Today
              </button>
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Homebannersectoin;
