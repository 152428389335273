import React, { useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { images } from "./Utility/Images";
import AOS from "aos";

// import styles from "./RoCareIndiaCaseStudy/styles.module.scss";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay } from "swiper/modules";
import ContactModalBox from "./HomePageComponents/ContactModalBox";

function ROCareIndiaCaseStudy() {

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  AOS.init();
  return (
    <>
      {/* breadcrumb-for-caseStudy */}

      <section className="breadcrumb-area">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <div className="breadcrumb-casestudy-box">
                <ul className="breadcrumb-casestudy-list">
                  <li>
                    <Link to="/">Home </Link> <span>/</span>{" "}
                  </li>
                  <li>
                    <Link to="/case-studies">Case Study</Link> <span>/</span>{" "}
                  </li>
                  <li>
                    <Link to="/ROCareIndiaCaseStudy">RO Care India </Link>{" "}
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* RoCare-main-banner-sec */}

 
      <section className="RoCare-banner-sec">
        <div className="container">
          <div className="j-c-sb row">
            <div
              className="RoCare-banner-content col-xl-4 col-lg-5 col-md-6"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="logo-img">
                <img
                  src={images.Rocarecasestudylogo}
                  alt=""
                  className="img-fluid"
                />
              </div>

              <h1 className="main-heading">Case Study for RO Care India</h1>

              <p className="desc">
                <span className="sub-head"> RO Care India : </span> Professional
                water purifier service for clean and healthy drinking water - A
                Case Study
              </p>

              <div className="RoCare-intro-list-box">
                <ul className="RoCare-intro-list">
                  <li>
                    {" "}
                    <span className="sub-head"> Product : </span> RO
                  </li>
                  <li>
                    {" "}
                    <span className="sub-head"> Industry : </span> Ecommerce
                  </li>
                  <li>
                    {" "}
                    <span className="sub-head"> Services : </span> UI/UX Design,
                    App Development
                  </li>
                  <li>
                    {" "}
                    <span className="sub-head"> Technology Stack : </span> React
                    Js, React Native, Node.js, MongoDB
                  </li>
                </ul>
              </div>

              <p className='mt-30'>
              <button type='button' onClick={handleShow} className='transform-button'>Contact Us</button> </p>  
            </div>

            <div
              className="RoCare-banner-img col-xl-8 col-lg-7 col-md-6"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <img
                src={images.Rocaremainbannerimg}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

      {/* RoCare-about-us-sec */}

      <section className="Rocare-aboutus py-40">
        <Container>
          <Row className="pb-3">
            <Col
              xl={10}
              className="mx-auto"
              data-aos="zoom-in-up"
              data-aos-duration="2000"
            >
              <div className="Rocare-aboutus-main-head">
                <h2 className="main-heading">RO Service Provider</h2>

                <p className="desc">
                  RO Care India stands as one of India's most trusted
                  independent water purifier service providers. The company
                  offers a comprehensive array of solutions, including RO
                  installation, repair, maintenance, and AMC services, catering
                  to a diverse clientele that spans domestic, commercial, and
                  industrial sectors. RO Care India has an extensive service
                  network encompassing various water purifier brands.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <img src={images.Rocareaboutusimg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </section>

      {/* client-requirements */}

      <section className="client-requirements-sec">
        <Container>
          <Row>
            <Col xl={6} md={6} className="padding-zero">
              <div className="client-requirements-main-head">
                <h2
                  className="main-heading"
                  data-aos="zoom-in-down"
                  data-aos-duration="2500"
                >
                  Client Requirements
                </h2>
                <p className="desc" data-aos="fade-up" data-aos-duration="3000">
                  Recognizing the potential of digitization, RO Care India
                  embarked on a transformational journey aimed at reinventing
                  its service delivery process. The objective was to develop a
                  comprehensive web portal and mobile application, with
                  intuitive interfaces tailored for different user roles -
                  service providers, service engineers, and customers. The
                  digital platform was envisioned to enhance service efficiency,
                  improve user experience, and facilitate business growth.
                </p>
              </div>
            </Col>

            <Col xl={6} md={6}>
              <div className="client-requirements-img">
                <img
                  src={images.Rocareclientreq}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/*Rocare-customer-features-sec */}

      <section className="Rocare-features-sec py-40">
        <div className="overlay-rocare-features"></div>
        <div className="position-relative-z-index-1">
          <Container>
            <Row className="pb-4">
              <Col xl={10} className="mx-auto">
                <div className="Rocare-features-main-head">
                  <h2 className="main-heading">Features</h2>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl={12} className="content-heading">
                <h3 className="features-heading">Customer Side</h3>
              </Col>
              <Col xl={12} className="features-content">
                <Row>
                  <Col xl={6} md={6}>
                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        Service Booking
                      </h5>

                      <ul className="features-list">
                        <li> Schedule upcoming maintenance services.</li>
                        <li>Reschedule or cancel existing appointments.</li>
                        <li>Set preferred timings for service appointments.</li>
                      </ul>
                    </div>

                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        Real-Time Tracking and Live Tracking
                      </h5>

                      <ul className="features-list">
                        <li>View live location of the service engineer.</li>
                        <li>Status updates and estimated arrival time.</li>
                        <li>Archive of past service tracking records.</li>
                      </ul>
                    </div>

                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        Geo-Location Mapping
                      </h5>

                      <ul className="features-list">
                        <li>
                          {" "}
                          Save multiple addresses for different service
                          locations.
                        </li>
                        <li>Pin location on an interactive map.</li>
                        <li>Edit or delete saved addresses.</li>
                      </ul>
                    </div>

                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        Product Purchase
                      </h5>

                      <ul className="features-list">
                        <li>
                          {" "}
                          Browse through an extensive catalog of products.
                        </li>
                        <li>
                          Filter and sort products based on various parameters.
                        </li>
                        <li>
                          View detailed product specifications and images.
                        </li>
                      </ul>
                    </div>

                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        Feedback and Ratings
                      </h5>

                      <ul className="features-list">
                        <li>
                          Rate services on different parameters such as
                          punctuality, professionalism, and effectiveness.
                        </li>
                        <li>Write detailed reviews to provide feedback.</li>
                        <li>View ratings and reviews from other customers.</li>
                      </ul>
                    </div>
                  </Col>

                  <Col xl={6} md={6}>
                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        Service History
                      </h5>

                      <ul className="features-list">
                        <li> View detailed reports of past services.</li>
                        <li> Download invoices and service reports.</li>
                        <li> Filter and search through service history.</li>
                      </ul>
                    </div>

                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        Integrated Payment Gateway
                      </h5>

                      <ul className="features-list">
                        <li>
                          {" "}
                          Secure payments via credit/debit cards, net banking,
                          UPI, and wallets.
                        </li>
                        <li>View transaction history and invoice details.</li>
                        <li>Apply promo codes for discounts.</li>
                      </ul>
                    </div>

                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        24x7 Customer Support{" "}
                      </h5>

                      <ul className="features-list">
                        <li>Connect through call, email, or chat.</li>
                        <li>
                          FAQ section for quick resolution of common queries.
                        </li>
                        <li>Request call-back at convenient timings.</li>
                      </ul>
                    </div>

                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        Service Subscription
                      </h5>

                      <ul className="features-list">
                        <li> Choose from different subscription plans.</li>
                        <li>
                          {" "}
                          Set alerts for upcoming services as per the plan.
                        </li>
                        <li> Auto-renewal option for subscriptions.</li>
                      </ul>
                    </div>

                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        Loyalty Program
                      </h5>

                      <ul className="features-list">
                        <li> Earn points on every booking or purchase.</li>
                        <li>
                          {" "}
                          Redeem points for discounts on services or products.
                        </li>
                        <li> Track points history and validity.</li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      {/* rocare-customer-screens */}

      <section className="rocare-customer-screens py-40">
        <Container>
          <Row>
            <Swiper
              slidesPerView={4}
              spaceBetween={20}
              speed={900}
              loop={true}
              // pagination={{
              //   clickable: true,
              // }}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },

                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
              className="mySwiper py-5"
            >
              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc1} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc2} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc3} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc4} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc5} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc7} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc8} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc15} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc17} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc18} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc19} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc20} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>
            </Swiper>
          </Row>
        </Container>
      </section>

      {/* rocare-service-provider-features-sec */}

      <section className="Rocare-features-sec py-40">
        <div className="overlay-rocare-features"></div>
        <div className="position-relative-z-index-1">
          <Container>
            <Row className="pb-4">
              <Col xl={10} className="mx-auto">
                <div className="Rocare-features-main-head">
                  <h2 className="main-heading">Features</h2>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl={12} className="content-heading">
                <h3 className="features-heading">Service Provider Side</h3>
              </Col>
              <Col xl={12} className="features-content">
                <Row>
                  <Col xl={6} md={6}>
                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        Job Alerts and Scheduling
                      </h5>

                      <ul className="features-list">
                        <li> View details of the job request.</li>
                        <li>Accept or decline job requests.</li>
                        <li>Calendar integration for scheduling.</li>
                      </ul>
                    </div>

                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        Live Tracking and Geo-Mapping
                      </h5>

                      <ul className="features-list">
                        <li>Real-time traffic updates.</li>
                        <li>Save frequently visited locations.</li>
                        <li>Map view of all active and upcoming jobs.</li>
                      </ul>
                    </div>

                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        Service Status Update
                      </h5>

                      <ul className="features-list">
                        <li> Capture and upload images of completed jobs.</li>
                        <li>
                          Digital acknowledgement from customers on job
                          completion.
                        </li>
                        <li>Archive of past job statuses.</li>
                      </ul>
                    </div>

                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        Inventory Management
                      </h5>

                      <ul className="features-list">
                        <li> Real-time inventory tracking.</li>
                        <li>Real-time inventory tracking.</li>
                        <li>Set alerts for low-stock items.</li>
                        <li>Request restock directly from the app.</li>
                      </ul>
                    </div>
                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        Direct Customer Interaction
                      </h5>

                      <ul className="features-list">
                        <li> Send and receive messages.</li>
                        <li>Call customers directly from the app.</li>
                        <li>
                          Share important information such as ETA, job status,
                          etc.
                        </li>
                      </ul>
                    </div>
                  </Col>

                  <Col xl={6} md={6}>
                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        Earnings Tracker
                      </h5>

                      <ul className="features-list">
                        <li> View earnings from completed jobs.</li>
                        <li>Track pending payments.</li>
                        <li>
                          Detailed financial reports for better income
                          management.
                        </li>
                      </ul>
                    </div>
                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        Support and Training
                      </h5>

                      <ul className="features-list">
                        <li>Access to digital manuals and guides.</li>
                        <li>Video tutorials for complex tasks.</li>
                        <li>Contact support directly from the app.</li>
                      </ul>
                    </div>

                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        Geo-Location Mapping
                      </h5>

                      <ul className="features-list">
                        <li> Set preferred service areas.</li>
                        <li>
                          Dynamic location setting based on current location.
                        </li>
                        <li>Save and manage multiple serviceable locations.</li>
                      </ul>
                    </div>

                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        Availability Status
                      </h5>

                      <ul className="features-list">
                        <li> Toggle availability on/off.</li>
                        <li>Schedule availability for future dates.</li>
                        <li>Auto status update based on job schedule.</li>
                      </ul>
                    </div>

                    <div className="white-box">
                      <h5>
                        <img
                          src={images.Rocarelocationicon}
                          alt=""
                          className="img-fluid"
                        />
                        Profile Management{" "}
                      </h5>

                      <ul className="features-list">
                        <li>Update personal and professional information.</li>
                        <li>Add and manage certifications and skills.</li>
                        <li>View and respond to customer feedback.</li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      {/* rocare-service-provider-screens */}

      <section className="rocare-customer-screens py-40">
        <Container>
          <Row>
            <Swiper
              slidesPerView={4}
              spaceBetween={20}
              speed={900}
              loop={true}
              // pagination={{
              //   clickable: true,
              // }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },

                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
              className="mySwiper py-5"
            >
              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc1} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc6} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc9} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc10} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc11} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc12} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc13} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc14} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Rocare-customer-slide">
                  <img src={images.Rocaresc16} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>
            </Swiper>
          </Row>
        </Container>
      </section>

      {/* Rocare-challenges-sec */}

      <section className="rocare-challenges-sec py-40">
        <Container>
          <Row className="py-3">
            <Col xl={6}>
              <div className="rocare-challenges-img">
                <img
                  src={images.RocareChallenges}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Col>

            <Col xl={5}>
              <div className="rocare-challenges-content">
                <h2 className="main-heading">Challenges</h2>

                <h6 className="sub-heading">1. User Interface Design</h6>

                <p className="desc">
                  Designing an intuitive, user-friendly interface catering to a
                  diverse user base was a significant challenge. The design
                  needed to be simple yet functional for both tech-savvy users
                  and those with limited tech exposure.
                </p>

                <h6 className="sub-heading">2. Scalability</h6>

                <p className="desc">
                  The platform had to be designed to handle a growing user base
                  and an expanding portfolio of services. It needed to maintain
                  top performance even under heavy load, which required
                  efficient back-end architecture.
                </p>
                <h6 className="sub-heading">3. Training and Adoption</h6>

                <p className="desc">
                  Ensuring that service engineers and vendors, who were more
                  accustomed to traditional ways of working, could effectively
                  use the platform was a crucial task. It required comprehensive
                  training and continuous support.
                </p>
                <h6 className="sub-heading">
                  4. Real-Time Data Synchronization
                </h6>

                <p className="desc">
                  Implementing live tracking and real-time status updates
                  required a robust back-end
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
  
  
      <ContactModalBox show={show} setShow={setShow}  handleShow={handleShow} handleClose={handleClose} />
    </>
  );
}

export default ROCareIndiaCaseStudy;
