import React from 'react'
import customwebapp from "../../../../assets/images/WebDevelopment/services/web-development.webp";
import pwa from "../../../../assets/images/WebDevelopment/services/pwa.webp";
import ecommerce from "../../../../assets/images/WebDevelopment/services/ecommerce.webp";
// import redesign from "../../../../assets/images/WebDevelopment/services/redesign.webp";
// import Api from "../../../../assets/images/WebDevelopment/services/integration.webp";
import frontend from "../../../../assets/images/WebDevelopment/services/front-end-programming.webp";
// import uiux from "../../../../assets/images/WebDevelopment/services/uiux.webp";
import consulting from "../../../../assets/images/WebDevelopment/services/consulting.webp";
import Maintenance from "../../../../assets/images/WebDevelopment/services/maintenance.webp";
import { Col, Container, Row } from 'react-bootstrap';
import styles from './styles.module.scss'

export default function SupplyChainServices() {

    const webservicesArr = 
        [
          {
      image:customwebapp,
      heading:" Stay Ahead with a Dynamic Supply Chain",
      para:"A dynamic supply chain enables swift adaptation to market changes, optimizing operations, reducing costs, and enhancing customer satisfaction for competitiveness.",
        },
    
      //     {
      // image:ecommerce,
      // heading:"eCommerce Web App Development",
      // para:"We excel in bridging the gap between your business idea and your customers. From selecting the right tech stack, designing an engaging user experience, and deploying a smooth-running application, we take care of your end-to-end eCommerce web app development services needs. With timely delivery, we ensure high ROI for your business from technological investments.",
      //   },
      //     {
      // image:Digitalmktwhiteseo,
      // heading:"SaaS App Development",
      // para:"We build scalable SaaS products to advance your growth. Our PMOs use established KPIs to guarantee the highest output from engineers. Elevated user experience is at the center of all our development efforts. A combination of agile development, lean startup method, and 14+ years of experience ensure that we provide superior and differentiated web app development services.",
      //   },

          {
      image:pwa,
      heading:"Improved Collaboration",
      para:"Strengthened communication and partnerships among suppliers, manufacturers, and retailers foster better teamwork and shared goals.",
        },
          {
      image:frontend,
      heading:"Increased Flexibility",
      para:"A well-developed supply chain can quickly adapt to market changes, customer demands, and unexpected disruptions, ensuring business continuity.",
        },
          {
      image:ecommerce,
      heading:"Better Inventory Management",
      para:"Optimized inventory levels prevent stockouts and overstock situations, minimizing holding costs and enhancing cash flow.",
        },
          {
      image:Maintenance,
      heading:"Data-Driven Decision Making",
      para:"Access to real-time data and analytics empowers businesses to make informed decisions, identify trends, and anticipate future demands.",
        },
          {
      image:consulting,
      heading:" Higher Customer Satisfaction",
      para:"Efficient supply chain management ensures timely delivery of products and services, leading to improved customer experiences and loyalty.",
        },
      
      
      
      ]
      
  return (
   <>
    <section className={styles.web_services_sec}>
        <Container>
          <Row>
           
              <div className={styles.heading_box}>
                <h2 className={styles.main_heading}>
                Supply Chain    <span> Management System </span>  Benefits
                </h2>
                <p className={styles.desc}>
                At Ebslon Infotech, we utilize advanced technology and data-driven strategies to optimize supply chain management, enhancing efficiency and reducing costs. Our solutions provide real-time insights, streamline processes, and foster collaboration, helping you improve customer satisfaction and achieve sustainable growth.
                </p>
              </div>
          
          </Row>
          <Row className={styles.pt_40}>
      {
        webservicesArr && webservicesArr?.map((el,index)=>(
          <Col xl={4} lg={4} md={6} key={index}>
          <div className={styles.card}>
         
                <div className={styles.icon}>
                  <span className={styles.circle_icon}></span>
                  <img
                    src={el.image}
                    alt=""
                    className="img-fluid"
                  />
                </div>
             
             
                  <h5 className={styles.web_heading}>
                 {el.heading}

                  </h5>
            
           

            <div className={styles.content}>
              <p className={styles.desc}>
             {el.para}
              </p>

            
            </div>
          </div>
        </Col>
        ))
      }
      
      
      
          
         

        
          </Row>
        </Container>
      </section>
   
   
   </>
  )
}
