
import React, { useState } from 'react'
import styles from './styles.module.scss'

import fintech from '../../../assets/images/softwaredevelopment/industriesserve/Fintech-1.webp'
import education from '../../../assets/images/softwaredevelopment/industriesserve/education.webp'
import healthcare from '../../../assets/images/softwaredevelopment/industriesserve/healthcare.webp'
import retail from '../../../assets/images/softwaredevelopment/industriesserve/retail.webp'
import logistics from '../../../assets/images/softwaredevelopment/industriesserve/logistic.webp'
import manufacturing from '../../../assets/images/softwaredevelopment/industriesserve/manufacturing.webp'
import entertainment from '../../../assets/images/softwaredevelopment/industriesserve/Entertainment.webp'
import appdevelopment from '../../../assets/images/softwaredevelopment/industriesserve/On-demand-App-Development.webp'
import food from '../../../assets/images/softwaredevelopment/industriesserve/food.webp'
import sports from '../../../assets/images/softwaredevelopment/industriesserve/gaming.webp'
import energy from '../../../assets/images/softwaredevelopment/industriesserve/energy.webp'
import social from '../../../assets/images/softwaredevelopment/industriesserve/Social-Networking.webp'
import facility from '../../../assets/images/softwaredevelopment/industriesserve/Facilities-Management.webp'
import agriculture from '../../../assets/images/softwaredevelopment/industriesserve/agriculure.webp'
import insurance from '../../../assets/images/softwaredevelopment/industriesserve/insurance.webp'

import { Autoplay, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

export default function Industries() {

const [industriesarr,SetIndustriesArr] = useState([
    {
        image:fintech,
        heading:"Fintech",
        move_up:false,
    },
    {
        image:education,
        heading:"Education",
        move_up:true,
    },
    {
        image:healthcare,
        heading:"Healthcare",
        move_up:false,
    },
    {
        image:retail,
        heading:"Retail",
        move_up:true,
    },
    {
        image:logistics,
        heading:"Logistics",
        move_up:false,
    },
    {
        image:manufacturing,
        heading:"Manufacturing",
        move_up:true,
    },
    {
        image:entertainment,
        heading:"Entertainment",
        move_up:false,
    },
    {
        image:appdevelopment,
        heading:"On Demand App Development",
        move_up:true,
    },
    {
        image:food,
        heading:"Food and Restaurant",
        move_up:false,
    },
    {
        image:sports,
        heading:"On Demand App Development",
        move_up:true,
    },
    {
        image:energy,
        heading:"Energy & Utilities",
        move_up:false,
    },
    {
        image:social,
        heading:"Social Networking",
        move_up:true,
    },
   
    {
        image:facility,
        heading:"Facilities Management",
        move_up:false,
    },
   
    {
        image:agriculture,
        heading:"Agriculture",
        move_up:true,
    },
   
    {
        image:insurance,
        heading:"Insurance",
        move_up:false,
    },
   
   

])
const  breakpoint={
    320: {
      slidesPerView: 1,
      slidesPerGroup:1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      slidesPerGroup:1,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 3,
      slidesPerGroup:1,
      spaceBetween: 25,
    },
    1200: {
      slidesPerView: 4,
      slidesPerGroup:2,
      spaceBetween: 30,
    },
  }



  return (
    <>
    <section className={styles.industries_sec}>
<div className="container">
<div className="row">
        <h2 className={styles.main_heading}>Industries We Serve</h2>
        <p className={styles.desc}>We Take Pride in Serving Clients Belonging to Various Industries.</p>
    </div>

    <div className={`row ${styles.pt_50}`}>
    <Swiper
            
            slidesPerView={4}
            slidesPerGroup={1}
            spaceBetween={25}
            loop
            modules={[Autoplay,Pagination]}
            speed={3500}
            autoplay={{ disableOnInteraction: false, delay: 2500 }}
            pagination={{ clickable: true }} // Set clickable pagination dots
            breakpoints={breakpoint}
            className={`mySwiper ${styles.py_40} industries_swiper`}
          >
           
            {
        industriesarr && industriesarr?.map((el,index)=>(
            <SwiperSlide key={index} >
           
            <div className={`${styles.card} ${  el.move_up == true ? styles.move_up :  ""}`}>
<div className={styles.image}>
<img src={el.image} alt='' />

</div>
<div className={styles.content}>
<h5>{el.heading}</h5>
</div>

            </div>
            </SwiperSlide>
       
        ))
       }
              
           
           
           

          
           
          </Swiper>
      
    </div>

</div>
    </section>
    
    </>
  )
}
