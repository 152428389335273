
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import aboutimg from '../../../assets/images/softwaredevelopment/About/aboutus.webp'
import crmimg from '../../../assets/images/softwaredevelopment/About/crmsoftware.webp'
import erpimg from '../../../assets/images/softwaredevelopment/About/erp.webp'
import styles from './styles.module.scss'

import { Link } from 'react-router-dom'



function SoftwareAboutus() {
  return (
   <>
    <section className={styles.software_aboutus}>
        <Container>
        <div className={styles.heading_box}>
                <h2 className={styles.main_heading}>
                What <span> Software Development </span> Services We Offer
                </h2>
            
              </div>
          <Row className={`align-items-center justify-content-center ${styles.pt_40}`}>
            <Col xl={6} lg={6}>
              <div className={styles.image}>
                <img
                  src={crmimg}
                  alt="CRMSoftware"
                  
                />
              </div>
            </Col>

            <Col xl={5} lg={6} >
              <div className={styles.content}>
                <h3 className={styles.heading}>
                Custom <span>CRM Software </span> Development
                </h3>
                <p className={styles.desc}>
                Ebslon Infotech, a Custom CRM Software Development Company, excels in delivering CRM marketing management solutions tailored to your business dynamics. Our bespoke CRM software streamlines operations, strengthens customer relationships, and propels growth, ensuring your business scales new heights in efficiency and customer satisfaction.


                </p>

             
              
                <p className={styles.buttn_holder}>
                  {" "}
                  <Link to="/custom-crm-development-services" className={styles.rm_buttn}>
                    CRM Software
                  </Link>
                </p>
              </div>
            </Col>
          </Row>

          <Row className={`align-items-center justify-content-center ${styles.row_reverse} ${styles.pt_40}`}>
          <Col xl={5} lg={6} >
              <div className={styles.content}>
                <h3 className={styles.heading}>
                Custom <span>ERP Software </span> Development
                </h3>
                <p className={styles.desc}>
                Ebslon Infotech, we offer a cloud-based, scalable, and customizable Mobile ERP software solution, which manages all your business-critical processes in a centralized and integrated fashion.



                </p>

                <div className={styles.content_list}>
                <ul className={styles.list}>
                  <li>Surpass others with our superior ERP solution.</li>
                  <li>No downloads, just seamless online access.</li>
                  <li>Fastest ERP implementation ever.</li>
                  <li>Save an hour daily with automated functions.</li>
                  <li>Boost profits and avoid order delays.</li>
                </ul>
              </div>


             
              
                <p className={styles.buttn_holder}>
                  {" "}
                  <Link to="/custom-erp-software-development-company" className={styles.rm_buttn}>
                  ERP Software
                  </Link>
                </p>
              </div>
            </Col>
            <Col xl={6} lg={6}>
              <div className={styles.image}>
                <img
                  src={erpimg}
                  alt="ErpSoftware"
                  
                />
              </div>
            </Col>

     
          </Row>

          <Row className={`align-items-center justify-content-center ${styles.pt_40}`}>
            <Col xl={6} lg={6}>
              <div className={styles.image}>
                <img
                  src={aboutimg}
                  alt="WebAppDevelopment"
                  
                />
              </div>
            </Col>

            <Col xl={5} lg={6} >
              <div className={styles.content}>
                <h3 className={styles.heading}>
                 <span>Web App </span> Development
                </h3>
                <p className={styles.desc}>
                We serve the needs of digital-first businesses by combining our industry expertise and engineering knowledge. As a leading Web App Development Services Company, we focus on using modern application development frameworks to create high-performance applications.


                </p>

             
              
                <p className={styles.buttn_holder}>
                  {" "}
                  <Link to="/web-development-company-in-india" className={styles.rm_buttn}>
                    Web / App Development
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
   
   
   </>
  )
}

export default SoftwareAboutus