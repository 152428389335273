import React, { useState } from 'react'
import styles from './styles.module.scss'


import planning from '../../../../assets/images/supplychainmanagement/approach/planning.webp'
import logistics from '../../../../assets/images/supplychainmanagement/approach/logistics.webp'
import manufacturing from '../../../../assets/images/supplychainmanagement/approach/manufacturing.webp'
import lifecycle from '../../../../assets/images/supplychainmanagement/approach/lifecycle.webp'
import assets from '../../../../assets/images/supplychainmanagement/approach/assets.webp'
import sustainable from '../../../../assets/images/supplychainmanagement/approach/sustainable.webp'





export default function SupplyChainApproach() {

    const [selectedTab, SetSelectedTab] = useState("ARandVR");


    return (
        <>
            <section className={styles.our_approach}>
                <div className="container">
                    <div className="row">
                        <h2 className={styles.main_heading}>
                        How We Work?
                        </h2>
                        <p className={styles.desc}>In supply chain management, we optimize processes by analyzing data, improving efficiency, and ensuring timely delivery to meet customer demands.</p>
                    </div>
                    <div className={`row ${styles.pt_50}`}>
                        <div className="col-xl-3 col-md-5">
                            <div className={styles.sidebar}>
                                <ul className={styles.list}>
                                    <li>
                                        <button className={selectedTab === "ARandVR" ? styles.tabactive : ""} onClick={() => SetSelectedTab("ARandVR")}> Supply Chain Planning</button>
                                    </li>
                                    <li>
                                        <button className={selectedTab === "Retail" ? styles.tabactive : ""} onClick={() => SetSelectedTab("Retail")}>Supply Chain Logistics
                                        </button>
                                    </li>
                                    <li>
                                        <button className={selectedTab === "Education" ? styles.tabactive : ""} onClick={() => SetSelectedTab("Education")}>Manufacturing
                                        </button>
                                    </li>
                                    <li>
                                        <button className={selectedTab === "Healthcare" ? styles.tabactive : ""} onClick={() => SetSelectedTab("Healthcare")}>Data Product Lifecycle Management
                                        </button>
                                    </li>
                                    <li>
                                        <button className={selectedTab === "RealEstate" ? styles.tabactive : ""} onClick={() => SetSelectedTab("RealEstate")}>Create Enterprise Asset Management
                                        </button>
                                    </li>
                                    <li>
                                        <button className={selectedTab === "Manufacturing" ? styles.tabactive : ""} onClick={() => SetSelectedTab("Manufacturing")}>Sustainable supply Chain
                                        </button>
                                    </li>
                                  
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-7 col-md-7">

                            {
                                selectedTab === "ARandVR" ? (
                                    <div className={styles.content}>
                                        <h5>Supply Chain Planning</h5>
                                        <div className={styles.image}>
                                           <img src={planning} alt=''/>
                                        </div>
                                        <p>
                                        Effective supply chain planning aligns resources with demand, optimizing inventory levels and enhancing operational efficiency. By forecasting needs and streamlining processes, businesses can respond quickly to market changes, ensuring timely delivery and cost-effective operations.
                                        </p>
                                    </div>
                                ) : selectedTab === "Retail" ? (
                                    <div className={styles.content}>
                                        <h5>Supply Chain Logistics</h5>
                                        <div className={styles.image}>
                                           <img src={logistics} alt=''/>
                                        </div>
                                        <p>
                                        Supply chain logistics involves managing the movement and storage of goods efficiently. It encompasses transportation, warehousing, and inventory management, ensuring products reach their destination on time. Effective logistics strategies enhance customer satisfaction and reduce operational costs.
                                        </p>
                                    </div>
                                ) : selectedTab === "Education" ? (

                                    <div className={styles.content}>
                                        <h5>Manufacturing</h5>
                                        <div className={styles.image}>
                                           <img src={manufacturing} alt=''/>
                                        </div>
                                        <p>
                                        Manufacturing transforms raw materials into finished products through efficient processes. Emphasizing quality control, automation, and lean practices, modern manufacturing strives to minimize waste and maximize productivity. This approach ensures competitiveness in an evolving market, meeting consumer demands effectively.
                                        </p>
                                    </div>

                                ) : selectedTab === "Healthcare" ? (

                                    <div className={styles.content}>
                                        <h5>Data Product Lifecycle Management</h5>
                                        <div className={styles.image}>
                                           <img src={lifecycle} alt=''/>
                                        </div>
                                        <p>
                                        Data product lifecycle management oversees a product’s journey from inception to retirement. By integrating data analytics at each stage, businesses can enhance decision-making, optimize resource allocation, and improve product quality, ultimately driving innovation and customer satisfaction.
                                        </p>
                                    </div>

                                ) : selectedTab === "RealEstate" ? (

                                    <div className={styles.content}>
                                        <h5>Create Enterprise Asset Management</h5>
                                        <div className={styles.image}>
                                           <img src={assets} alt=''/>
                                        </div>
                                        <p>
                                        Creating enterprise asset management (EAM) systems ensures optimal utilization of organizational assets. EAM integrates data, maintenance schedules, and performance metrics, enabling proactive management. This approach enhances operational efficiency, reduces downtime, and extends asset lifespan, ultimately boosting overall productivity.
                                        </p>
                                    </div>

                                ) : selectedTab === "Manufacturing" ? (
                                    <div className={styles.content}>
                                        <h5>Sustainable Supply Chain</h5>
                                        <div className={styles.image}>
                                           <img src={sustainable} alt=''/>
                                        </div>
                                        <p>
                                        A sustainable supply chain prioritizes environmental and social responsibility throughout the supply chain. By implementing eco-friendly practices, such as waste reduction and ethical sourcing, businesses can minimize their ecological footprint while enhancing brand reputation and customer loyalty.
                                        </p>
                                    </div>

                                ) : (
                                    <div className={styles.content}>
                                    <h5>Supply Chain Planning</h5>
                                    <div className={styles.image}>
                                       <img src={planning} alt=''/>
                                    </div>
                                    <p>
                                    Effective supply chain planning aligns resources with demand, optimizing inventory levels and enhancing operational efficiency. By forecasting needs and streamlining processes, businesses can respond quickly to market changes, ensuring timely delivery and cost-effective operations.
                                    </p>
                                </div>
                                )
                            }





                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
