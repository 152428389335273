import React from "react";
import SupplyBanner from "./_SupplyChainManagement/Components/SupplyBanner/SupplyBanner";
import SupplyAboutus from "./_SupplyChainManagement/Components/_SupplyAboutus/_SupplyAboutus";

import SupplyChainFAQ from "./_SupplyChainManagement/SupplyChainFAQ/SupplyChainFAQ";
import SupplyChainServices from "./_SupplyChainManagement/Components/SupplyChainServices/SupplyChainServices";
import WebTechnologies from "./_SupplyChainManagement/Components/WebTechs/WebTechnologies";
import SuccessStories from "./_SupplyChainManagement/Components/SuccessStories/SuccessStories";
import SupplyChainApproach from "./_SupplyChainManagement/Components/_SupplyChainApproach/SupplyChainApproach";
function SupplyChainManagement() {
  return (
    <>
      <SupplyBanner />
      <SupplyAboutus />
      <SupplyChainServices/>
      <SupplyChainApproach/>
      <WebTechnologies/>
      <SuccessStories/>
<SupplyChainFAQ/>
    
    </>
  );
}

export default SupplyChainManagement;
