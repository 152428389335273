import React from "react";
import styles from  './styles.module.scss'
import bannerimg from "../../../../assets/images/supplychainmanagement/supply_banner.webp";
import { Link } from "react-router-dom";

function SupplyBanner() {
  return (
    <>
      <section className={styles.about_banner}>
        <div className="container">
          <div className={`row ${styles.j_c_sb} align-items-center`}>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className={styles.content}>
                <h1 className={styles.heading}>
                Empower Your  <span> Supply Chain  </span>with Fusion Cloud SCM Technology
                </h1>
             
                <p className={styles.desc}>
                A robust supply chain management system enhances efficiency by streamlining processes, improving visibility, and reducing costs. It enables businesses to respond quickly to market changes, optimize inventory levels, and foster collaboration among stakeholders. Ultimately, it drives growth and ensures customer satisfaction in a competitive landscape.
                </p>

                <p className={styles.buttn_holder}>
                  <Link to="/contact-us" className={styles.buttn}>
                    <span>Request a Demo</span>
                  </Link>
                </p>
              </div>
            </div>
            <div className="col-xl-5 col-md-6">
              <div className={styles.image}>
                <img src={bannerimg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SupplyBanner;
