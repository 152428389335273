import React from 'react'
import { Col, Row, Container, Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
function SoftwareFaq() {
  return (
   <>
   
   <section className="faq-area py-40">
        <Container>
          <Row className="py-2">
            <Col xl={8} className="mx-auto features-head">
              <h2>
                Frequently Asked <span>Questions</span>
              </h2>
              {/* <p className="desc">
              </p> */}
            </Col>
          </Row>

          <Row className="pt-3 faq-accordion">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                What differentiates your app development services from other
                    providers?
         


                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
                As a leading app development company in India, we prioritize
                    innovation, merging latest technologies with time-tested
                    strategies to deliver unparalleled solutions.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                Is your expertise limited to mobile app development
                services, or do you cover web apps as well?
                 


                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
                Our proficiency extends beyond just mobile. We offer
                    comprehensive app development services, encompassing both
                    mobile and web platforms.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                How do you maintain the title of the best app development
                company in the region?
              


                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
                Our dedication to excellence, combined with our ability to
                    integrate modern trends and technologies, positions us as
                    the best app development company in India.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                I'm keen on a custom iOS application. Is iOS app development
                    within your realm of expertise?
                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
              
                Absolutely. Our iOS app development team is equipped with
                    the skills and experience to transform your vision into a
                    high-performing application.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                Are the web app development services you offer scalable and
                future-ready?
               
                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
                Certainly. Our web app development services are designed
                    with scalability in mind, ensuring your application remains
                    relevant and efficient as your business grows.

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                With the proliferation of mobile devices, how does your
                    mobile app development services stand out?

                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
            
                We understand the diverse mobile landscape. By leveraging
                    custom mobile app development methodologies and the latest
                    trends, we ensure your app excels across all devices.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                How do you tailor your app development services to cater to
                niche business requirements?
            

                  <FontAwesomeIcon className="plus-icon" icon={faChevronUp} />
                  <FontAwesomeIcon
                    className="minus-icon"
                    icon={faChevronDown}
                  />
                </Accordion.Header>
                <Accordion.Body>
                Our custom mobile app development process involves an
                    in-depth analysis of your business needs. This allows us to
                    create solutions that are not only innovative but also align
                    perfectly with your objectives.
                </Accordion.Body>
              </Accordion.Item>

           
     
           
            </Accordion>
          </Row>
        </Container>
      </section>
   </>
  )
}

export default SoftwareFaq
