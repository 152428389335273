import logo from "../../assets/images/logo.png";
import client_1 from "../../assets/images/clients/1.png";
import client_2 from "../../assets/images/clients/2.png";
import client_3 from "../../assets/images/clients/3.png";
import client_4 from "../../assets/images/clients/4.png";
import client_5 from "../../assets/images/clients/5.png";
import client_6 from "../../assets/images/clients/6.png";
import client_7 from "../../assets/images/clients/7.png";
import client_8 from "../../assets/images/clients/8.png";
import app from "../../assets/images/services/app.svg";
import web from "../../assets/images/services/web.svg";
import graphic from "../../assets/images/services/graphic.svg";
import digital from "../../assets/images/services/digital.svg";
import testimonial_1 from "../../assets/images/testimonial/1.jpg";
import testimonial_2 from "../../assets/images/testimonial/2.jpg";
import testimonial_3 from "../../assets/images/testimonial/3.jpg";
import android from "../../assets/images/technology/android.svg";
import angular from "../../assets/images/technology/angular.svg";
import css from "../../assets/images/technology/css.svg";
import html from "../../assets/images/technology/html.svg";
import ios from "../../assets/images/technology/ios.svg";
import mysql from "../../assets/images/technology/mysql.svg";
import nodejs from "../../assets/images/technology/nodejs.svg";
import php from "../../assets/images/technology/php.svg";
import postgresql from "../../assets/images/technology/postgresql.svg";
import python from "../../assets/images/technology/python.png";
import reactjs from "../../assets/images/technology/reactjs.svg";
import vuejs from "../../assets/images/technology/vuejs.svg";
import mongo from "../../assets/images/technology/mongo.png";
import experessjs from "../../assets/images/technology/experessjs.png";
import reactnavtive from "../../assets/images/technology/reactnavtive.png";
import healthcare from "../../assets/images/industry/healthcare.svg";
import banking from "../../assets/images/industry/banking.svg";
import Manufacturing from "../../assets/images/industry/Manufacturing.svg";
import Informationtechnologies from "../../assets/images/industry/Informationtechnologies.svg";
import Retail from "../../assets/images/industry/Retail.svg";
import Insurance from "../../assets/images/industry/Insurance.svg";
import professionalservices from "../../assets/images/industry/professionalservices.svg";
import Marketing from "../../assets/images/industry/Marketing.svg";
import benefit_1 from "../../assets/images/benefits/1.png";
import benefit_2 from "../../assets/images/benefits/2.png";
import benefit_3 from "../../assets/images/benefits/3.png";
import benefit_4 from "../../assets/images/benefits/4.png";
import blog from "../../assets/images/blog.jpg";
import work_1 from "../../assets/images/work_1.png";
import work_2 from "../../assets/images/work_2.png";
import work_3 from "../../assets/images/work_3.png";
import work_4 from "../../assets/images/work_4.png";
import result from "../../assets/images/result.jpg";
import banner_1 from "../../assets/images/icon/banner_1.png";
import banner_2 from "../../assets/images/icon/banner_2.png";
import logo_2 from "../../assets/images/logo_2.png";
import about from "../../assets/images/about.jpg";
import mission from "../../assets/images/mission.jpg";
import vision from "../../assets/images/vision.jpg";
import value from "../../assets/images/value.png";
import offer_1 from "../../assets/images/icon/offer_1.png";
import offer_2 from "../../assets/images/icon/offer_2.png";
import offer_3 from "../../assets/images/icon/offer_3.png";
import offer_4 from "../../assets/images/icon/offer_4.png";
import map from "../../assets/images/icon/map.png";
import phone from "../../assets/images/icon/phone.png";
import timetable from "../../assets/images/icon/timetable.png";
import email from "../../assets/images/icon/email.png";
import contact from "../../assets/images/contact.jpg";
import case_study from "../../assets/images/case_study.jpeg";
import crm from "../../assets/images/crm.png";
import crm2 from "../../assets/images/crm2.png";
import team from "../../assets/images/team.png";
import techimgicon1 from "../../assets/images/tech_img/android.png";
import techimgicon2 from "../../assets/images/tech_img/angular.png";
import techimgicon3 from "../../assets/images/tech_img/html5-cl.webp";
import techimgicon4 from "../../assets/images/tech_img/ios.png";
import techimgicon5 from "../../assets/images/tech_img/js-cl.webp";
import techimgicon6 from "../../assets/images/tech_img/react.png";
import techimgicon7 from "../../assets/images/tech_img/nodejs-logo.png";
import techimgicon8 from "../../assets/images/tech_img/php_sra4hw.webp";
import techimgicon9 from "../../assets/images/tech_img/pythn_icon.png";
import techimgicon10 from "../../assets/images/tech_img/radius.png";
import techimgicon11 from "../../assets/images/tech_img/mysql.png";
import techimgicon12 from "../../assets/images/tech_img/oracle-logo.png";
import techimgicon13 from "../../assets/images/tech_img/mongodb.png";
import techimgicon14 from "../../assets/images/tech_img/postgresql.png";
import usericon from "../../assets/images/svg_icon/customer_user.png";
import logo1 from "../../assets/images/svg_icon/logo1.webp";
import logo2 from "../../assets/images/svg_icon/logo2.webp";
import logo3 from "../../assets/images/svg_icon/logo3.webp";
import logo4 from "../../assets/images/svg_icon/logo4.webp";
import logo5 from "../../assets/images/svg_icon/logo5.webp";
import logo6 from "../../assets/images/svg_icon/logo6.webp";
import logo7 from "../../assets/images/svg_icon/logo7.webp";
import logo8 from "../../assets/images/svg_icon/sundays_forever.webp";
import webpngimg from "../../assets/images/svg_icon/newmegako.webp";
import logowebimg from "../../assets/images/svg_icon/megaco-logo.png";
import appstorpngicon1 from "../../assets/images/svg_icon/newapp-store.png";
import appstorpngicon2 from "../../assets/images/svg_icon/newPlay-store.png";
import amazonappimg from "../../assets/images/svg_icon/E-learning-bg-new-min.jpg";
import amazonappimg1 from "../../assets/images/svg_icon/social-network-for-patients.webp";
import creandoScreenShot from "../../assets/images/creando_screenshot.webp";
import blogimg from "../../assets/images/e-commerce.webp";
import blogimg1 from "../../assets/images/mobile_app_developent.webp";
import blogimg2 from "../../assets/images/ecommerce_blogimg.webp";
import blogimg3 from "../../assets/images/apple_update_img.webp";
import blogimg4 from "../../assets/images/webdevelopment.png";
import blogimg5 from "../../assets/images/seoimage.jpg";
import blogimg6 from "../../assets/images/Mobile-App-Development.webp";
import blogimg7 from "../../assets/images/Top-complex-business-problems-that.webp";
import arrwoblue from "../../assets/images/arrow_blue.svg";
import webdesing from "../../assets/images/services/webdesign.JPG";
import webdevelopment1 from "../../assets/images/services/web-development.JPG";
import mobileapp from "../../assets/images/services/mobile_development.JPG";
import elearning from "../../assets/images/services/elearning.JPG";
import ecommerdevelopment from "../../assets/images/services/ecommer-development.JPG";
import contaiwriting from "../../assets/images/services/contentwrignt.JPG";
import crmsoftware from "../../assets/images/services/crm-software.JPG";
import itconsting from "../../assets/images/services/itconsting.JPG";
import digital_marketing from "../../assets/images/services/digital_marketing.JPG";
import graphice from "../../assets/images/services/graphic-desinging.JPG";
import desktopappdevelopemnt from "../../assets/images/services/desktopappdevelopemnt.JPG";
import webportal from "../../assets/images/services/webportaldevelopment.JPG";
import boot_icon from "../../assets/images/tech_img/boot_icon.png";

import Appporftfoliyoimg from "../../assets/images/portfolio/appportfoliyo1.png";
import Appporftfoliyoimg1 from "../../assets/images/portfolio/appportfoliyo2.png";
import Appporftfoliyoimg2 from "../../assets/images/portfolio/appportfoliyo3.png";
import Appporftfoliyoimg3 from "../../assets/images/portfolio/appportfoliyo4.png";
import Appporftfoliyoimg4 from "../../assets/images/portfolio/appportfoliyo5.png";
import Appporftfoliyoimg5 from "../../assets/images/portfolio/appportfoliyo6.png";
import Appporftfoliyoimg6 from "../../assets/images/portfolio/appportfoliyo7.png";
import Appporftfoliyoimg7 from "../../assets/images/portfolio/appportfoliyo8.png";
import Appporftfoliyoimg8 from "../../assets/images/portfolio/appportfoliyo9.png";
import Appporftfoliyoimg9 from "../../assets/images/portfolio/appportfoliyo10.png";
import webportfoliyoimg1 from "../../assets/images/portfolio/leziyon.png";

import webportfoliyoimg2 from "../../assets/images/portfolio/dignitytravels.png";
import webportfoliyoimg3 from "../../assets/images/portfolio/fliptrip.png";
import webportfoliyoimg4 from "../../assets/images/portfolio/mainvalue.png";
import webportfoliyoimg5 from "../../assets/images/portfolio/mobiusdesign.png";
import webportfoliyoimg6 from "../../assets/images/portfolio/niveshonline.png";
import webportfoliyoimg7 from "../../assets/images/portfolio/precisionrxtelemed.png";
import webportfoliyoimg8 from "../../assets/images/portfolio/rajasthan_tour_and_travels.png";
import webportfoliyoimg9 from "../../assets/images/portfolio/sringaar.png";
import webportfoliyoimg10 from "../../assets/images/portfolio/stanum.png";
import webportfoliyoimg11 from "../../assets/images/portfolio/sterileindia.png";
import webportfoliyoimg12 from "../../assets/images/portfolio/stratojet.png";
import webportfoliyoimg13 from "../../assets/images/portfolio/uttamchai.png";
import webportfoliyoimg14 from "../../assets/images/portfolio/writers.png";
import webportfoliyoimg15 from "../../assets/images/portfolio/govindroyandassociates.png";
import webportfoliyoimg16 from "../../assets/images/portfolio/growthsecret.png";
import workimgmockup from "../../assets/images/benefits/Data-Analytics.png";
import ai from "../../assets/images/tech_img/ai.png";
import ajax from "../../assets/images/tech_img/ajax.png";
import amp from "../../assets/images/tech_img/amp.png";
import backbonejs from "../../assets/images/tech_img/backbone-js.png";
import codeigniter from "../../assets/images/tech_img/codeigniter.png";
import google_api from "../../assets/images/tech_img/google_api.png";
import joomla from "../../assets/images/tech_img/joomla.png";
import jquery from "../../assets/images/tech_img/jquery.png";
import js from "../../assets/images/tech_img/js.png";
import json from "../../assets/images/tech_img/json.png";
import magento from "../../assets/images/tech_img/magento.png";
import material from "../../assets/images/tech_img/material.png";
import open from "../../assets/images/tech_img/open.png";
import semanticui from "../../assets/images/tech_img/semantic-ui.png";
import shopify from "../../assets/images/tech_img/shopify.png";
import woocomercelogo from "../../assets/images/tech_img/woocomerce-logo.png";
import wordpress_icon from "../../assets/images/tech_img/wordpress_icon.png";
import xml from "../../assets/images/tech_img/xml.png";
import web1 from "../../assets/images/tech_img/web.png";
import solutionsimg1 from "../../assets/images/benefits/crm-and-erp-systems.svg";
import solutionsimg3 from "../../assets/images/benefits/ecommerce.svg";
import solutionsimg4 from "../../assets/images/benefits/Ed-tech.svg";
import solutionsimg5 from "../../assets/images/benefits/Fin-tech.svg";
import solutionsimg6 from "../../assets/images/benefits/health-care.svg";
import solutionsimg7 from "../../assets/images/benefits/logistics.svg";
import solutionsimg8 from "../../assets/images/benefits/Manufacturing.svg";
import solutionsimg9 from "../../assets/images/benefits/real-estste.svg";
import solutionsimg10 from "../../assets/images/benefits/software-1.svg";
import jobportal from "../../assets/images/benefits/jobportal.png";
import solutionsimg11 from "../../assets/images/benefits/Travel.svg";
import hospitaly from "../../assets/images/benefits/hospital.png";
import solutionsimg12 from "../../assets/images/benefits/ed-tech1.svg";
import solutionsimgsociety from "../../assets/images/benefits/society-management.png";
import solutionsimgdigitalmkt from "../../assets/images/benefits/digitalmkt.png";
import solutionsimgwhatsappbot from "../../assets/images/benefits/bot.png";
import solutionsimgschoolmanagement from "../../assets/images/benefits/schoolmanagement.png";
import solutionsimgappdevelopment from "../../assets/images/benefits/appdevelopment.png";

import clicentlogo1 from "../../assets/images/clicent-logo/artisaga1.webp";
import clicentlogo2 from "../../assets/images/clicent-logo/ceressy1.webp";
import clicentlogo3 from "../../assets/images/clicent-logo/favcy1.webp";
import clicentlogo4 from "../../assets/images/clicent-logo/goodgoodpiggy1.webp";
import clicentlogo5 from "../../assets/images/clicent-logo/image2.webp";
import clicentlogo6 from "../../assets/images/clicent-logo/image3.webp";
import clicentlogo7 from "../../assets/images/clicent-logo/image4.webp";
import clicentlogo9 from "../../assets/images/clicent-logo/image5.webp";
import clicentlogo10 from "../../assets/images/clicent-logo/image7.webp";
import clicentlogo11 from "../../assets/images/clicent-logo/image8.webp";
import clicentlogo12 from "../../assets/images/clicent-logo/image9.webp";
import clicentlogo13 from "../../assets/images/clicent-logo/image10.webp";
import clicentlogo14 from "../../assets/images/clicent-logo/image11.webp";
import clicentlogo15 from "../../assets/images/clicent-logo/image12.webp";
import clicentlogo16 from "../../assets/images/clicent-logo/image13.webp";
import clicentlogo17 from "../../assets/images/clicent-logo/image16.webp";
import clicentlogo18 from "../../assets/images/clicent-logo/image18.webp";
import clicentlogo19 from "../../assets/images/clicent-logo/image19.webp";
import clicentlogo20 from "../../assets/images/clicent-logo/image20.webp";
import clicentlogo21 from "../../assets/images/clicent-logo/image21.webp";
import clicentlogo22 from "../../assets/images/clicent-logo/image22.webp";
import clicentlogo23 from "../../assets/images/clicent-logo/image23.webp";
import clicentlogo24 from "../../assets/images/clicent-logo/image24.webp";
import clicentlogo25 from "../../assets/images/clicent-logo/image25.webp";
import clicentlogo27 from "../../assets/images/clicent-logo/image26.webp";
import clicentlogo28 from "../../assets/images/clicent-logo/image27.webp";
import clicentlogo29 from "../../assets/images/clicent-logo/image28.webp";
import clicentlogo30 from "../../assets/images/clicent-logo/image29.webp";
import clicentlogo31 from "../../assets/images/clicent-logo/image30.webp";
import clicentlogo32 from "../../assets/images/clicent-logo/image31.webp";
import clicentlogo33 from "../../assets/images/clicent-logo/image32.webp";
import clicentlogo34 from "../../assets/images/clicent-logo/image33.webp";
import clicentlogo35 from "../../assets/images/clicent-logo/image34.webp";
import clicentlogo36 from "../../assets/images/clicent-logo/image36.webp";
import clicentlogo37 from "../../assets/images/clicent-logo/image37.webp";
import clicentlogo38 from "../../assets/images/clicent-logo/image38.webp";
import clicentlogo39 from "../../assets/images/clicent-logo/image39.webp";
import clicentlogo40 from "../../assets/images/clicent-logo/image45.png";
import clicentlogo41 from "../../assets/images/clicent-logo/image46.png";
import clicentlogo42 from "../../assets/images/clicent-logo/image47.webp";
import clicentlogo43 from "../../assets/images/clicent-logo/image48.webp";
import clicentlogo44 from "../../assets/images/clicent-logo/ktllogo1.webp";
import clicentlogo45 from "../../assets/images/clicent-logo/nikkypore1.png";
import clicentlogo46 from "../../assets/images/clicent-logo/rocare_india1.png";
import clicentlogo47 from "../../assets/images/clicent-logo/sundaysforever1.png";
import clicentlogo48 from "../../assets/images/clicent-logo/voiceoc.png";
import clicentlogo49 from "../../assets/images/clicent-logo/chiratae.webp";
import clicentlogo50 from "../../assets/images/clicent-logo/creek.webp";
import clicentlogo51 from "../../assets/images/clicent-logo/centoria_logo1.webp";
import clicentlogo52 from "../../assets/images/clicent-logo/nivesh-logo.png";
import clicentlogo54 from "../../assets/images/clicent-logo/luxester.webp";
import clicentlogo55 from "../../assets/images/jrny-1.svg";
import clicentlogo56 from "../../assets/images/jrny-2.svg";
import clicentlogo57 from "../../assets/images/clicent-logo/image50.webp";
import clicentlogo58 from "../../assets/images/clicent-logo/image58.webp";
import clicentlogo59 from "../../assets/images/clicent-logo/image59.webp";
import clicentlogo60 from "../../assets/images/clicent-logo/image60.webp";
import clicentlogo61 from "../../assets/images/clicent-logo/image61.webp";
import clicentlogo62 from "../../assets/images/clicent-logo/image62.webp";
import clicentlogo63 from "../../assets/images/clicent-logo/image63.webp";
import clicentlogo64 from "../../assets/images/clicent-logo/image64.webp";
import clicentlogo65 from "../../assets/images/clicent-logo/image65.webp";
import clicentlogo66 from "../../assets/images/clicent-logo/image66.webp";
import contactimgfile from "../../assets/images/download_contact.svg";
import whatappiconftr from "../../assets/images/whatappimg.png";

import learningimg from "../../assets/images/learning-dev.jpg";
import idea from "../../assets/images/idea.png";
import Integrityicon from "../../assets/images/Integrityicon.png";
import Collaborationicon from "../../assets/images/Collaborationicon.png";
import Qualityicon from "../../assets/images/Qualityicon.png";
import Sustainabilityicon from "../../assets/images/Sustainabilityicon.png";
import Responsibilityicon from "../../assets/images/Responsibilityicon.png";
import Inclusionicon from "../../assets/images/Inclusionicon.png";
import Agilityicon from "../../assets/images/Agilityicon.png";
import customericon from "../../assets/images/customer-focused.png";
// import banner  from "../../assets/images/banner_1.webp"
import mobilsapp from "../../assets/images/mobilsapp.png";
import mobilsapp1 from "../../assets/images/mobilsapp1.png";
import mobilsapp2 from "../../assets/images/mobilsapp2.png";
import mobilsapp3 from "../../assets/images/mobilsapp3.png";
import mobilsapp4 from "../../assets/images/mobilsapp4.png";
import laptopimg1 from "../../assets/images/laptopwebimg4.png";
import laptopimg2 from "../../assets/images/laptopwebimg3.png";
import laptopimg3 from "../../assets/images/laptopwebimg3.png";
import icon2017 from "../../assets/images/icon2017.svg";
import icon2019 from "../../assets/images/icon2019.svg";
import icon2020 from "../../assets/images/icon2020.svg";
import icon2021 from "../../assets/images/icon2021.svg";
import icon2022 from "../../assets/images/icon2022.svg";
import alwin from "../../assets/images/team1.jpg";
import team2 from "../../assets/images/team2.png";
// import team3 from "../../assets/images/team3.png"
import team4 from "../../assets/images/team4.png";
import team6 from "../../assets/images/team6.png";
import team7 from "../../assets/images/team7.png";
import team8 from "../../assets/images/team8.png";
import team9 from "../../assets/images/team9.png";
import team10 from "../../assets/images/team10.png";
// import team11 from "../../assets/images/team11.png";
import team12 from "../../assets/images/team13.png";
// import team13 from "../../assets/images/team14.png";
import moreimg from "../../assets/images/manymoreimg.png";
import officeimg from "../../assets/images/officeimg.png";
import mission1 from "../../assets/images/mission-1.svg";
import mission2 from "../../assets/images/mission-2.svg";
import missionimg from "../../assets/images/mission-img.svg";
import aboutbanner from "../../assets/images/about_banner.png";
import mistionfirs from "../../assets/images/misson1stimg.jpg";
import crm1 from "../../assets/images/benefits/crm.png";
import website from "../../assets/images/benefits/website.png";
import digitalmarketing from "../../assets/images/benefits/digital-marketing.png";
// import menuimg from "../../assets/images/top_megamenuimg.png";
import mockupimg1 from "../../assets/images/mockupimg1.png";
import mockupimg2 from "../../assets/images/mockupimg2.png";
import mockupimg3 from "../../assets/images/mockupimg3.png";
import mockupimg4 from "../../assets/images/mockupimg4.png";
import topmockupimg from "../../assets/images/clicent-logo/mockupnew1.webp";
import centermockupimg from "../../assets/images/clicent-logo/mockupnew2.webp";
import bottommockupimg from "../../assets/images/clicent-logo/mockupnew3.webp";
import topmockupimg1 from "../../assets/images/clicent-logo/mockupnew7.webp";
import centermockupimg1 from "../../assets/images/clicent-logo/mockupnew8.webp";
import bottommockupimg1 from "../../assets/images/clicent-logo/mockupnew9.webp";
import topmockupimg2 from "../../assets/images/clicent-logo/mockupnew4.webp";
import centermockupimg2 from "../../assets/images/clicent-logo/mockupnew5.webp";
import bottommockupimg2 from "../../assets/images/clicent-logo/mockupnew6.webp";
import topmockupimg3 from "../../assets/images/clicent-logo/mockupnew10.webp";
import centermockupimg3 from "../../assets/images/clicent-logo/mockupnew11.webp";
import bottommockupimg3 from "../../assets/images/clicent-logo/mockupnew12.webp";
import topmockupimg4 from "../../assets/images/clicent-logo/mockupnew13.webp";
import centermockupimg4 from "../../assets/images/clicent-logo/mockupnew14.webp";
import bottommockupimg4 from "../../assets/images/clicent-logo/mockupnew15.webp";
import mobilemocup from "../../assets/images/clicent-logo/mobile_mockup.webp";
import mobilemocup1 from "../../assets/images/clicent-logo/mobile_mockup1.webp";
import mobilemocup2 from "../../assets/images/clicent-logo/mobile_mockup2.webp";
import mobilemocup3 from "../../assets/images/clicent-logo/mobile_mockup3.webp";
import mobilemocup4 from "../../assets/images/clicent-logo/mobile_mockup4.webp";
// import mobilebannerhome from "../../assets/images/mobile_bannerhome.png"
import mobilebannerabout from "../../assets/images/mobile_bannerabout.png";
// bhawana imags
import edimg1 from "../../assets/images/edimg1.jpg";
import edtechaboutus from "../../assets/images/edtech/edtechaboutus.jpg";

import login from "../../assets/images/login.png";
import searchad from "../../assets/images/search-ad.png";
import measurement from "../../assets/images/measurement.png";
import schedule from "../../assets/images/schedule.png";
import track from "../../assets/images/track.png";
import tracking from "../../assets/images/tracking.png";
import payment from "../../assets/images/payment-method.png";
import history from "../../assets/images/order-history.png";
import notification from "../../assets/images/sms-notification.png";
import streaming from "../../assets/images/streaming.png";
import book from "../../assets/images/online-book.png";
import certificate from "../../assets/images/certificate.png";
import screen1 from "../../assets/images/screen1.png";
import screen2 from "../../assets/images/screen2.png";
import screen3 from "../../assets/images/screen3.png";
import screen4 from "../../assets/images/screen4.png";
import screen5 from "../../assets/images/screen5.png";
import screen6 from "../../assets/images/screen6.png";
import screen7 from "../../assets/images/screen7.png";
import screen8 from "../../assets/images/screen8.png";
import phone1 from "../../assets/images/phone.png";

// bhawana images add -------------------
import banner1 from "../../assets/images/ecommerce/banner1.jpg";

import head_img1 from "../../assets/images/ecommerce/head_img2.jpg";

import head_img2 from "../../assets/images/ecommerce/head_img1.jpg";

import feature1 from "../../assets/images/ecommerce/feature1.png";

import feature2 from "../../assets/images/ecommerce/feature2.png";

import feature3 from "../../assets/images/ecommerce/feature3.png";

import feature4 from "../../assets/images/ecommerce/feature4.png";

import feature5 from "../../assets/images/ecommerce/feature5.png";

import feature6 from "../../assets/images/ecommerce/feature6.png";

import feature7 from "../../assets/images/ecommerce/feature7.png";

import feature8 from "../../assets/images/ecommerce/feature8.png";

import feature9 from "../../assets/images/ecommerce/feature9.png";

import feature10 from "../../assets/images/ecommerce/feature10.png";

import feature11 from "../../assets/images/ecommerce/feature11.png";

import feature12 from "../../assets/images/ecommerce/feature12.png";

import dashboard from "../../assets/images/ecommerce/dashboard.png";

import product from "../../assets/images/ecommerce/product.png";

import user from "../../assets/images/ecommerce/user.png";

import employee from "../../assets/images/ecommerce/employee.png";

import vendor from "../../assets/images/ecommerce/vendor.png";

import rewards from "../../assets/images/ecommerce/rewards.png";

import help from "../../assets/images/ecommerce/help.png";

import membership from "../../assets/images/ecommerce/membership.png";

import upload from "../../assets/images/ecommerce/upload.png";

import catalog from "../../assets/images/ecommerce/catalog.png";

import order from "../../assets/images/ecommerce/order.png";

import delivery from "../../assets/images/ecommerce/delivery.png";

import report from "../../assets/images/ecommerce/report.png";

import module from "../../assets/images/ecommerce/modules.png";

import payments from "../../assets/images/ecommerce/payments.png";

import dot from "../../assets/images/ecommerce/dot.svg";

import escreen1 from "../../assets/images/ecommerce/escreen1.png";

import escreen2 from "../../assets/images/ecommerce/escreen2.png";

import escreen3 from "../../assets/images/ecommerce/escreen3.png";

import escreen4 from "../../assets/images/ecommerce/escreen4.png";

import escreen5 from "../../assets/images/ecommerce/escreen5.png";

import escreen6 from "../../assets/images/ecommerce/escreen6.png";

import escreen7 from "../../assets/images/ecommerce/escreen7.png";

import escreen8 from "../../assets/images/ecommerce/escreen8.png";

import escreen9 from "../../assets/images/ecommerce/escreen9.png";

import escreen10 from "../../assets/images/ecommerce/escreen10.png";

import app1 from "../../assets/images/ecommerce/app1.png";

import app2 from "../../assets/images/ecommerce/app2.png";

import app3 from "../../assets/images/ecommerce/app3.png";

import app4 from "../../assets/images/ecommerce/app4.png";

import app5 from "../../assets/images/ecommerce/app5.png";

import app6 from "../../assets/images/ecommerce/app6.png";

import engage1 from "../../assets/images/ecommerce/engage1.svg";

import engage2 from "../../assets/images/ecommerce/engage2.svg";

import engage3 from "../../assets/images/ecommerce/engage3.svg";

import engage4 from "../../assets/images/ecommerce/engage4.svg";

import check from "../../assets/images/ecommerce/check.svg";

import img1 from "../../assets/images/ecommerce/img_3.png";

import img2 from "../../assets/images/ecommerce/img_4.png";
import grobanner from "../../assets/images/grocery/grobanner.jpg";

import mobileapps1 from "../../assets/images/grocery/mobileapps1.png";

import mobileapps2 from "../../assets/images/grocery/mobileapps2.png";

import grocery_mart from "../../assets/images/grocery/grocery_mart.png";

import apps from "../../assets/images/grocery/apps.png";

import onboarding from "../../assets/images/grocery/onboarding.png";

import browse from "../../assets/images/grocery/browse.png";

import quick from "../../assets/images/grocery/quick.png";

import easyoption from "../../assets/images/grocery/easyoption.png";

import trackings from "../../assets/images/grocery/trackings.png";

import schedules from "../../assets/images/grocery/schedules.png";

import reorder from "../../assets/images/grocery/reorder.png";

import pushnot from "../../assets/images/grocery/pushnot.png";

import offerzone from "../../assets/images/grocery/offerzone.png";

import manage from "../../assets/images/grocery/manage.png";

import rating from "../../assets/images/grocery/rating.png";

import dashboards from "../../assets/images/grocery/dashboards.png";

import stores from "../../assets/images/grocery/stores.png";

import managers from "../../assets/images/grocery/managers.png";

import listing from "../../assets/images/grocery/listing.png";

import assiorder from "../../assets/images/grocery/assiorder.png";

import orders from "../../assets/images/grocery/orders.png";

import managecustm from "../../assets/images/grocery/managecustm.png";

import managepay from "../../assets/images/grocery/managepay.png";

import manageoff from "../../assets/images/grocery/manageoff.png";

import managefeed from "../../assets/images/grocery/managefeed.png";

import managenoti from "../../assets/images/grocery/managenoti.png";

import reporting from "../../assets/images/grocery/reporting.png";

import locator from "../../assets/images/grocery/locator.png";

import pickup from "../../assets/images/grocery/pickup.png";

import ordering from "../../assets/images/grocery/ordering.png";

import integration from "../../assets/images/grocery/integration.png";

import support from "../../assets/images/grocery/support.png";

import inventory from "../../assets/images/grocery/inventory.png";

import change from "../../assets/images/grocery/change.png";

import business1 from "../../assets/images/grocery/business1.jpg";

import business2 from "../../assets/images/grocery/business2.jpg";

import business3 from "../../assets/images/grocery/business3.jpg";

import business4 from "../../assets/images/grocery/business4.jpg";

import busicon1 from "../../assets/images/grocery/busicon1.png";

import busicon2 from "../../assets/images/grocery/busicon2.png";

import busicon3 from "../../assets/images/grocery/busicon3.png";

import busicon4 from "../../assets/images/grocery/busicon4.png";

import gro_screen1 from "../../assets/images/grocery/gro_screen1.png";

import gro_screen2 from "../../assets/images/grocery/gro_screen2.png";

import gro_screen3 from "../../assets/images/grocery/gro_screen3.png";

import gro_screen4 from "../../assets/images/grocery/gro_screen4.png";

import gro_screen5 from "../../assets/images/grocery/gro_screen5.png";

import gro_screen6 from "../../assets/images/grocery/gro_screen6.png";

import gro_screen7 from "../../assets/images/grocery/gro_screen7.png";

import gro_screen8 from "../../assets/images/grocery/gro_screen8.png";

import gro_screen9 from "../../assets/images/grocery/gro_screen9.png";

import gro_screen10 from "../../assets/images/grocery/gro_screen10.png";

import friendly from "../../assets/images/grocery/friendly.png";

import customize from "../../assets/images/grocery/customize.png";

import features_int from "../../assets/images/grocery/features_int.png";

import gateways from "../../assets/images/grocery/gateways.png";

import realtime from "../../assets/images/grocery/realtime.png";

import analytics from "../../assets/images/grocery/analytics.png";

import multistores from "../../assets/images/grocery/multistores.png";

import alerts from "../../assets/images/grocery/alerts.png";

import scale from "../../assets/images/grocery/scale.png";

// mukul add images-------------------------

import travelappbanner from "../../assets/images/travel-app-banner.webp";
import fileicon from "../../assets/images/travel-features/file-icon.png";
import magnifiericon from "../../assets/images/travel-features/magnifier.png";
import measurements from "../../assets/images/travel-features/3d-printer.png";
import scheduleicon from "../../assets/images/travel-features/scheduleicon.png";
import filter from "../../assets/images/travel-features/filter.png";
import monitor from "../../assets/images/travel-features/monitor.png";
import remainder from "../../assets/images/travel-features/remainder.png";
import event from "../../assets/images/travel-features/event.png";
import alarm from "../../assets/images/travel-features/alarm.png";
import thumbsup from "../../assets/images/travel-features/thumbs-up.png";
import howitworks from "../../assets/images/travel-features/how-it-works.png";
import travelbanner from "../../assets/images/travelbanner1.webp";
import travelportfolio from "../../assets/images/travel-portfolio/portfolio-img.png";

import handupapp from "../../assets/images/handupapp.png";
import apppngimages from "../../assets/images/apppngimages.png";
import lapimg from "../../assets/images/fntc_img13.png";
import avtarimg from "../../assets/images/avtarimg.png";
import mockup_img from "../../assets/images/mockup_img.png";
import fintechfeatures from "../../assets/images/fintech/fintech-features.png";
import premiumimg from "../../assets/images/clicent-logo/premium_img9.svg";
import shapeverticaldots from "../../assets/images/clicent-logo/shape_vertical_dots.png";
import fntcimg20 from "../../assets/images/clicent-logo/fntc_img20.webp";
import fntcimg from "../../assets/images/clicent-logo/fntc_img5.svg";
import maskgroup from "../../assets/images/clicent-logo/mask-group.webp";
import appicon from "../../assets/images/clicent-logo/apstr.webp";
import appicon1 from "../../assets/images/clicent-logo/gply_icon.png";
import jobportalbanner from "../../assets/images/job-portal/banner/job-portal-development.jpg";
import jobportalaboutus from "../../assets/images/job-portal/job-portal-aboutus.jpg";
import jobservicesuiux from "../../assets/images/job-portal/our-services/uiux.png";
import jobservicesuiuxwhite from "../../assets/images/job-portal/our-services/uiux-white.png";
import jobappdevelopment from "../../assets/images/job-portal/our-services/app-development.png";
import jobappdevelopmentwhite from "../../assets/images/job-portal/our-services/app-development-white.png";
import jobwebdevelopment from "../../assets/images/job-portal/our-services/coding.png";
import jobwebdevelopmentwhite from "../../assets/images/job-portal/our-services/coding-white.png";
import jobsoftware from "../../assets/images/job-portal/our-services/software.png";
import jobsoftwarewhite from "../../assets/images/job-portal/our-services/software-white.png";
import jobdatamigration from "../../assets/images/job-portal/our-services/migration.png";
import jobdatamigrationwhite from "../../assets/images/job-portal/our-services/migration-white.png";
import jobcandidatefeatures from "../../assets/images/job-portal/our-services/smartphone.png";
import jobcandidatefeatureswhite from "../../assets/images/job-portal/our-services/smartphone-white.png";
import ondemandbg from "../../assets/images/job-portal/on-demand/on-demand-bg.webp";
import ondemanduser from "../../assets/images/job-portal/on-demand/user.png";
import ondemandjobseeker from "../../assets/images/job-portal/on-demand/job-search.png";
import ondemandrecruiter from "../../assets/images/job-portal/on-demand/cv.png";
import ondemandadminbig from "../../assets/images/job-portal/on-demand/admin-big.png";
import ondemandjobseekerbig from "../../assets/images/job-portal/on-demand/job-seeker.png";
import ondemandjobrecruiterbig from "../../assets/images/job-portal/on-demand/job-recruiters.png";
import commonfeatures from "../../assets/images/job-portal/features/common-features.png";
import commonfeaturesb from "../../assets/images/job-portal/features/common-features-b.png";
import advancedfeatures from "../../assets/images/job-portal/features/advanced-features.png";
import advancedfeaturesw from "../../assets/images/job-portal/features/advance-features-w.png";
import featuresprofile from "../../assets/images/job-portal/features/features-box/profile.png";
import featuresmulti from "../../assets/images/job-portal/features/features-box/multilanguage_icon.png";
import featuresresume from "../../assets/images/job-portal/features/features-box/resume.png";
import featuresfilter from "../../assets/images/job-portal/features/features-box/filter.png";
import featuresnotifications from "../../assets/images/job-portal/features/features-box/notifications.png";
import featuresinappcall from "../../assets/images/job-portal/features/features-box/notifications.png";
import featuresinappchats from "../../assets/images/job-portal/features/features-box/in-app-chats.png";
import featuresprofilecretion from "../../assets/images/job-portal/features/features-box/profile-creation.png";
import featuresvideocall from "../../assets/images/job-portal/features/features-box/video-call.png";
import featurescloud from "../../assets/images/job-portal/features/features-box/cloud-management.png";
import featuresadtracker from "../../assets/images/job-portal/features/features-box/real-time-tracking.png";
import featuresdiscount from "../../assets/images/job-portal/features/features-box/discount.png";
import featureswebinars from "../../assets/images/job-portal/features/features-box/webinars.png";
import featurestemplates from "../../assets/images/job-portal/features/features-box/custom-templates.png";
import featuresapp from "../../assets/images/job-portal/features/features-box/nativeEnterprise.png";
import featuresupload from "../../assets/images/job-portal/features/features-box/what_icon6.png";
import recentwork1 from "../../assets/images/job-portal/recent-works/recent-work-1.png";
import recentwork2 from "../../assets/images/job-portal/recent-works/recent-work-2.png";
import recentwork3 from "../../assets/images/job-portal/recent-works/recent-work-3.png";
import certificate1 from "../../assets/images/job-portal/why-choose-jp/certificate.png";
import enghiringmodel1 from "../../assets/images/job-portal/eng-hiring-model/eng-hiring-img.png";
import onsite from "../../assets/images/job-portal/eng-hiring-model/icons/on-site.svg";
import offsite from "../../assets/images/job-portal/eng-hiring-model/icons/off-site.svg";
import hybrid from "../../assets/images/job-portal/eng-hiring-model/icons/hybrid.svg";
import fixedprice from "../../assets/images/job-portal/eng-hiring-model/icons/fixed-price.svg";
import dedicatedhiring from "../../assets/images/job-portal/eng-hiring-model/icons/dedicated-hiring.svg";
import hourlybasis from "../../assets/images/job-portal/eng-hiring-model/icons/hourly-basis.svg";
import discussSpecialistsbg from "../../assets/images/job-portal/discuss-specialist/discuss-specialistsbg.webp";
import healthcarebanner from "../../assets/images/healthcare/healthcare-banner.webp";
import healthcareabout from "../../assets/images/healthcare/about-healthcare.webp";
import healthcaremonitor from "../../assets/images/healthcare/monitor.png";
import healthcaremigration from "../../assets/images/healthcare/migration.png";
import healthcarecheckicon from "../../assets/images/healthcare/check-circle-icon.png";
import healthchooseus1 from "../../assets/images/healthcare/whychooseus/domain-expert.png";
import healthchooseus2 from "../../assets/images/healthcare/whychooseus/robust.png";
import healthchooseus3 from "../../assets/images/healthcare/whychooseus/innovation.png";
import healthchooseus4 from "../../assets/images/healthcare/whychooseus/quality-control.png";
import healthchooseus5 from "../../assets/images/healthcare/whychooseus/easy-use.png";
import healthchooseus6 from "../../assets/images/healthcare/whychooseus/translation.png";
import healthcarehexagon from "../../assets/images/healthcare/hexagon-yellow.png";
import healthcareiconcheck from "../../assets/images/healthcare/icon-check.png";
import healthcareportfolio1 from "../../assets/images/healthcare/portfolio/helthcare1.png";
import healthcareportfolio2 from "../../assets/images/healthcare/portfolio/healthcare2.png";
import healthcareportfolio3 from "../../assets/images/healthcare/portfolio/healthcare3.png";

// import erpbanner from "../../assets/images/Erp/erp-banner.png";
import erpcta from "../../assets/images/Erp/erp-cta.png";
import erpsupplychain from "../../assets/images/Erp/achievements/supply-chain-management.png";
import erpsmanufacturing from "../../assets/images/Erp/achievements/manufacturing.png";
import erpcustomerrm from "../../assets/images/Erp/achievements/customer-relations.png";
import erpinventory from "../../assets/images/Erp/achievements/inventory.png";
import erpbusinessintell from "../../assets/images/Erp/achievements/business-intelligence.png";
import erpmarketing from "../../assets/images/Erp/achievements/marketing-sales.png";
import erpfinancial from "../../assets/images/Erp/achievements/financials-accounting.png";
import erpanalytics from "../../assets/images/Erp/achievements/analytics.png";
import erphumanresource from "../../assets/images/Erp/achievements/human-resources.png";
import erppurchasing from "../../assets/images/Erp/achievements/purchase.png";
import erpprojectmanagement from "../../assets/images/Erp/achievements/project-management.png";
import erpdocmanagement from "../../assets/images/Erp/achievements/document-adjustment.png";
import erpchatbot from "../../assets/images/Erp/achievements/live-chat.png";
import erpconsult from "../../assets/images/Erp/development-services/consult.png";
import erpsolutions from "../../assets/images/Erp/development-services/solutions.png";
import erpoperation from "../../assets/images/Erp/development-services/operation.png";
import erpappdevelop from "../../assets/images/Erp/development-services/data.png";
import erpservices from "../../assets/images/Erp/development-services/customer-service.png";
import erpstaff from "../../assets/images/Erp/development-services/teamwork.png";
import erpinventorymanage from "../../assets/images/Erp/explore-services/inventory-management.jpg";
import erpsalespurchase from "../../assets/images/Erp/explore-services/salesandpurchase.png";
import erpproduction from "../../assets/images/Erp/explore-services/production.png";
import erpcheck from "../../assets/images/Erp/explore-services/check.png";
import erpaboutimg from "../../assets/images/Erp/erp-about-img.png";
import erptailored from "../../assets/images/Erp/software-solutions/tailored.png";
import erpflexible from "../../assets/images/Erp/software-solutions/flexible.png";
import erpimplement from "../../assets/images/Erp/software-solutions/implementation.png";
import erpcustomsoftwaresolutions from "../../assets/images/Erp/software-solutions/customsoftwaresolutions.png";

// import crmmainbanner from "../../assets/images/CRM/CRM-banner.png";
import crmaboutimg from "../../assets/images/CRM/crm_aboutimg.webp";
import crmcustomerservices from "../../assets/images/CRM/industries-we-cater/customer-service.png";
import crmindustimg from "../../assets/images/CRM/industries-we-cater/main-img.jpg";
import crmuserfriendly from "../../assets/images/CRM/features/high-five.png";
import crmrobustdata from "../../assets/images/CRM/features/robust.png";
import crmworkflow from "../../assets/images/CRM/features/operation.png";
import crmcustomized from "../../assets/images/CRM/features/customize.png";
import crmcommunicationtools from "../../assets/images/CRM/features/mobile-marketing.png";
import crmsecurity from "../../assets/images/CRM/features/verified.png";
import crmexperience from "../../assets/images/CRM/why-do-need/experience.png";
import crmefficiency from "../../assets/images/CRM/why-do-need/efficacy.png";
import crmscalability from "../../assets/images/CRM/why-do-need/flexibility.png";
import crmdecision from "../../assets/images/CRM/why-do-need/decision.png";
import crmdatasecurity from "../../assets/images/CRM/why-do-need/secure-data.png";
import crmbusinessprocess from "../../assets/images/CRM/why-do-need/iteration.png";
import crmdevelopment from "../../assets/images/CRM/crm-development-services/crm-development.png";
import crmdevelopmentw from "../../assets/images/CRM/crm-development-services/crm-developmentw.png";
import crmintegration from "../../assets/images/CRM/crm-development-services/crm-integration.png";
import crmintegrationw from "../../assets/images/CRM/crm-development-services/crm-integrationw.png";
import crmmigration from "../../assets/images/CRM/crm-development-services/migration.png";
import crmmigrationw from "../../assets/images/CRM/crm-development-services/migrationw.png";
import crmsupport from "../../assets/images/CRM/crm-development-services/crm-support.png";
import crmsupportw from "../../assets/images/CRM/crm-development-services/crm-supportw.png";
import crmhealthcare from "../../assets/images/CRM/industries-we-cater/healthcare.png";
import crmretial from "../../assets/images/CRM/industries-we-cater/shopping-store.png";
import crmrealestate from "../../assets/images/CRM/industries-we-cater/agreement.png";
import crmeducation from "../../assets/images/CRM/industries-we-cater/mortarboard.png";
import crmfinance from "../../assets/images/CRM/industries-we-cater/budget.png";
import crmmanufacturing from "../../assets/images/CRM/industries-we-cater/factory.png";
import crmbenefitsfinance from "../../assets/images/CRM/benefits-custom-crm/finance.png";
import crmbenefitshospitality from "../../assets/images/CRM/benefits-custom-crm/sundays-forever.png";
import crmbenefitsfashion from "../../assets/images/CRM/benefits-custom-crm/ahilya.png";
import crmbenefitseducation from "../../assets/images/CRM/benefits-custom-crm/spotlight.png";
import crmbenefitsfinance1 from "../../assets/images/CRM/benefits-custom-crm/nivesh.png";
import crmbenefitsgovernment from "../../assets/images/CRM/benefits-custom-crm/QCW.png";
import SchoolManagementbanner from "../../assets/images/School-Management/school-managemente.webp";
import SchoolManagementaboutimg from "../../assets/images/School-Management/school-aboutus.webp";
import Schmtcustomdevelopment from "../../assets/images/School-Management/sch-mt-software-services/custom-development.png";
import Schmtcustomized from "../../assets/images/School-Management/sch-mt-software-services/customization.png";
import Schmtintegration from "../../assets/images/School-Management/sch-mt-software-services/integration.png";
import Schmtmodernization from "../../assets/images/School-Management/sch-mt-software-services/modernization.png";
import Schmtaccounting from "../../assets/images/School-Management/modules/accounting.png";
import Schmtfinance from "../../assets/images/School-Management/modules/financial-management.png";
import Schmtinventory from "../../assets/images/School-Management/modules/inventory-mt.png";
import Schmtlibrary from "../../assets/images/School-Management/modules/library.png";
import Schmttransportation from "../../assets/images/School-Management/modules/transportation.png";
import Schmttimetable from "../../assets/images/School-Management/modules/timetable.png";
import Schmtattendance from "../../assets/images/School-Management/modules/attendance.png";
import Schmtperformance from "../../assets/images/School-Management/modules/performance.png";
import Schmtadmission from "../../assets/images/School-Management/modules/admission.png";
import Schmtpolicy from "../../assets/images/School-Management/modules/policy.png";
import Schmtinquiry from "../../assets/images/School-Management/modules/Inquiry.png";
import Schmtstudent from "../../assets/images/School-Management/modules/student.png";
import Schmtteacherapp from "../../assets/images/School-Management/features/teacher-app.jpg";
import Schmtstudentapp from "../../assets/images/School-Management/features/studentapp1.png";
import Schmtparentsapp from "../../assets/images/School-Management/features/parents-app.jpg";
import Schmtadminapp from "../../assets/images/School-Management/features/adminapp1.png";
import Schmtprocessimg from "../../assets/images/School-Management/process/school-development-process.jpg";
import Schmtgathering from "../../assets/images/School-Management/process/data-gathering.png";
import Schmtgatheringh from "../../assets/images/School-Management/process/data-gathering-hover.png";
import Schprocessdots from "../../assets/images/School-Management/process/dots-bg.png";
import Schtestingassurance from "../../assets/images/School-Management/process/testingassurance.png";
import Schdevelopmentcoding from "../../assets/images/School-Management/process/development-and-coding.png";
import Schdeployment from "../../assets/images/School-Management/process/deployment.png";
import Schgatheringanalysis from "../../assets/images/School-Management/process/gathering-analysis.png";
import Schprototyping from "../../assets/images/School-Management/process/design-prototyping.png";
import Schcontinousimprovement from "../../assets/images/School-Management/process/continous-improvement.png";
import Schtraining from "../../assets/images/School-Management/process/training.png";
import Schmtadvantages from "../../assets/images/School-Management/advantages/sch-advantages.png";
import Schmtstudentappvideo from "../../assets/images/School-Management/features/student-app.gif";
import Schmtportfolio1 from "../../assets/images/School-Management/portfolio/schportfolio1.png";
import Schmtportfolio2 from "../../assets/images/School-Management/portfolio/schportfolio2.png";
import Schmtportfolio3 from "../../assets/images/School-Management/portfolio/schportfolio3.png";
import Schmtportfolio4 from "../../assets/images/School-Management/portfolio/schportfolio4.png";
import Schmtportfolio5 from "../../assets/images/School-Management/portfolio/schportfolio5.png";
import Schmtportfolio6 from "../../assets/images/School-Management/portfolio/schportfolio6.png";
import Schmtportfolio7 from "../../assets/images/School-Management/portfolio/schportfolio7.png";
import Schmtportfolio8 from "../../assets/images/School-Management/portfolio/schportfolio8.png";
import Societymtbanner from "../../assets/images/society-management/society-banner.webp";
import Societyuserverified from "../../assets/images/society-management/features/verification.png";
import Societysecurity from "../../assets/images/society-management/features/padlock.png";
import Societybooking from "../../assets/images/society-management/features/booking.png";
import Societynoticeboard from "../../assets/images/society-management/features/board.png";
import Societybills from "../../assets/images/society-management/features/bill.png";
import Societyannouncements from "../../assets/images/society-management/features/promotion.png";
import Societyparking from "../../assets/images/society-management/features/parking.png";
import Societyappdevelopment from "../../assets/images/society-management/services/app-development.png";
import Societyintegration from "../../assets/images/society-management/services/distributed.png";
import Societycustomize from "../../assets/images/society-management/services/customize.png";
import Societyconsultancy from "../../assets/images/society-management/services/talk.png";
import Societywhychooseus from "../../assets/images/society-management/why-choose-us/why-choose-us.jpg";
import Societyexpertise from "../../assets/images/society-management/why-choose-us/quality.png";
import Societyuserfriendly from "../../assets/images/society-management/why-choose-us/high-five.png";
import Societyportfolio from "../../assets/images/society-management/portfolio/portfolio.png";
import Societyportfolio1 from "../../assets/images/society-management/portfolio/portfolio1.png";
import Societyportfolio2 from "../../assets/images/society-management/portfolio/portfolio2.png";
import Societyportfolio3 from "../../assets/images/society-management/portfolio/portfolio3.png";
import Societyportfolio4 from "../../assets/images/society-management/portfolio/portfolio4.png";
import Societyportfolio5 from "../../assets/images/society-management/portfolio/portfolio5.png";
import Societyportfolio6 from "../../assets/images/society-management/portfolio/portfolio6.png";
import Societyportfolio7 from "../../assets/images/society-management/portfolio/portfolio7.png";
import Societyportfolio8 from "../../assets/images/society-management/portfolio/portfolio8.png";
import Societyportfolio9 from "../../assets/images/society-management/portfolio/portfolio9.png";
import realestatebanner from "../../assets/images/Real-Estate/real-estate-banner.png";
import realestateaboutus from "../../assets/images/Real-Estate/real-estate-about-uss.png";
import realestservicesmobileapp from "../../assets/images/Real-Estate/services/mobile-app.png";
import realestserviceswebapp from "../../assets/images/Real-Estate/services/coding.png";
import realestservicesrentingapp from "../../assets/images/Real-Estate/services/renting-app.png";
import realestservicesproperty from "../../assets/images/Real-Estate/services/property.png";
import realestserviceslead from "../../assets/images/Real-Estate/services/team-management.png";
import realestservicesmaintenance from "../../assets/images/Real-Estate/services/maintenance.png";
import realestfeatureslogin from "../../assets/images/Real-Estate/features/log-in.png";
import realestfeaturesagentprofile from "../../assets/images/Real-Estate/features/agent-profile.png";
import realestfeatureschat from "../../assets/images/Real-Estate/features/chat-messaging.png";
import realestfeaturespropertylisting from "../../assets/images/Real-Estate/features/property-listings.png";
import realestfeaturesfavlisting from "../../assets/images/Real-Estate/features/fav-listing.png";
import realestfeaturesmainimg from "../../assets/images/Real-Estate/features/real-estate-features.png";
import erpportfolio1 from "../../assets/images/Erp/portfolio/erp-portfolio-1.png";
import erpportfolio2 from "../../assets/images/Erp/portfolio/erp-portfolio-2.png";
import erpportfolio3 from "../../assets/images/Erp/portfolio/erp-portfolio-3.png";

import crmportfolioebslon from "../../assets/images/CRM/portfolio/crm-ebslon.png";
import crmportfoliorocare from "../../assets/images/CRM/portfolio/Ro-care-crm.png";
import crmportfoliofliptrip from "../../assets/images/CRM/portfolio/crm-fliptrip.png";
import healthcareindustryspecificmain from "../../assets/images/healthcare/solutions/industry-specific-main.png";
import realestmngmnticon1 from "../../assets/images/Real-Estate/management/dedicated-resource.png";
import realestmngmntcosteffective from "../../assets/images/Real-Estate/management/cost-effective.png";
import realestmngmnttimelydelivery from "../../assets/images/Real-Estate/management/fast-delivery.png";
import realestmngmntreadyproject from "../../assets/images/Real-Estate/management/ready-for-project.png";

import realestmobappcustomize from "../../assets/images/Real-Estate/mobile-app-solutions/customization.png";
import realestmobappsprite from "../../assets/images/Real-Estate/mobile-app-solutions/real-estate-app-sprite1.png";
import realestappdevelopmentimg from "../../assets/images/Real-Estate/real-estate-app-development/real-estate-app-Features.png";
import realestexplore from "../../assets/images/Real-Estate/real-estate-app-development/explore.png";
import realestexplorey from "../../assets/images/Real-Estate/real-estate-app-development/explore-y.png";
import realestsignup from "../../assets/images/Real-Estate/real-estate-app-development/signup.png";
import realestsignupy from "../../assets/images/Real-Estate/real-estate-app-development/signup-y.png";
import realestsearchresult from "../../assets/images/Real-Estate/real-estate-app-development/search-result.png";
import realestsearchresulty from "../../assets/images/Real-Estate/real-estate-app-development/serach-result-y.png";
import realestlistproperty from "../../assets/images/Real-Estate/real-estate-app-development/list-property.png";
import realestlistpropertyy from "../../assets/images/Real-Estate/real-estate-app-development/list-property-y.png";
import realestsearchfilter from "../../assets/images/Real-Estate/real-estate-app-development/search-filter.png";
import realestsearchfiltery from "../../assets/images/Real-Estate/real-estate-app-development/search-filter-y.png";
import realestbuysellrent from "../../assets/images/Real-Estate/real-estate-app-development/buy-sell-rent.png";
import realestbuysellrenty from "../../assets/images/Real-Estate/real-estate-app-development/buy-sell-rent-y.png";
import realestsettings from "../../assets/images/Real-Estate/real-estate-app-development/settings.png";
import realestsettingsy from "../../assets/images/Real-Estate/real-estate-app-development/settings-y.png";
import realestchat from "../../assets/images/Real-Estate/real-estate-app-development/chat.png";
import realestchaty from "../../assets/images/Real-Estate/real-estate-app-development/chat-y.png";
import realestmap from "../../assets/images/Real-Estate/real-estate-app-development/map.png";
import realestmapy from "../../assets/images/Real-Estate/real-estate-app-development/map-y.png";
import realestdashboard from "../../assets/images/Real-Estate/real-estate-app-development/dashboard.png";
import realestdashboardy from "../../assets/images/Real-Estate/real-estate-app-development/dashboard-y.png";
import realestexclusivedeals from "../../assets/images/Real-Estate/real-estate-app-development/exclusive.png";
import realestexclusivedealsy from "../../assets/images/Real-Estate/real-estate-app-development/exclusive-y.png";
import realestpropertydetails from "../../assets/images/Real-Estate/real-estate-app-development/property-details.png";
import realestpropertydetailsy from "../../assets/images/Real-Estate/real-estate-app-development/property-details-y.png";
import realestportfolio1 from "../../assets/images/Real-Estate/portfolio/portfolio1.png";
import realestportfolio2 from "../../assets/images/Real-Estate/portfolio/portfolio2.png";
import manufacturingbanner from "../../assets/images/Manufacturing/manufacturing-banner.jpg";
import manufacturingaboutus from "../../assets/images/Manufacturing/mft-about-us.jpg";
import manufacturingsolution1 from "../../assets/images/Manufacturing/mft-solutions/mftsolution1.png";
import manufacturingsolutioninventory from "../../assets/images/Manufacturing/mft-solutions/mftsolutioninventory.png";
import manufacturingsolutionanalytics from "../../assets/images/Manufacturing/mft-solutions/mftsolutionanalytcs.png";
import mftsolutionplanning from "../../assets/images/Manufacturing/mft-solutions/product-panning-1.png";
import mftsolutionwork from "../../assets/images/Manufacturing/mft-solutions/work-order-1.png";
import mftsolutionbi from "../../assets/images/Manufacturing/mft-solutions/bi-reports-1.png";
import mftsolutioninventory from "../../assets/images/Manufacturing/mft-solutions/inventory-management-1.png";
import mftsolutionsales from "../../assets/images/Manufacturing/mft-solutions/sales-production-1.png";
import mftsolutionexintegration from "../../assets/images/Manufacturing/mft-solutions/external-integration-1.png";
import mftservicesplanning from "../../assets/images/Manufacturing/mft-services/Production-Planning-Reporting-Solution-01.png";
import mftservicesorder from "../../assets/images/Manufacturing/mft-services/Order-Inventory-Management-Software.png";
import mftservicessmartprod from "../../assets/images/Manufacturing/mft-services/Smart-Products.png";
import mftservicescustomerbehavior from "../../assets/images/Manufacturing/mft-services/Customer-Behavior-Analysis-1.png";
import mftservicesmaintenance from "../../assets/images/Manufacturing/mft-services/Predictive-Maintenance.png";
import mftservicesintegration from "../../assets/images/Manufacturing/mft-services/Integration-into-Manufacturing-Software-Solutions-07.png";
import mftourworks from "../../assets/images/Manufacturing/our-works/manufacturing.png";
import retailposbanner from "../../assets/images/RetailPos/Retail-pos-banner.webp";
import retailposaboutus from "../../assets/images/RetailPos/about-retail-pos.jpg";

import retailposexponentialtech from "../../assets/images/RetailPos/why-choose-us/exponential-technology-icon.png";
import retailpossuperiorarcht from "../../assets/images/RetailPos/why-choose-us/superior-architecture-icon.png";
import retailposdesignthink from "../../assets/images/RetailPos/why-choose-us/design-thinking-icon.png";
import retailposcustomize from "../../assets/images/RetailPos/why-choose-us/custom.png";

import retailposimpretailexp from "../../assets/images/RetailPos/importance-retail-pos/customercentric.png";
import retailposimpsecure from "../../assets/images/RetailPos/importance-retail-pos/highlysecure.png";
import retailposportfolio1 from "../../assets/images/RetailPos/portfolio/retail-portfolio1.png";
import retailposportfolio2 from "../../assets/images/RetailPos/portfolio/retail-portfolio2.png";
import whatsappbotbanner from "../../assets/images/Whatsapp-Bot/whatsapp-bot-banner.jpg";
import whatsappbotaboutlandingmobile from "../../assets/images/Whatsapp-Bot/about-us/landing-mobile.png";
import whatsappbotaboutlandingdialog from "../../assets/images/Whatsapp-Bot/about-us/landing-dialog.png";
import whatsappbotwhyyouneedwiden from "../../assets/images/Whatsapp-Bot/why-you-need/wider-reach-1.png";
import whatsappbotwhyyouneedreadrate from "../../assets/images/Whatsapp-Bot/why-you-need/high-open-read-rate-1.png";
import whatsappbotwhyyouneedcsat from "../../assets/images/Whatsapp-Bot/why-you-need/increased-customer-satisfaction-1.png";
import whatsappbotwhyyouneed24by7 from "../../assets/images/Whatsapp-Bot/why-you-need/24x7-availability-2.png";
import whatsappbotwhyyouneedresponserate from "../../assets/images/Whatsapp-Bot/why-you-need/high-response-rate-1.png";
import whatsappbotwhyyouneedconnectagents from "../../assets/images/Whatsapp-Bot/why-you-need/connect-to-agents-1.png";
import whatsappbotwhyyouneedcostreduce from "../../assets/images/Whatsapp-Bot/why-you-need/cost-reduction-1.png";
import whatsappbotwhyyouneedbrandrecall from "../../assets/images/Whatsapp-Bot/why-you-need/brand-recall-1.png";
import whatsappbotbenefitspersonworking from "../../assets/images/Whatsapp-Bot/whatsappbot-benefits/person-working.png";
import whatsappbotbenefitsfaq from "../../assets/images/Whatsapp-Bot/whatsappbot-benefits/faq.png";
import whatsappbotbenefitsrichtext from "../../assets/images/Whatsapp-Bot/whatsappbot-benefits/rich-text.png";
import whatsappbotbenefitsdatacollection from "../../assets/images/Whatsapp-Bot/whatsappbot-benefits/data-collection.png";
import whatsappbotbenefitsleads from "../../assets/images/Whatsapp-Bot/whatsappbot-benefits/qualify-leads.png";
import whatsappbotbenefitscustomer from "../../assets/images/Whatsapp-Bot/whatsappbot-benefits/customer-retention.png";
import whatsappbotbottombanner from "../../assets/images/Whatsapp-Bot/bottom-banner.png";
import whatsappbotchannelswhatsapp from "../../assets/images/Whatsapp-Bot/allchannels/whatsapp.png";
import whatsappbotchannelsinstagram from "../../assets/images/Whatsapp-Bot/allchannels/instagram.png";
import whatsappbotchannelswebsite from "../../assets/images/Whatsapp-Bot/allchannels/website.png";
import whatsappbotchannelsfb from "../../assets/images/Whatsapp-Bot/allchannels/FB-Messanger.png";
import whatsappbotchannelsiMessage from "../../assets/images/Whatsapp-Bot/allchannels/iMessage.png";
import whatsappbotchannelsViber from "../../assets/images/Whatsapp-Bot/allchannels/viber.png";
import whatsappbotchannelsGoogle from "../../assets/images/Whatsapp-Bot/allchannels/Google.png";
import whatsappbotplaybtn from "../../assets/images/Whatsapp-Bot/play-button.png";
import stepprocessimg1 from "../../assets/images/Whatsapp-Bot/steps-process/1.png";
import stepprocessimg2 from "../../assets/images/Whatsapp-Bot/steps-process/2.png";
import stepprocessimg3 from "../../assets/images/Whatsapp-Bot/steps-process/3.png";
import stepprocessimg4 from "../../assets/images/Whatsapp-Bot/steps-process/parallax_4.png";
import stepprocessimg5 from "../../assets/images/Whatsapp-Bot/steps-process/parallax_5.png";
import stepprocessimg6 from "../../assets/images/Whatsapp-Bot/steps-process/parallax_6.png";
import retailposefficient from "../../assets/images/RetailPos/features/statistics.png";
import retailposefficientw from "../../assets/images/RetailPos/features/statisticsw.png";
import retailposstreamlined from "../../assets/images/RetailPos/features/streamlined-operations.jpg";
import retailposcustomerexp from "../../assets/images/RetailPos/features/customer-experience.jpg";
import retailpossalestracking from "../../assets/images/RetailPos/features/sales-tracking.jpg";
import retailpossecurepayment from "../../assets/images/RetailPos/features/secure-payments.jpg";
import retailpostimesaving from "../../assets/images/RetailPos/features/save-time-y.png";
import retailpostimesavingw from "../../assets/images/RetailPos/features/save-time-w.png";
import retailposcheckout from "../../assets/images/RetailPos/features/checkout-y.png";
import retailposcheckoutw from "../../assets/images/RetailPos/features/checkout-w.png";
import retailposrealtime from "../../assets/images/RetailPos/features/real-time-y.png";
import retailposrealtimew from "../../assets/images/RetailPos/features/real-time-w.png";
import retailposdetailreporting from "../../assets/images/RetailPos/features/reporting-y.png";
import retailposdetailreportingw from "../../assets/images/RetailPos/features/reporting-w.png";
import retailposfraud from "../../assets/images/RetailPos/features/fraud-prevention-y.png";
import retailposfraudw from "../../assets/images/RetailPos/features/fraud-prevention-w.png";
import retailposfinancialmt from "../../assets/images/RetailPos/features/fiancial-management-y.png";
import retailposfinancialmtw from "../../assets/images/RetailPos/features/fiancial-management-w.png";
import whatsappbotvoiceaiy from "../../assets/images/Whatsapp-Bot/value-added-features/voice-ai-y.png";
import whatsappbotvoiceaiw from "../../assets/images/Whatsapp-Bot/value-added-features/voice-ai-w.png";
import whatsappbotemotionaly from "../../assets/images/Whatsapp-Bot/value-added-features/emotional-intelligent-y.png";
import whatsappbotemotionalw from "../../assets/images/Whatsapp-Bot/value-added-features/emotional-intelligent-w.png";
import whatsappbotaiagenty from "../../assets/images/Whatsapp-Bot/value-added-features/agent-assist-y.png";
import whatsappbotaiagentw from "../../assets/images/Whatsapp-Bot/value-added-features/agent-assist-w.png";
import whatsappbotlanguagey from "../../assets/images/Whatsapp-Bot/value-added-features/language-y.png";
import whatsappbotlagnuagew from "../../assets/images/Whatsapp-Bot/value-added-features/language-w.png";
import whatsappbotanalyticsy from "../../assets/images/Whatsapp-Bot/value-added-features/intelligent-analytics-y.png";
import whatsappbotanalyticsw from "../../assets/images/Whatsapp-Bot/value-added-features/intelligent-analytics-w.png";
import whatsappbotintegrationy from "../../assets/images/Whatsapp-Bot/value-added-features/integration-y.png";
import whatsappbotintegrationw from "../../assets/images/Whatsapp-Bot/value-added-features/integration-w.png";
import whatsappbotvoiceaibig from "../../assets/images/Whatsapp-Bot/value-added-features/voice-ai-big.png";
import whatsappbotemotionalbig from "../../assets/images/Whatsapp-Bot/value-added-features/emotional-intelligence-big.png";
import whatsappbotaiagentbig from "../../assets/images/Whatsapp-Bot/value-added-features/ai-agent-assistant-big.png";
import whatsappbotlanguagebig from "../../assets/images/Whatsapp-Bot/value-added-features/language-big.png";
import whatsappbotanalyticsbig from "../../assets/images/Whatsapp-Bot/value-added-features/intelligent-analytics-big.png";
import whatsappbotintegrationbig from "../../assets/images/Whatsapp-Bot/value-added-features/integrations-big.png";
import whatsappbotchannelswhatsappicon from "../../assets/images/Whatsapp-Bot/allchannels/whatsapp-icon.png";
import whatsappbotchannelsinstagramicon from "../../assets/images/Whatsapp-Bot/allchannels/instagram-icon.png";
import whatsappbotchannelswebsiteicon from "../../assets/images/Whatsapp-Bot/allchannels/website-icon.png";
import whatsappbotchannelsfbicon from "../../assets/images/Whatsapp-Bot/allchannels/fb-icon.png";
import whatsappbotchannelsiMessageicon from "../../assets/images/Whatsapp-Bot/allchannels/iMessage-icon.png";
import whatsappbotchannelsVibericon from "../../assets/images/Whatsapp-Bot/allchannels/viber-icon.png";
import whatsappbotchannelsGoogleicon from "../../assets/images/Whatsapp-Bot/allchannels/google-messages-icon.png";
import fastobannerimg from "../../assets/images/casestudy/case-study-1/banner/fasto-banner-img.png";
import Casestudyabout from "../../assets/images/casestudy/case-study-1/intro/about-client.png";
import fastoorderplacement from "../../assets/images/casestudy/case-study-1/business-model/order-placement.png";
import fastoorderplacementw from "../../assets/images/casestudy/case-study-1/business-model/order-placement-w.png";
import fastoproductrange from "../../assets/images/casestudy/case-study-1/business-model/product-range.png";
import fastoproductrangew from "../../assets/images/casestudy/case-study-1/business-model/product-range-w.png";
import fastodeliverylogistics from "../../assets/images/casestudy/case-study-1/business-model/logistics.png";
import fastodeliverylogisticsw from "../../assets/images/casestudy/case-study-1/business-model/logistics-w.png";
import fastomarketresearch from "../../assets/images/casestudy/case-study-1/market-research/market-research.png";
import fastocheckicon from "../../assets/images/casestudy/case-study-1/market-research/checkdiscount.png";
import fastotechnology from "../../assets/images/casestudy/case-study-1/market-research/technology.png";
import fastoresultsgrowth from "../../assets/images/casestudy/case-study-1/results-growth/results-growth.png";
import fastofutureprospects from "../../assets/images/casestudy/case-study-1/future-prospects/future-prospects.png";
import fastochallengessolutions from "../../assets/images/casestudy/case-study-1/challenges-solutions/challenges-solution.png";
import fastologo from "../../assets/images/casestudy/case-study-1/logo/fasto-logo.png";
import huddleheartlogo from "../../assets/images/casestudy/Huddle-heart/banner/logo.png";
import huddleheartbannerimg from "../../assets/images/casestudy/Huddle-heart/banner/huddle-heart-banner-img.png";
import huddleheartabout from "../../assets/images/casestudy/Huddle-heart/intro/about-img.png";
import huddleheartresearchimg from "../../assets/images/casestudy/Huddle-heart/market-research/market-research.png";
import huddleheartorderplacement from "../../assets/images/casestudy/Huddle-heart/business-model/order-placement.png";
import huddleheartproductrange from "../../assets/images/casestudy/Huddle-heart/business-model/product-range.png";
import huddleheartdeliverylogistics from "../../assets/images/casestudy/Huddle-heart/business-model/logistics.png";
import huddleheartchallengessolutions from "../../assets/images/casestudy/Huddle-heart/challenges-solutions/challenges-solutions.png";
import huddleheartsafteyconcerns from "../../assets/images/casestudy/Huddle-heart/challenges-solutions/safety-blue.png";
import huddleheartnomeetup from "../../assets/images/casestudy/Huddle-heart/challenges-solutions/nomeet.png";
import huddleheartmislead from "../../assets/images/casestudy/Huddle-heart/challenges-solutions/mislead.png";
import huddleheartimpacts from "../../assets/images/casestudy/Huddle-heart/impacts/impacts.png";
import huddleheartfutureprospects from "../../assets/images/casestudy/Huddle-heart/future-prospects/future-prospects.png";
import huddleheartunwantedmsg from "../../assets/images/casestudy/Huddle-heart/challenges-solutions/unwanted-msg.png";
import huddleheartbaddates from "../../assets/images/casestudy/Huddle-heart/challenges-solutions/bad-dates.png";
import huddlehearthookup from "../../assets/images/casestudy/Huddle-heart/challenges-solutions/hookup-culture.png";
import huddleheartenhancedcommunication from "../../assets/images/casestudy/Huddle-heart/enhanced-communication/enhanced-communication.png";
import huddleheartuserprofile1 from "../../assets/images/casestudy/Huddle-heart/profile-banner/profile-1.png";
import huddleheartuserprofile2 from "../../assets/images/casestudy/Huddle-heart/profile-banner/profile-2.png";
import huddleheartuserprofile3 from "../../assets/images/casestudy/Huddle-heart/profile-banner/profile-3.png";
import huddleheartheartshape from "../../assets/images/casestudy/Huddle-heart/about-us/heart-shape.png";
import huddlehearteclipiseshape from "../../assets/images/casestudy/Huddle-heart/about-us/eclipise.png";
import huddleheartaboutusimg from "../../assets/images/casestudy/Huddle-heart/about-us/intro.png";
import huddleheartsimplesecure from "../../assets/images/casestudy/Huddle-heart/features/simple-secure.png";
import huddleheartswipe from "../../assets/images/casestudy/Huddle-heart/features/swipe.png";
import huddleheartrobustprofile from "../../assets/images/casestudy/Huddle-heart/features/robust.png";
import huddleheartrobustmatching from "../../assets/images/casestudy/Huddle-heart/features/match.png";
import huddleheartrobustrespectful from "../../assets/images/casestudy/Huddle-heart/features/respect.png";
import huddleheartgeographic from "../../assets/images/casestudy/Huddle-heart/future-prospects/geographic.png";
import huddleheartinnovation from "../../assets/images/casestudy/Huddle-heart/future-prospects/innovation.png";
import huddleheartstrongcommunities from "../../assets/images/casestudy/Huddle-heart/future-prospects/strong-communities.png";
import huddleheartgeographicw from "../../assets/images/casestudy/Huddle-heart/future-prospects/geographic-w.png";
import huddleheartinnovationw from "../../assets/images/casestudy/Huddle-heart/future-prospects/innovation-w.png";
import huddleheartstrongcommunitiesw from "../../assets/images/casestudy/Huddle-heart/future-prospects/strong-community-w.png";
import huddleheartbalanceuser from "../../assets/images/casestudy/Huddle-heart/challenges-solutions/balance-user.png";
import huddleheartusersafety from "../../assets/images/casestudy/Huddle-heart/challenges-solutions/user-safety.png";
import huddleheartconclusion from "../../assets/images/casestudy/Huddle-heart/conclusion/conclusion.png";
import huddlehearticebreakers from "../../assets/images/casestudy/Huddle-heart/enhanced-communication/hearing.png";
import huddleheartvoicemessage from "../../assets/images/casestudy/Huddle-heart/enhanced-communication/voice-message.png";
import huddleheartworkbanner from "../../assets/images/casestudy/Huddle-heart/works/work-banner.png";
import huddleheartacquistion from "../../assets/images/casestudy/Huddle-heart/impacts/acquistion.png";
import huddleheartmeaningful from "../../assets/images/casestudy/Huddle-heart/impacts/meaningful.png";
import plywoodbazarmainlogo from "../../assets/images/casestudy/plywoodbazar/banner-sec/plywoodbazarlogo.png";
import plywoodbazarbannerimg from "../../assets/images/casestudy/plywoodbazar/banner-sec/banner-img.png";
import plywoodbazaraboutusimg from "../../assets/images/casestudy/plywoodbazar/about-us/about-us-img.png";
import plywoodbazaraboutusvs1 from "../../assets/images/casestudy/plywoodbazar/about-us/vs1.png";
import plywoodbazaraboutusvs2 from "../../assets/images/casestudy/plywoodbazar/about-us/vs2.png";
import plywoodbazaraboutusvs3 from "../../assets/images/casestudy/plywoodbazar/about-us/vs3.png";
import plywoodbazarhandshake from "../../assets/images/casestudy/plywoodbazar/client-requirement/handshake.png";

//  landingpage

import LpLogoimg from "../../assets/images/LandingPage/main-logo/logo.png";

import Furnitureimg from "../../assets/images/LandingPage/what-we-offer/furniture.png";
import Groceryshopimg from "../../assets/images/LandingPage/what-we-offer/grocery.png";
import Electronicsshopimg from "../../assets/images/LandingPage/what-we-offer/electronics.png";
import Healthbeautystoreimg from "../../assets/images/LandingPage/what-we-offer/health.png";
import Fashionshopimg from "../../assets/images/LandingPage/what-we-offer/fashion.png";
import Jewelryshopimg from "../../assets/images/LandingPage/what-we-offer/jewellery.png";
import Fasterdevelopmentimg from "../../assets/images/LandingPage/why-choose-us/fasterdevelopment.png";
import Projectsdeliveredimg from "../../assets/images/LandingPage/why-choose-us/projects.png";
import Experienceimg from "../../assets/images/LandingPage/why-choose-us/experiencee.png";
import Enterpriseimg from "../../assets/images/LandingPage/why-choose-us/enterprise.png";
import Seamlessintegrationimg from "../../assets/images/LandingPage/why-choose-us/seamless-integration.png";
import AiandMlimg from "../../assets/images/LandingPage/why-choose-us/Ai.png";
import Minimilisticimg from "../../assets/images/LandingPage/why-choose-us/minimilistic-design.png";
import Developmentprocessimg from "../../assets/images/LandingPage/development-process/development-process.png";
import Artisagalogo from "../../assets/images/LandingPage/clicents-logo/artisaga1.png";
import Fastologo from "../../assets/images/LandingPage/clicents-logo/fasto.png";
import Momporiumlogo from "../../assets/images/LandingPage/clicents-logo/modernmart.png";
import Modernmartlogo from "../../assets/images/LandingPage/clicents-logo/modernmart.png";
import ROcarelogo from "../../assets/images/LandingPage/clicents-logo/rocare_india1.png";
import NikkyPorelogo from "../../assets/images/LandingPage/clicents-logo/nikkypore1.png";

import retailposimpofflinebill from "../../assets/images/RetailPos/importance-retail-pos/offline-bill.png";
import retailposimpscalable from "../../assets/images/RetailPos/importance-retail-pos/scalable-icon.png";
import retailposimpintegration from "../../assets/images/RetailPos/importance-retail-pos/seamlessintegration.png";
import retailposimpanalytics from "../../assets/images/RetailPos/importance-retail-pos/realtimeanalytics.png";
import retailposimployalty from "../../assets/images/RetailPos/importance-retail-pos/customer-loyalty.png";
import retailposimpdecision from "../../assets/images/RetailPos/importance-retail-pos/decision.png";
import retailposimppower from "../../assets/images/RetailPos/importance-retail-pos/thunderbolt.png";
import retailposuserfriendly from "../../assets/images/RetailPos/why-choose-us/login.png";
import retailposrobust from "../../assets/images/RetailPos/why-choose-us/robust.png";
import retailposdedicated from "../../assets/images/RetailPos/why-choose-us/support.png";
import retailposgrowth from "../../assets/images/RetailPos/why-choose-us/growth.png";
import retailposdatasecurity from "../../assets/images/RetailPos/why-choose-us/secure-data.png";
import retailposcompetitivepricing from "../../assets/images/RetailPos/why-choose-us/competitive.png";

// import retailposportfolio2 from "../../assets/images/RetailPos/portfolio/retail-portfolio2.png";
// import whatsappbotbanner from "../../assets/images/Whatsapp-Bot/whatsapp-bot-banner.jpg";
// import whatsappbotaboutlandingmobile from "../../assets/images/Whatsapp-Bot/about-us/landing-mobile.png";
// import whatsappbotaboutlandingdialog from "../../assets/images/Whatsapp-Bot/about-us/landing-dialog.png";
// import whatsappbotwhyyouneedwiden from "../../assets/images/Whatsapp-Bot/why-you-need/wider-reach-1.png";
// import whatsappbotwhyyouneedreadrate from "../../assets/images/Whatsapp-Bot/why-you-need/high-open-read-rate-1.png";
// import whatsappbotwhyyouneedcsat from "../../assets/images/Whatsapp-Bot/why-you-need/increased-customer-satisfaction-1.png";
// import whatsappbotwhyyouneed24by7 from "../../assets/images/Whatsapp-Bot/why-you-need/24x7-availability-2.png";
// import whatsappbotwhyyouneedresponserate from "../../assets/images/Whatsapp-Bot/why-you-need/high-response-rate-1.png";
// import whatsappbotwhyyouneedconnectagents from "../../assets/images/Whatsapp-Bot/why-you-need/connect-to-agents-1.png";
// import whatsappbotwhyyouneedcostreduce from "../../assets/images/Whatsapp-Bot/why-you-need/cost-reduction-1.png";
// import whatsappbotwhyyouneedbrandrecall from "../../assets/images/Whatsapp-Bot/why-you-need/brand-recall-1.png";
// import whatsappbotbenefitspersonworking from "../../assets/images/Whatsapp-Bot/whatsappbot-benefits/person-working.png";
// import whatsappbotbenefitsfaq from "../../assets/images/Whatsapp-Bot/whatsappbot-benefits/faq.png";
// import whatsappbotbenefitsrichtext from "../../assets/images/Whatsapp-Bot/whatsappbot-benefits/rich-text.png";
// import whatsappbotbenefitsdatacollection from "../../assets/images/Whatsapp-Bot/whatsappbot-benefits/data-collection.png";
// import whatsappbotbenefitsleads from "../../assets/images/Whatsapp-Bot/whatsappbot-benefits/qualify-leads.png";
// import whatsappbotbenefitscustomer from "../../assets/images/Whatsapp-Bot/whatsappbot-benefits/customer-retention.png";
// import whatsappbotbottombanner from "../../assets/images/Whatsapp-Bot/bottom-banner.png";
// import whatsappbotchannelswhatsapp from "../../assets/images/Whatsapp-Bot/allchannels/whatsapp.png";
// import whatsappbotchannelsinstagram from "../../assets/images/Whatsapp-Bot/allchannels/instagram.png";
// import whatsappbotchannelswebsite from "../../assets/images/Whatsapp-Bot/allchannels/website.png";
// import whatsappbotchannelsfb from "../../assets/images/Whatsapp-Bot/allchannels/FB-Messanger.png";
// import whatsappbotchannelsiMessage from "../../assets/images/Whatsapp-Bot/allchannels/iMessage.png";
// import whatsappbotchannelsViber from "../../assets/images/Whatsapp-Bot/allchannels/viber.png";
// import whatsappbotchannelsGoogle from "../../assets/images/Whatsapp-Bot/allchannels/Google.png";
// import whatsappbotplaybtn from "../../assets/images/Whatsapp-Bot/play-button.png";
// import stepprocessimg1 from "../../assets/images/Whatsapp-Bot/steps-process/1.png";
// import stepprocessimg2 from "../../assets/images/Whatsapp-Bot/steps-process/2.png";
// import stepprocessimg3 from "../../assets/images/Whatsapp-Bot/steps-process/3.png";
// import stepprocessimg4 from "../../assets/images/Whatsapp-Bot/steps-process/parallax_4.png";
// import stepprocessimg5 from "../../assets/images/Whatsapp-Bot/steps-process/parallax_5.png";
// import stepprocessimg6 from "../../assets/images/Whatsapp-Bot/steps-process/parallax_6.png";
// import retailposefficient from "../../assets/images/RetailPos/features/statistics.png";
// import retailposefficientw from "../../assets/images/RetailPos/features/statisticsw.png";
// import retailposstreamlined from "../../assets/images/RetailPos/features/streamlined-operations.jpg";
// import retailposcustomerexp from "../../assets/images/RetailPos/features/customer-experience.jpg";
// import retailpossalestracking from "../../assets/images/RetailPos/features/sales-tracking.jpg";
// import retailpossecurepayment from "../../assets/images/RetailPos/features/secure-payments.jpg";
// import retailpostimesaving from "../../assets/images/RetailPos/features/save-time-y.png";
// import retailpostimesavingw from "../../assets/images/RetailPos/features/save-time-w.png";
// import retailposcheckout from "../../assets/images/RetailPos/features/checkout-y.png";
// import retailposcheckoutw from "../../assets/images/RetailPos/features/checkout-w.png";
// import retailposrealtime from "../../assets/images/RetailPos/features/real-time-y.png";
// import retailposrealtimew from "../../assets/images/RetailPos/features/real-time-w.png";
// import retailposdetailreporting from "../../assets/images/RetailPos/features/reporting-y.png";
// import retailposdetailreportingw from "../../assets/images/RetailPos/features/reporting-w.png";
// import retailposfraud from "../../assets/images/RetailPos/features/fraud-prevention-y.png";
// import retailposfraudw from "../../assets/images/RetailPos/features/fraud-prevention-w.png";
// import retailposfinancialmt from "../../assets/images/RetailPos/features/fiancial-management-y.png";
// import retailposfinancialmtw from "../../assets/images/RetailPos/features/fiancial-management-w.png";
// import whatsappbotvoiceaiy from "../../assets/images/Whatsapp-Bot/value-added-features/voice-ai-y.png";
// import whatsappbotvoiceaiw from "../../assets/images/Whatsapp-Bot/value-added-features/voice-ai-w.png";
// import whatsappbotemotionaly from "../../assets/images/Whatsapp-Bot/value-added-features/emotional-intelligent-y.png";
// import whatsappbotemotionalw from "../../assets/images/Whatsapp-Bot/value-added-features/emotional-intelligent-w.png";
// import whatsappbotaiagenty from "../../assets/images/Whatsapp-Bot/value-added-features/agent-assist-y.png";
// import whatsappbotaiagentw from "../../assets/images/Whatsapp-Bot/value-added-features/agent-assist-w.png";
// import whatsappbotlanguagey from "../../assets/images/Whatsapp-Bot/value-added-features/language-y.png";
// import whatsappbotlagnuagew from "../../assets/images/Whatsapp-Bot/value-added-features/language-w.png";
// import whatsappbotanalyticsy from "../../assets/images/Whatsapp-Bot/value-added-features/intelligent-analytics-y.png";
// import whatsappbotanalyticsw from "../../assets/images/Whatsapp-Bot/value-added-features/intelligent-analytics-w.png";
// import whatsappbotintegrationy from "../../assets/images/Whatsapp-Bot/value-added-features/integration-y.png";
// import whatsappbotintegrationw from "../../assets/images/Whatsapp-Bot/value-added-features/integration-w.png";
// import whatsappbotvoiceaibig from "../../assets/images/Whatsapp-Bot/value-added-features/voice-ai-big.png";
// import whatsappbotemotionalbig from "../../assets/images/Whatsapp-Bot/value-added-features/emotional-intelligence-big.png";
// import whatsappbotaiagentbig from "../../assets/images/Whatsapp-Bot/value-added-features/ai-agent-assistant-big.png";
// import whatsappbotlanguagebig from "../../assets/images/Whatsapp-Bot/value-added-features/language-big.png";
// import whatsappbotanalyticsbig from "../../assets/images/Whatsapp-Bot/value-added-features/intelligent-analytics-big.png";
// import whatsappbotintegrationbig from "../../assets/images/Whatsapp-Bot/value-added-features/integrations-big.png";
// import whatsappbotchannelswhatsappicon from "../../assets/images/Whatsapp-Bot/allchannels/whatsapp-icon.png";
// import whatsappbotchannelsinstagramicon from "../../assets/images/Whatsapp-Bot/allchannels/instagram-icon.png";
// import whatsappbotchannelswebsiteicon from "../../assets/images/Whatsapp-Bot/allchannels/website-icon.png";
// import whatsappbotchannelsfbicon from "../../assets/images/Whatsapp-Bot/allchannels/fb-icon.png";
// import whatsappbotchannelsiMessageicon from "../../assets/images/Whatsapp-Bot/allchannels/iMessage-icon.png";
// import whatsappbotchannelsVibericon from "../../assets/images/Whatsapp-Bot/allchannels/viber-icon.png";
// import whatsappbotchannelsGoogleicon from "../../assets/images/Whatsapp-Bot/allchannels/google-messages-icon.png";
// import fastobannerimg from "../../assets/images/casestudy/case-study-1/banner/fasto-banner-img.png";
// import Casestudyabout from "../../assets/images/casestudy/case-study-1/intro/about-client.png";
// import fastoorderplacement from "../../assets/images/casestudy/case-study-1/business-model/order-placement.png";
// import fastoorderplacementw from "../../assets/images/casestudy/case-study-1/business-model/order-placement-w.png";
// import fastoproductrange from "../../assets/images/casestudy/case-study-1/business-model/product-range.png";
// import fastoproductrangew from "../../assets/images/casestudy/case-study-1/business-model/product-range-w.png";
// import fastodeliverylogistics from "../../assets/images/casestudy/case-study-1/business-model/logistics.png";
// import fastodeliverylogisticsw from "../../assets/images/casestudy/case-study-1/business-model/logistics-w.png";
// import fastomarketresearch from "../../assets/images/casestudy/case-study-1/market-research/market-research.png";
// import fastocheckicon from "../../assets/images/casestudy/case-study-1/market-research/checkdiscount.png";
// import fastotechnology from "../../assets/images/casestudy/case-study-1/market-research/technology.png";
// import fastoresultsgrowth from "../../assets/images/casestudy/case-study-1/results-growth/results-growth.png";
// import fastofutureprospects from "../../assets/images/casestudy/case-study-1/future-prospects/future-prospects.png";
// import fastochallengessolutions from "../../assets/images/casestudy/case-study-1/challenges-solutions/challenges-solution.png";
// import fastologo from "../../assets/images/casestudy/case-study-1/logo/fasto-logo.png";
// import huddleheartlogo from "../../assets/images/casestudy/Huddle-heart/banner/logo.png";
// import huddleheartbannerimg from "../../assets/images/casestudy/Huddle-heart/banner/huddle-heart-banner-img.png";
// import huddleheartabout from "../../assets/images/casestudy/Huddle-heart/intro/about-img.png";
// import huddleheartresearchimg from "../../assets/images/casestudy/Huddle-heart/market-research/market-research.png";
// import huddleheartorderplacement from "../../assets/images/casestudy/Huddle-heart/business-model/order-placement.png";
// import huddleheartproductrange from "../../assets/images/casestudy/Huddle-heart/business-model/product-range.png";
// import huddleheartdeliverylogistics from "../../assets/images/casestudy/Huddle-heart/business-model/logistics.png";
// import huddleheartchallengessolutions from "../../assets/images/casestudy/Huddle-heart/challenges-solutions/challenges-solutions.png";
// import huddleheartsafteyconcerns from "../../assets/images/casestudy/Huddle-heart/challenges-solutions/safety-blue.png";
// import huddleheartnomeetup from "../../assets/images/casestudy/Huddle-heart/challenges-solutions/nomeet.png";
// import huddleheartmislead from "../../assets/images/casestudy/Huddle-heart/challenges-solutions/mislead.png";
// import huddleheartimpacts from "../../assets/images/casestudy/Huddle-heart/impacts/impacts.png";
// import huddleheartfutureprospects from "../../assets/images/casestudy/Huddle-heart/future-prospects/future-prospects.png";
// import huddleheartunwantedmsg from "../../assets/images/casestudy/Huddle-heart/challenges-solutions/unwanted-msg.png";
// import huddleheartbaddates from "../../assets/images/casestudy/Huddle-heart/challenges-solutions/bad-dates.png";
// import huddlehearthookup from "../../assets/images/casestudy/Huddle-heart/challenges-solutions/hookup-culture.png";
// import huddleheartenhancedcommunication from "../../assets/images/casestudy/Huddle-heart/enhanced-communication/enhanced-communication.png";
// import huddleheartuserprofile1 from "../../assets/images/casestudy/Huddle-heart/profile-banner/profile-1.png";
// import huddleheartuserprofile2 from "../../assets/images/casestudy/Huddle-heart/profile-banner/profile-2.png";
// import huddleheartuserprofile3 from "../../assets/images/casestudy/Huddle-heart/profile-banner/profile-3.png";
// import huddleheartheartshape from "../../assets/images/casestudy/Huddle-heart/about-us/heart-shape.png";
// import huddlehearteclipiseshape from "../../assets/images/casestudy/Huddle-heart/about-us/eclipise.png";
// import huddleheartaboutusimg from "../../assets/images/casestudy/Huddle-heart/about-us/intro.png";
// import huddleheartsimplesecure from "../../assets/images/casestudy/Huddle-heart/features/simple-secure.png";
// import huddleheartswipe from "../../assets/images/casestudy/Huddle-heart/features/swipe.png";
// import huddleheartrobustprofile from "../../assets/images/casestudy/Huddle-heart/features/robust.png";
// import huddleheartrobustmatching from "../../assets/images/casestudy/Huddle-heart/features/match.png";
// import huddleheartrobustrespectful from "../../assets/images/casestudy/Huddle-heart/features/respect.png";
// import huddleheartgeographic from "../../assets/images/casestudy/Huddle-heart/future-prospects/geographic.png";
// import huddleheartinnovation from "../../assets/images/casestudy/Huddle-heart/future-prospects/innovation.png";
// import huddleheartstrongcommunities from "../../assets/images/casestudy/Huddle-heart/future-prospects/strong-communities.png";
// import huddleheartgeographicw from "../../assets/images/casestudy/Huddle-heart/future-prospects/geographic-w.png";
// import huddleheartinnovationw from "../../assets/images/casestudy/Huddle-heart/future-prospects/innovation-w.png";
// import huddleheartstrongcommunitiesw from "../../assets/images/casestudy/Huddle-heart/future-prospects/strong-community-w.png";
// import huddleheartbalanceuser from "../../assets/images/casestudy/Huddle-heart/challenges-solutions/balance-user.png";
// import huddleheartusersafety from "../../assets/images/casestudy/Huddle-heart/challenges-solutions/user-safety.png";
// import huddleheartconclusion from "../../assets/images/casestudy/Huddle-heart/conclusion/conclusion.png";
// import huddlehearticebreakers from "../../assets/images/casestudy/Huddle-heart/enhanced-communication/hearing.png";
// import huddleheartvoicemessage from "../../assets/images/casestudy/Huddle-heart/enhanced-communication/voice-message.png";
// import huddleheartworkbanner from "../../assets/images/casestudy/Huddle-heart/works/work-banner.png";
// import huddleheartacquistion from "../../assets/images/casestudy/Huddle-heart/impacts/acquistion.png";
// import huddleheartmeaningful from "../../assets/images/casestudy/Huddle-heart/impacts/meaningful.png";
// import plywoodbazarmainlogo from "../../assets/images/casestudy/plywoodbazar/banner-sec/plywoodbazarlogo.png";
// import plywoodbazarbannerimg from "../../assets/images/casestudy/plywoodbazar/banner-sec/banner-img.png";
// import plywoodbazaraboutusimg from "../../assets/images/casestudy/plywoodbazar/about-us/about-us-img.png";
// import plywoodbazaraboutusvs1 from "../../assets/images/casestudy/plywoodbazar/about-us/vs1.png";
// import plywoodbazaraboutusvs2 from "../../assets/images/casestudy/plywoodbazar/about-us/vs2.png";
// import plywoodbazaraboutusvs3 from "../../assets/images/casestudy/plywoodbazar/about-us/vs3.png";
// import plywoodbazarhandshake from "../../assets/images/casestudy/plywoodbazar/client-requirement/handshake.png";
import plywoodleftshape from "../../assets/images/casestudy/plywoodbazar/mission-vision/group-left-shape.png";
import plywoodrightshape from "../../assets/images/casestudy/plywoodbazar/mission-vision/group-right-shape.png";
import plywoodsolutionimg from "../../assets/images/casestudy/plywoodbazar/solution-given/solution-image.png";
import plywoodfeatureslogin from "../../assets/images/casestudy/plywoodbazar/features/login.png";
import plywoodbazarfeaturesimg from "../../assets/images/casestudy/plywoodbazar/features/features-mockup.png";
import plywoodbazarresultslide1 from "../../assets/images/casestudy/plywoodbazar/result/result-slide-1.png";
import Rocaremainbannerimg from "../../assets/images/casestudy/RoCareIndia/main-banner/main-banner-img.png";
import Rocarecasestudylogo from "../../assets/images/casestudy/RoCareIndia/main-banner/main-logo.png";
import ROcareIndiaCSimg from "../../assets/images/casestudy/RoCareIndia.png";
import Rocareaboutusimg from "../../assets/images/casestudy/RoCareIndia/aboutus/about-us-img.png";
import Rocareclientreq from "../../assets/images/casestudy/RoCareIndia/client-requirement/client-requirements.png";
import Rocarelocationicon from "../../assets/images/casestudy/RoCareIndia/features/location-icon.png";
import Rocaresc1 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc1.png";
import Rocaresc2 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc2.png";
import Rocaresc3 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc3.png";
import Rocaresc4 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc4.png";
import Rocaresc5 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc5.png";
import Rocaresc6 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc6.png";
import Rocaresc7 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc7.png";
import Rocaresc8 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc8.png";
import Rocaresc9 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc9.png";
import Rocaresc10 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc10.png";
import Rocaresc11 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc11.png";
import Rocaresc12 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc12.png";
import Rocaresc13 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc13.png";
import Rocaresc14 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc14.png";
import Rocaresc15 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc15.png";
import Rocaresc16 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc16.png";
import Rocaresc17 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc17.png";
import Rocaresc18 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc18.png";
import Rocaresc19 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc19.png";
import Rocaresc20 from "../../assets/images/casestudy/RoCareIndia/customer-slider/sc20.png";
import RocareChallenges from "../../assets/images/casestudy/RoCareIndia/Challenges/challenges.png";
import NikkyPoremainbanner from "../../assets/images/casestudy/NikkyPore/banner-sec/main-banner.png";
import NikkyPoremainlogo from "../../assets/images/casestudy/NikkyPore/banner-sec/nikkyporelogo.png";
import NikkyPoreaboutus from "../../assets/images/casestudy/NikkyPore/about-us/nikkyporeaboutus.png";
import NikkyPoregoals from "../../assets/images/casestudy/NikkyPore/goals-objectives/Nikkypore-goals.png";
import NikkyPorechallengesandsolutions from "../../assets/images/casestudy/NikkyPore/challenges-solutions/challenges-solutions.png";
import NikkyPorevdslide1 from "../../assets/images/casestudy/NikkyPore/visual-designs/nikkyporeslide1.png";
import NikkyPorevdslide2 from "../../assets/images/casestudy/NikkyPore/visual-designs/nikkyporeslide2.png";
import NikkyPorevdslide3 from "../../assets/images/casestudy/NikkyPore/visual-designs/nikkyporeslide3.png";
import NikkyPorevdslide4 from "../../assets/images/casestudy/NikkyPore/visual-designs/nikkyporeslide4.png";
import NikkyPorevdslide5 from "../../assets/images/casestudy/NikkyPore/visual-designs/nikkyporeslide5.png";
import NikkyPorevdslide6 from "../../assets/images/casestudy/NikkyPore/visual-designs/nikkyporeslide6.png";
import NikkyPorevdslide7 from "../../assets/images/casestudy/NikkyPore/visual-designs/nikkyporeslide7.png";
import NikkyPorevdslide8 from "../../assets/images/casestudy/NikkyPore/visual-designs/nikkyporeslide8.png";
import NikkyPorevdslide9 from "../../assets/images/casestudy/NikkyPore/visual-designs/nikkyporeslide9.png";
import NikkyPorevdslide10 from "../../assets/images/casestudy/NikkyPore/visual-designs/nikkyporeslide10.png";
import NikkyPorefeatures1 from "../../assets/images/casestudy/NikkyPore/features-sec/featureimg1.png";
import NikkyPorefeatures2 from "../../assets/images/casestudy/NikkyPore/features-sec/featureimg2.png";
import Deyormainbanner from "../../assets/images/casestudy/Deyor/main-banner/Deyor-main-banner.png";
import Deyorlogo from "../../assets/images/casestudy/Deyor/main-banner/logo.png";
import Deyoraboutusimg from "../../assets/images/casestudy/Deyor/about-us/about-us.png";
import Deyorobjectiveimg from "../../assets/images/casestudy/Deyor/our-objective/objective.png";
import Deyoraboutlogo from "../../assets/images/casestudy/Deyor/about-us/deyor-about-logo.png";
import Deyoraboutrytarrow from "../../assets/images/casestudy/Deyor/about-us/Next-arrow-black.png";
import Deyorchallengesimg from "../../assets/images/casestudy/Deyor/challenges/challenges.png";
import Deyorsolutionsimg from "../../assets/images/casestudy/Deyor/solutions/solutions.png";
import Deyorfeatureimg1 from "../../assets/images/casestudy/Deyor/features/deyorfeature1.png";
import Deyorfeatureimg2 from "../../assets/images/casestudy/Deyor/features/deyorfeature2.png";
import Deyorfeatureimg3 from "../../assets/images/casestudy/Deyor/features/deyorfeature3.png";
import Deyorfeatureimg4 from "../../assets/images/casestudy/Deyor/features/deyorfeature4.png";
import Deyorfeatureline1 from "../../assets/images/casestudy/Deyor/features/1_lines.png";
import Deyorfeatureline2 from "../../assets/images/casestudy/Deyor/features/2_lines.png";
import Deyorfeatureline3 from "../../assets/images/casestudy/Deyor/features/3_lines.png";
import Deyorworkscreen1 from "../../assets/images/casestudy/Deyor/work-screens/screen1.png";
import Deyorworkscreen2 from "../../assets/images/casestudy/Deyor/work-screens/screen2.png";
import Deyorworkscreen3 from "../../assets/images/casestudy/Deyor/work-screens/screen3.png";
import Deyorworkscreen4 from "../../assets/images/casestudy/Deyor/work-screens/screen4.png";
import Deyorworkscreen5 from "../../assets/images/casestudy/Deyor/work-screens/screen5.png";
import Deyorworkscreen6 from "../../assets/images/casestudy/Deyor/work-screens/screen6.png";
import Deyorworkscreen7 from "../../assets/images/casestudy/Deyor/work-screens/screen7.png";
import Deyorworkscreen8 from "../../assets/images/casestudy/Deyor/work-screens/screen8.png";
import Samagri from "../../assets/images/casestudy/Samagri.png";
import Samagribannerimg from "../../assets/images/casestudy/Samagri/main-banner/samagri-banner.png";
import Samagrihalfelipse from "../../assets/images/casestudy/Samagri/main-banner/halfElipse.png";
import Samagriaboutuslogo from "../../assets/images/casestudy/Samagri/aboutus/samagri-logo.png";
import Samagriaboutusimg from "../../assets/images/casestudy/Samagri/aboutus/samagri-aboutus.png";
import Samagriaboutussm1 from "../../assets/images/casestudy/Samagri/aboutus/small-img1.png";
import Samagriaboutussm2 from "../../assets/images/casestudy/Samagri/aboutus/small-img2.png";
import Samagriaboutussm3 from "../../assets/images/casestudy/Samagri/aboutus/small-img3.png";
import Samagriaboutussm4 from "../../assets/images/casestudy/Samagri/aboutus/small-img4.png";
import Samagriprojecthighlights from "../../assets/images/casestudy/Samagri/project-highlights/samagri-project-highlight.png";
import Samagriphighlightcircle from "../../assets/images/casestudy/Samagri/project-highlights/white-circle.png";
import Samagrihighlight1 from "../../assets/images/casestudy/Samagri/project-highlights/highlight1.png";
import Samagrihighlight2 from "../../assets/images/casestudy/Samagri/project-highlights/highlight2.png";
import Samagrihighlight3 from "../../assets/images/casestudy/Samagri/project-highlights/highlight3.png";
import Samagrihighlight4 from "../../assets/images/casestudy/Samagri/project-highlights/highlight4.png";
import Samagriworkscreens from "../../assets/images/casestudy/Samagri/our-screens/work-screens.png";
import Samagrifeatures3step from "../../assets/images/casestudy/Samagri/features/3-step-order.png";
import Samagrifeatureseasyreplacement from "../../assets/images/casestudy/Samagri/features/replacement.png";
import Samagrifeaturessecure from "../../assets/images/casestudy/Samagri/features/secure.png";
import Samagrifeaturescustomerservice from "../../assets/images/casestudy/Samagri/features/customer-service-agent.png";
import Samagrifeaturesoffers from "../../assets/images/casestudy/Samagri/features/discount.png";
import Samagrifeaturesreasonableprice from "../../assets/images/casestudy/Samagri/features/best-price.png";
import Samagrifeaturesimg from "../../assets/images/casestudy/Samagri/features/features-img.png";
import Samagrichallenges from "../../assets/images/casestudy/Samagri/challenges/challenges-samagri.png";
import Turningpointmainbanner from "../../assets/images/casestudy/Turningpoint/main-banner/turningpoint-main-banner.png";
import Turningpointlogo from "../../assets/images/casestudy/Turningpoint/main-banner/main-logo.png";
import Turningpointbannerimg from "../../assets/images/casestudy/Turningpoint/main-banner/turning-point-img.png";
import Turningpointintroimg from "../../assets/images/casestudy/Turningpoint/intro/turningpoint-intro.png";
import Turningpointfeaturesimg from "../../assets/images/casestudy/Turningpoint/features/features-img.png";
import Turningpointchallengessolutionsimg from "../../assets/images/casestudy/Turningpoint/challenges-solutions/turning-point-challenges-solutions.png";
import Turningpointportfolioslide1 from "../../assets/images/casestudy/Turningpoint/portfolio-sec/tp-slide-1.png";
import Turningpointportfolioslide2 from "../../assets/images/casestudy/Turningpoint/portfolio-sec/tp-slide-2.png";
import Turningpointportfolioslide3 from "../../assets/images/casestudy/Turningpoint/portfolio-sec/tp-slide-3.png";
import Turningpointportfolioslide4 from "../../assets/images/casestudy/Turningpoint/portfolio-sec/tp-slide-4.png";
import Turningpointportfolioslide5 from "../../assets/images/casestudy/Turningpoint/portfolio-sec/tp-slide-5.png";
import Turningpointportfolioslide6 from "../../assets/images/casestudy/Turningpoint/portfolio-sec/tp-slide-6.png";
import Turningpointportfolioslide7 from "../../assets/images/casestudy/Turningpoint/portfolio-sec/tp-slide-7.png";
import Turningpointportfolioslide8 from "../../assets/images/casestudy/Turningpoint/portfolio-sec/tp-slide-8.png";
import Turningpointportfolioslide9 from "../../assets/images/casestudy/Turningpoint/portfolio-sec/tp-slide-9.png";
import Turningpointportfolioslide10 from "../../assets/images/casestudy/Turningpoint/portfolio-sec/tp-slide-10.png";
import Turningpointportfolioslide11 from "../../assets/images/casestudy/Turningpoint/portfolio-sec/tp-slide-11.png";
import Turningpointportfolioslide12 from "../../assets/images/casestudy/Turningpoint/portfolio-sec/tp-slide-12.png";
import Turningpointportfolioslide13 from "../../assets/images/casestudy/Turningpoint/portfolio-sec/tp-slide-13.png";
import Turningpointportfolioslide14 from "../../assets/images/casestudy/Turningpoint/portfolio-sec/tp-slide-14.png";
import Turningpointportfolioslide15 from "../../assets/images/casestudy/Turningpoint/portfolio-sec/tp-slide-15.png";

import DigitalMarketingaboutus from "../../assets/images/DigitalMarketing/Aboutus/digital-marketing-aboutus.png";
import Digitalmktwhychoosebrain from "../../assets/images/DigitalMarketing/why-choose-us/brain.png";
import Digitalmktwhychooseteam from "../../assets/images/DigitalMarketing/why-choose-us/team.png";
import Digitalmktwhychoosetrackrecord from "../../assets/images/DigitalMarketing/why-choose-us/track-record.png";
import Digitalmktwhychoosestrategies from "../../assets/images/DigitalMarketing/why-choose-us/strategies.png";
import Digitalmktwhychoosedatadriven from "../../assets/images/DigitalMarketing/why-choose-us/data-driven.png";
import Digitalmktwhychoosetransparentreport from "../../assets/images/DigitalMarketing/why-choose-us/transparent-repor.png";
import Digitalmktwhychoosecustomercentric from "../../assets/images/DigitalMarketing/why-choose-us/customer-centric.png";
import Digitalmktwhychoosecomprehensive from "../../assets/images/DigitalMarketing/why-choose-us/comprehensive.png";
import Digitalmktservicesvideoicon from "../../assets/images/DigitalMarketing/our-services/video-btn.png";
import Digitalmktservicesimg from "../../assets/images/DigitalMarketing/our-services/services-img.png";
import Digitalmktbenefitsimg from "../../assets/images/DigitalMarketing/benefits/benefits-img.png";
import Digitalmktanalytics from "../../assets/images/DigitalMarketing/benefits/benefits-img.png";
import Digitalmktgoogleanalytics from "../../assets/images/DigitalMarketing/tools/google-analytics.png";
import Digitalmktsearchconsole from "../../assets/images/DigitalMarketing/tools/google-search.png";
import Digitalmkttagmanager from "../../assets/images/DigitalMarketing/tools/google-tag-manager.png";
import Digitalmktdatastudio from "../../assets/images/DigitalMarketing/tools/data-studio.png";
import Digitalmktgoogleads from "../../assets/images/DigitalMarketing/tools/gogle-ads.png";
import Digitalmktgooglebusiness from "../../assets/images/DigitalMarketing/tools/google-my-bus.png";
import Digitalmktcasestudyimg from "../../assets/images/DigitalMarketing/casestudy/case-study-ranking-graph.png";
import Digitalmktwhiteseo from "../../assets/images/DigitalMarketing/digital-mkt-services/white-seo-new.png";
import Digitalmktdmsnew from "../../assets/images/DigitalMarketing/digital-mkt-services/DMS-new.png";
import Digitalmktleadgeneration from "../../assets/images/DigitalMarketing/digital-mkt-services/lead-generation-new.png";
import Digitalmktwebdesign from "../../assets/images/DigitalMarketing/digital-mkt-services/web-design-dev-new.png";
import Digitalmktonlinereputation from "../../assets/images/DigitalMarketing/digital-mkt-services/handshake.png";
import Digitalmktconversion from "../../assets/images/DigitalMarketing/digital-mkt-services/conversion-new.png";
import Digitalmktboostup from "../../assets/images/DigitalMarketing/boost-up-business/boost.png";
import Digitalmktsocialmedia from "../../assets/images/DigitalMarketing/social-media/social-media-management.png";
import Digitalmktsocialmediainsta from "../../assets/images/DigitalMarketing/social-media/instagram.png";
import Digitalmktsocialmediafb from "../../assets/images/DigitalMarketing/social-media/fb.png";
import Digitalmktsocialmediatw from "../../assets/images/DigitalMarketing/social-media/twitter.png";
import Digitalmktsocialmedialinkedin from "../../assets/images/DigitalMarketing/social-media/linkedin.png";
import Digitalmktsocialmediawhatsapp from "../../assets/images/DigitalMarketing/social-media/whatsapp.webp";

// fintech changed image
import alliance1 from "../../assets/images/alliance1.png";
import capability1 from "../../assets/images/capability1.png";
import functional1 from "../../assets/images/functional1.png";
import collaboration1 from "../../assets/images/collaboration1.png";
import accesscontrol1 from "../../assets/images/accesscontrol1.png";
import certified1 from "../../assets/images/certified1.png";
import ecosystem1 from "../../assets/images/ecosystem1.png";
import chooseicon1 from "../../assets/images/chooseicon1.png";
import Brochure from "../../assets/images/brochure.png";
import errorimg from "../../assets/images/dribbble_1.gif";
import webportfoliyoimg17 from "../../assets/images/portfolio/artiaga.png";
import webportfoliyoimg18 from "../../assets/images/portfolio/aahilya.png";
import webportfoliyoimg19 from "../../assets/images/portfolio/ceressy.png";

// /case-study/
import techused1 from "../../assets/images/casestudy/techused1.png";
import plywoodbazar from "../../assets/images/casestudy/plywoodbazar.png";
import Fasto from "../../assets/images/casestudy/Fasto.png";
import NikkyPore from "../../assets/images/casestudy/Nikkyporefilters.png";
import huddleheart from "../../assets/images/casestudy/huddle-heart.png";
import turningpoint from "../../assets/images/casestudy/turningpoint.png";
import DeyorCasestudy from "../../assets/images/casestudy/Deyor.png";
import techused2 from "../../assets/images/casestudy/techused2.png";

export const images = {
  // case-study

  techused1,
  plywoodbazar,
  Fasto,
  huddleheart,
  techused2,
  turningpoint,
  DeyorCasestudy,

  webportfoliyoimg19,
  webportfoliyoimg18,
  Brochure,
  errorimg,
  whatappiconftr,
  workimgmockup,
  webportfoliyoimg17,
  webportfoliyoimg16,
  webportfoliyoimg15,
  webportfoliyoimg14,
  webportfoliyoimg13,
  webportfoliyoimg12,
  webportfoliyoimg11,
  webportfoliyoimg10,
  webportfoliyoimg9,
  webportfoliyoimg8,
  webportfoliyoimg7,
  webportfoliyoimg6,
  webportfoliyoimg5,
  webportfoliyoimg4,
  webportfoliyoimg3,
  webportfoliyoimg2,
  webportfoliyoimg1,

  hospitaly,
  jobportal,
  appicon,
  appicon1,
  maskgroup,
  shapeverticaldots,
  fntcimg,
  fntcimg20,
  premiumimg,
  fintechfeatures,
  lapimg,
  mockup_img,
  avtarimg,
  apppngimages,
  handupapp,
  mobilebannerabout,
  // mobilebannerhome,
  mobilemocup1,
  mobilemocup2,
  mobilemocup3,
  mobilemocup4,
  topmockupimg1,
  centermockupimg1,
  bottommockupimg1,
  topmockupimg2,
  centermockupimg2,
  bottommockupimg2,
  topmockupimg3,
  centermockupimg3,
  bottommockupimg3,
  topmockupimg4,
  centermockupimg4,
  bottommockupimg4,
  // bottommockupimg4,
  // bottommockupimg4,
  topmockupimg,
  centermockupimg,
  bottommockupimg,
  contactimgfile,
  mobilemocup,
  //  mukul  images add
  // travelappbanner,

  fileicon,
  magnifiericon,
  measurements,
  scheduleicon,
  filter,
  monitor,
  remainder,
  event,
  alarm,
  thumbsup,
  howitworks,
  travelbanner,
  travelportfolio,
  ondemandbg,
  ondemanduser,
  ondemandjobseeker,
  ondemandrecruiter,
  ondemandadminbig,
  ondemandjobseekerbig,
  ondemandjobrecruiterbig,
  commonfeatures,
  advancedfeatures,
  featuresprofile,
  featuresmulti,
  featuresresume,
  featuresfilter,
  featuresnotifications,
  featuresinappcall,
  featuresinappchats,
  featuresprofilecretion,
  featuresvideocall,
  featurescloud,
  featuresadtracker,
  featuresdiscount,
  featureswebinars,
  featurestemplates,
  featuresapp,
  featuresupload,
  recentwork1,
  recentwork2,
  recentwork3,
  certificate1,
  enghiringmodel1,
  onsite,
  offsite,
  hybrid,
  fixedprice,
  dedicatedhiring,
  hourlybasis,
  discussSpecialistsbg,
  commonfeaturesb,
  advancedfeaturesw,
  jobportalbanner,
  jobportalaboutus,
  jobservicesuiux,
  jobservicesuiuxwhite,
  jobappdevelopment,
  jobappdevelopmentwhite,
  jobwebdevelopment,
  jobwebdevelopmentwhite,
  jobsoftware,
  jobsoftwarewhite,
  jobdatamigration,
  jobdatamigrationwhite,
  jobcandidatefeatures,
  jobcandidatefeatureswhite,
  travelappbanner,
  //  travelbanner,
  mockupimg1,
  mockupimg2,
  mockupimg3,
  mockupimg4,

  //  jobservicesuiux,

  // bhawna images add--------------
  edimg1,
  edtechaboutus,
  login,
  searchad,
  measurement,
  schedule,
  track,
  tracking,
  payment,
  history,
  notification,
  streaming,
  book,
  certificate,
  screen1,
  screen2,
  screen3,
  screen4,
  screen5,
  screen6,
  screen7,
  screen8,
  phone1,

  banner1,

  head_img1,

  head_img2,

  feature1,

  feature2,

  feature3,

  feature4,

  feature5,

  feature6,

  feature7,

  feature8,

  feature9,

  feature10,

  feature11,

  feature12,

  dashboard,

  product,

  user,

  employee,

  vendor,

  rewards,

  help,

  membership,

  upload,

  catalog,

  order,

  delivery,

  report,

  module,

  payments,

  dot,

  escreen1,

  escreen2,

  escreen3,

  escreen4,

  escreen5,

  escreen6,

  escreen7,

  escreen8,

  escreen9,

  escreen10,

  app1,

  app2,

  app3,

  app4,

  app5,

  app6,

  engage1,

  engage2,

  engage3,

  engage4,

  check,

  img1,

  img2,

  grobanner,

  mobileapps1,

  mobileapps2,

  grocery_mart,

  apps,

  onboarding,

  browse,

  quick,

  easyoption,

  trackings,

  schedules,

  reorder,

  pushnot,

  offerzone,

  manage,

  rating,

  dashboards,

  stores,

  managers,

  listing,

  assiorder,

  orders,

  managecustm,

  managepay,

  manageoff,

  managefeed,

  managenoti,

  reporting,

  locator,

  pickup,

  ordering,

  integration,

  support,

  inventory,

  change,

  business1,

  business2,

  business3,

  business4,

  busicon1,

  busicon2,

  busicon3,

  busicon4,

  gro_screen1,

  gro_screen2,

  gro_screen3,

  gro_screen4,

  gro_screen5,

  gro_screen6,

  gro_screen7,

  gro_screen8,

  gro_screen9,

  gro_screen10,

  friendly,

  customize,

  features_int,

  gateways,

  realtime,

  analytics,

  multistores,

  alerts,

  scale,

  // bhawana  image var top

  // menuimg,
  arrwoblue,
  blogimg7,
  crm1,
  website,
  digitalmarketing,
  mistionfirs,
  aboutbanner,
  missionimg,
  mission1,
  mission2,
  moreimg,
  // team13,
  team10,
  // team11,
  team12,
  officeimg,
  team4,
  team6,
  team7,
  team8,
  team9,
  team2,
  alwin,
  icon2017,
  icon2019,
  icon2020,
  icon2021,
  icon2022,
  Responsibilityicon,
  Inclusionicon,
  Sustainabilityicon,
  Agilityicon,
  Qualityicon,
  Collaborationicon,
  Integrityicon,
  customericon,
  idea,
  learningimg,
  clicentlogo56,
  clicentlogo55,
  clicentlogo54,
  clicentlogo52,
  clicentlogo51,
  clicentlogo49,
  clicentlogo50,
  clicentlogo57,
  clicentlogo58,
  clicentlogo59,
  clicentlogo60,
  clicentlogo61,
  clicentlogo62,
  clicentlogo63,
  clicentlogo64,
  clicentlogo65,
  clicentlogo66,

  solutionsimg12,
  laptopimg1,
  laptopimg2,
  laptopimg3,
  mobilsapp,
  mobilsapp1,
  mobilsapp2,
  mobilsapp3,
  mobilsapp4,
  // banner,
  clicentlogo1,
  clicentlogo2,
  clicentlogo3,
  clicentlogo4,
  clicentlogo5,
  clicentlogo6,
  clicentlogo7,
  clicentlogo9,
  clicentlogo10,
  clicentlogo11,
  clicentlogo12,
  clicentlogo13,
  clicentlogo14,
  clicentlogo15,
  clicentlogo16,
  clicentlogo17,
  clicentlogo18,
  clicentlogo19,
  clicentlogo20,
  clicentlogo21,
  clicentlogo22,
  clicentlogo23,
  clicentlogo24,
  clicentlogo25,
  clicentlogo27,
  clicentlogo28,
  clicentlogo29,
  clicentlogo30,
  clicentlogo31,
  clicentlogo32,
  clicentlogo33,
  clicentlogo34,
  clicentlogo35,
  clicentlogo36,
  clicentlogo37,
  clicentlogo38,
  clicentlogo39,
  clicentlogo40,
  clicentlogo41,
  clicentlogo42,
  clicentlogo43,
  clicentlogo44,
  clicentlogo45,
  clicentlogo46,
  clicentlogo47,
  clicentlogo48,
  solutionsimg4,
  solutionsimg5,
  solutionsimg6,
  solutionsimg7,
  solutionsimg8,
  solutionsimg9,
  solutionsimg10,
  solutionsimg11,
  solutionsimg3,
  solutionsimgsociety,
  solutionsimgwhatsappbot,
  solutionsimgdigitalmkt,
  solutionsimgschoolmanagement,
  solutionsimgappdevelopment,
  solutionsimg1,
  web1,
  xml,
  wordpress_icon,
  woocomercelogo,
  shopify,
  semanticui,
  open,
  material,
  magento,
  json,
  js,
  jquery,
  joomla,
  google_api,
  codeigniter,
  backbonejs,
  amp,
  ajax,
  ai,
  boot_icon,
  logo8,
  webportal,
  desktopappdevelopemnt,
  graphice,
  digital_marketing,
  itconsting,
  crmsoftware,
  contaiwriting,
  ecommerdevelopment,
  elearning,
  webdesing,
  webdevelopment1,
  mobileapp,
  blogimg6,
  blogimg5,
  blogimg4,
  blogimg3,
  blogimg2,
  blogimg1,
  blogimg,
  amazonappimg1,
  amazonappimg,
  appstorpngicon1,
  appstorpngicon2,
  logowebimg,
  webpngimg,
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  usericon,
  techimgicon14,
  techimgicon13,
  techimgicon12,
  techimgicon11,

  techimgicon10,
  techimgicon9,
  techimgicon8,
  techimgicon7,
  techimgicon1,
  techimgicon2,
  techimgicon3,
  techimgicon4,
  techimgicon5,
  techimgicon6,
  logo,
  android,
  mission,
  work_1,
  crm,
  map,
  case_study,
  vision,
  phone,
  client_1,
  crm2,
  contact,
  timetable,
  team,
  value,
  email,
  offer_1,
  work_2,
  about,
  offer_2,
  healthcare,
  banner_1,
  offer_3,
  work_3,
  banner_2,
  offer_4,
  angular,
  logo_2,
  work_4,
  result,
  banking,
  testimonial_1,
  Manufacturing,
  css,
  benefit_1,
  Informationtechnologies,
  benefit_2,
  client_2,
  blog,
  Retail,
  experessjs,
  benefit_3,
  Insurance,
  testimonial_2,
  benefit_4,
  professionalservices,
  html,
  Marketing,
  client_3,
  ios,
  testimonial_3,
  mongo,
  client_4,
  mysql,
  client_5,
  nodejs,
  client_8,
  client_6,
  php,
  client_7,
  postgresql,
  app,
  python,
  web,
  reactjs,
  graphic,
  reactnavtive,
  digital,
  vuejs,
  // how_it_work,
  creandoScreenShot,
  healthcarebanner,
  healthcaremonitor,
  healthcaremigration,
  healthcarecheckicon,
  healthchooseus1,
  healthcarehexagon,
  healthcareiconcheck,
  // erpbanner,
  erpcta,
  erpsupplychain,
  erpsmanufacturing,
  erpcustomerrm,
  erpinventory,
  erpbusinessintell,
  erpmarketing,
  erpfinancial,
  erpanalytics,
  erphumanresource,
  erppurchasing,
  healthchooseus2,
  healthchooseus3,
  healthchooseus4,
  healthchooseus5,
  healthchooseus6,
  healthcareabout,
  erpconsult,
  erpsolutions,
  erpoperation,
  erpappdevelop,
  erpservices,
  erpstaff,
  erpinventorymanage,
  erpcheck,
  erpprojectmanagement,
  erpdocmanagement,
  erpchatbot,
  //  erpsoftwaredevelopment,
  erpaboutimg,
  erpsalespurchase,
  erpproduction,
  erptailored,
  erpflexible,
  erpimplement,
  erpcustomsoftwaresolutions,
  // crmmainbanner,
  crmaboutimg,
  crmcustomerservices,
  //  crmwhyworkus,
  crmindustimg,
  crmuserfriendly,
  crmrobustdata,
  crmworkflow,
  crmcustomized,
  crmcommunicationtools,
  crmsecurity,
  crmexperience,
  crmefficiency,
  crmscalability,
  crmdecision,
  crmdatasecurity,
  crmbusinessprocess,
  crmdevelopment,
  crmdevelopmentw,
  crmintegration,
  crmintegrationw,
  crmmigration,
  crmmigrationw,
  crmsupport,
  crmsupportw,
  crmhealthcare,
  crmretial,
  crmrealestate,
  crmeducation,
  crmfinance,
  crmmanufacturing,
  crmbenefitsfinance,
  crmbenefitshospitality,
  crmbenefitsfashion,
  crmbenefitseducation,
  crmbenefitsfinance1,
  crmbenefitsgovernment,
  SchoolManagementbanner,
  SchoolManagementaboutimg,
  Schmtcustomdevelopment,
  Schmtcustomized,
  Schmtintegration,
  Schmtmodernization,
  Schmtaccounting,
  Schmtfinance,
  Schmtinventory,
  Schmtlibrary,
  Schmttransportation,
  Schmttimetable,
  Schmtattendance,
  Schmtperformance,
  Schmtadmission,
  Schmtpolicy,
  Schmtinquiry,
  Schmtstudent,
  Schmtteacherapp,
  Schmtstudentapp,
  Schmtparentsapp,
  Schmtadminapp,
  Schmtprocessimg,
  Schmtgathering,
  Schmtgatheringh,
  Schprocessdots,
  Schtestingassurance,
  Schdevelopmentcoding,
  Schdeployment,
  Schgatheringanalysis,
  Schprototyping,
  Schcontinousimprovement,
  Schtraining,
  Schmtadvantages,
  Schmtstudentappvideo,
  Schmtportfolio1,
  Schmtportfolio2,
  Schmtportfolio3,
  Schmtportfolio4,
  Schmtportfolio5,
  Schmtportfolio6,
  Schmtportfolio7,
  Schmtportfolio8,
  Societymtbanner,
  Societyuserverified,
  Societysecurity,
  Societybooking,
  Societynoticeboard,
  Societybills,
  Societyannouncements,
  Societyparking,
  Societyappdevelopment,
  Societyintegration,
  Societycustomize,
  Societyconsultancy,
  Societywhychooseus,
  Societyexpertise,
  Societyuserfriendly,
  Societyportfolio,
  Societyportfolio1,
  Societyportfolio2,
  Societyportfolio3,
  Societyportfolio4,
  Societyportfolio5,
  Societyportfolio6,
  Societyportfolio7,
  Societyportfolio8,
  Societyportfolio9,
  realestatebanner,
  realestateaboutus,
  realestservicesmobileapp,
  realestserviceswebapp,
  realestservicesrentingapp,
  realestservicesproperty,
  realestserviceslead,
  realestservicesmaintenance,
  realestfeatureslogin,
  realestfeaturesagentprofile,
  realestfeatureschat,
  realestfeaturespropertylisting,
  realestfeaturesfavlisting,
  realestfeaturesmainimg,
  crmportfolioebslon,
  crmportfoliofliptrip,
  crmportfoliorocare,
  healthcareindustryspecificmain,
  realestmngmnticon1,
  realestmngmntcosteffective,
  realestmngmnttimelydelivery,
  realestmngmntreadyproject,
  realestsignup,
  realestsignupy,
  realestsearchresult,
  realestsearchresulty,
  realestlistproperty,
  realestlistpropertyy,
  realestsearchfilter,
  realestsearchfiltery,
  realestbuysellrent,
  realestbuysellrenty,
  realestsettings,
  realestsettingsy,
  realestchat,
  realestchaty,
  realestmap,
  realestmapy,
  realestdashboard,
  realestdashboardy,
  realestexclusivedeals,
  realestexclusivedealsy,
  realestpropertydetails,
  realestpropertydetailsy,
  healthcareportfolio1,
  healthcareportfolio2,
  healthcareportfolio3,
  erpportfolio1,
  erpportfolio2,
  erpportfolio3,
  realestportfolio1,
  realestportfolio2,
  manufacturingbanner,
  manufacturingaboutus,
  manufacturingsolution1,
  mftsolutionplanning,
  mftsolutionwork,
  mftsolutionbi,
  mftsolutioninventory,
  mftsolutionsales,
  mftsolutionexintegration,
  mftservicesplanning,
  mftservicesorder,
  mftservicessmartprod,
  mftservicescustomerbehavior,
  mftservicesmaintenance,
  mftservicesintegration,
  mftourworks,
  retailposbanner,
  retailposaboutus,
  retailposstreamlined,
  retailposcustomerexp,
  retailpossalestracking,
  retailpossecurepayment,
  retailposexponentialtech,
  retailpossuperiorarcht,
  retailposdesignthink,
  retailposimpofflinebill,
  retailposimpscalable,
  retailposimpintegration,
  retailposimpanalytics,
  retailposimpretailexp,
  retailposimpsecure,
  retailposimployalty,
  retailposimpdecision,
  retailposimppower,
  retailposportfolio1,
  retailposportfolio2,
  retailposuserfriendly,
  retailposrobust,
  retailposdedicated,
  retailposgrowth,
  retailposdatasecurity,
  retailposcompetitivepricing,
  whatsappbotbanner,
  whatsappbotaboutlandingmobile,
  whatsappbotaboutlandingdialog,
  whatsappbotwhyyouneedwiden,
  whatsappbotwhyyouneedreadrate,
  whatsappbotwhyyouneedcsat,
  whatsappbotwhyyouneed24by7,
  whatsappbotwhyyouneedresponserate,
  whatsappbotwhyyouneedconnectagents,
  whatsappbotwhyyouneedcostreduce,
  whatsappbotwhyyouneedbrandrecall,
  whatsappbotbenefitspersonworking,
  whatsappbotbenefitsfaq,
  whatsappbotbenefitsrichtext,
  whatsappbotbenefitsdatacollection,
  whatsappbotbenefitsleads,
  whatsappbotbenefitscustomer,
  retailposcustomize,
  whatsappbotbottombanner,
  manufacturingsolutioninventory,
  manufacturingsolutionanalytics,
  whatsappbotchannelswhatsapp,
  whatsappbotchannelswhatsappicon,
  whatsappbotchannelsinstagram,
  whatsappbotchannelsinstagramicon,
  whatsappbotchannelswebsite,
  whatsappbotchannelswebsiteicon,
  whatsappbotchannelsfb,
  whatsappbotchannelsfbicon,
  whatsappbotchannelsiMessage,
  whatsappbotchannelsiMessageicon,
  whatsappbotchannelsViber,
  whatsappbotchannelsVibericon,
  whatsappbotchannelsGoogle,
  whatsappbotchannelsGoogleicon,
  Appporftfoliyoimg,
  Appporftfoliyoimg1,
  Appporftfoliyoimg2,
  Appporftfoliyoimg3,
  Appporftfoliyoimg4,
  Appporftfoliyoimg5,
  Appporftfoliyoimg6,
  Appporftfoliyoimg7,
  Appporftfoliyoimg8,
  Appporftfoliyoimg9,
  whatsappbotplaybtn,
  stepprocessimg1,
  stepprocessimg2,
  stepprocessimg3,
  stepprocessimg4,
  stepprocessimg5,
  stepprocessimg6,
  retailposefficient,
  retailposefficientw,
  retailpostimesaving,
  retailpostimesavingw,
  retailposcheckout,
  retailposcheckoutw,
  retailposrealtime,
  retailposrealtimew,
  retailposdetailreporting,
  retailposdetailreportingw,
  retailposfraud,
  retailposfraudw,
  retailposfinancialmt,
  retailposfinancialmtw,
  whatsappbotvoiceaiy,
  whatsappbotvoiceaiw,
  whatsappbotemotionaly,
  whatsappbotemotionalw,
  whatsappbotaiagenty,
  whatsappbotaiagentw,
  whatsappbotlanguagey,
  whatsappbotlagnuagew,
  whatsappbotanalyticsy,
  whatsappbotanalyticsw,
  whatsappbotintegrationy,
  whatsappbotintegrationw,
  whatsappbotvoiceaibig,
  whatsappbotemotionalbig,
  whatsappbotaiagentbig,
  whatsappbotlanguagebig,
  whatsappbotanalyticsbig,
  whatsappbotintegrationbig,
  fastobannerimg,
  fastoorderplacement,
  fastoorderplacementw,
  fastoproductrange,
  fastoproductrangew,
  fastodeliverylogistics,
  fastodeliverylogisticsw,
  huddleheartorderplacement,
  huddleheartproductrange,
  huddleheartdeliverylogistics,
  fastomarketresearch,
  fastocheckicon,
  fastotechnology,
  fastoresultsgrowth,
  fastofutureprospects,
  fastochallengessolutions,
  Casestudyabout,
  fastologo,
  huddleheartlogo,
  huddleheartbannerimg,
  huddleheartabout,
  huddleheartresearchimg,
  huddleheartchallengessolutions,
  huddleheartsafteyconcerns,
  huddleheartnomeetup,
  huddleheartmislead,
  huddleheartimpacts,
  huddleheartfutureprospects,
  huddleheartunwantedmsg,
  huddleheartbaddates,
  huddlehearthookup,
  huddleheartenhancedcommunication,
  huddleheartuserprofile1,
  huddleheartuserprofile2,
  huddleheartuserprofile3,
  huddleheartheartshape,
  huddlehearteclipiseshape,
  huddleheartaboutusimg,
  huddleheartsimplesecure,
  huddleheartswipe,
  huddleheartrobustprofile,
  huddleheartrobustmatching,
  huddleheartrobustrespectful,
  huddleheartgeographic,
  huddleheartinnovation,
  huddleheartstrongcommunities,
  huddleheartgeographicw,
  huddleheartinnovationw,
  huddleheartstrongcommunitiesw,
  huddleheartbalanceuser,
  huddleheartusersafety,
  huddleheartconclusion,
  huddlehearticebreakers,
  huddleheartvoicemessage,
  huddleheartworkbanner,
  huddleheartacquistion,
  huddleheartmeaningful,
  plywoodbazarmainlogo,
  plywoodbazarbannerimg,
  plywoodbazaraboutusimg,
  plywoodbazaraboutusvs1,
  plywoodbazaraboutusvs2,
  plywoodbazaraboutusvs3,
  plywoodbazarhandshake,
  LpLogoimg,
  Furnitureimg,
  Groceryshopimg,
  Electronicsshopimg,
  Healthbeautystoreimg,
  Fashionshopimg,
  Jewelryshopimg,
  Fasterdevelopmentimg,
  Projectsdeliveredimg,
  Experienceimg,
  Enterpriseimg,
  Seamlessintegrationimg,
  AiandMlimg,
  Minimilisticimg,
  Developmentprocessimg,
  Artisagalogo,
  Fastologo,
  Momporiumlogo,
  Modernmartlogo,
  ROcarelogo,
  NikkyPorelogo,

  // whatsappbotemotionalbig,
  // whatsappbotaiagentbig,
  // whatsappbotlanguagebig,
  // whatsappbotanalyticsbig,
  // whatsappbotintegrationbig,
  // fastobannerimg,
  // fastoorderplacement,
  // fastoorderplacementw,
  // fastoproductrange,
  // fastoproductrangew,
  // fastodeliverylogistics,
  // fastodeliverylogisticsw,
  // huddleheartorderplacement,
  // huddleheartproductrange,
  // huddleheartdeliverylogistics,
  // fastomarketresearch,
  // fastocheckicon,
  // fastotechnology,
  // fastoresultsgrowth,
  // fastofutureprospects,
  // fastochallengessolutions,
  // Casestudyabout,
  // fastologo,
  // huddleheartlogo,
  // huddleheartbannerimg,
  // huddleheartabout,
  // huddleheartresearchimg,
  // huddleheartchallengessolutions,
  // huddleheartsafteyconcerns,
  // huddleheartnomeetup,
  // huddleheartmislead,
  // huddleheartimpacts,
  // huddleheartfutureprospects,
  // huddleheartunwantedmsg,
  // huddleheartbaddates,
  // huddlehearthookup,
  // huddleheartenhancedcommunication,
  // huddleheartuserprofile1,
  // huddleheartuserprofile2,
  // huddleheartuserprofile3,
  // huddleheartheartshape,
  // huddlehearteclipiseshape,
  // huddleheartaboutusimg,
  // huddleheartsimplesecure,
  // huddleheartswipe,
  // huddleheartrobustprofile,
  // huddleheartrobustmatching,
  // huddleheartrobustrespectful,
  // huddleheartgeographic,
  // huddleheartinnovation,
  // huddleheartstrongcommunities,
  // huddleheartgeographicw,
  // huddleheartinnovationw,
  // huddleheartstrongcommunitiesw,
  // huddleheartbalanceuser,
  // huddleheartusersafety,
  // huddleheartconclusion,
  // huddlehearticebreakers,
  // huddleheartvoicemessage,
  // huddleheartworkbanner,
  // huddleheartacquistion,
  // huddleheartmeaningful,
  // plywoodbazarmainlogo,
  // plywoodbazarbannerimg,
  // plywoodbazaraboutusimg,
  // plywoodbazaraboutusvs1,
  // plywoodbazaraboutusvs2,
  // plywoodbazaraboutusvs3,
  // plywoodbazarhandshake,
  plywoodleftshape,
  plywoodrightshape,
  plywoodsolutionimg,
  plywoodfeatureslogin,
  plywoodbazarfeaturesimg,
  plywoodbazarresultslide1,
  Rocaremainbannerimg,
  Rocarecasestudylogo,
  ROcareIndiaCSimg,
  Rocareaboutusimg,
  Rocareclientreq,
  Rocarelocationicon,
  Rocaresc1,
  Rocaresc2,
  Rocaresc3,
  Rocaresc4,
  Rocaresc5,
  Rocaresc6,
  Rocaresc7,
  Rocaresc8,
  Rocaresc9,
  Rocaresc10,
  Rocaresc11,
  Rocaresc12,
  Rocaresc13,
  Rocaresc14,
  Rocaresc15,
  Rocaresc16,
  Rocaresc17,
  Rocaresc18,
  Rocaresc19,
  Rocaresc20,
  RocareChallenges,
  NikkyPoremainbanner,
  NikkyPoremainlogo,
  NikkyPoreaboutus,
  NikkyPoregoals,
  NikkyPorechallengesandsolutions,
  NikkyPorevdslide1,
  NikkyPorevdslide2,
  NikkyPorevdslide3,
  NikkyPorevdslide4,
  NikkyPorevdslide5,
  NikkyPorevdslide6,
  NikkyPorevdslide7,
  NikkyPorevdslide8,
  NikkyPorevdslide9,
  NikkyPorevdslide10,
  NikkyPore,
  NikkyPorefeatures1,
  NikkyPorefeatures2,
  Deyormainbanner,
  Deyorlogo,
  Deyoraboutusimg,
  Deyorobjectiveimg,
  Deyoraboutlogo,
  Deyoraboutrytarrow,
  Deyorchallengesimg,
  Deyorsolutionsimg,
  Deyorfeatureimg1,
  Deyorfeatureimg2,
  Deyorfeatureimg3,
  Deyorfeatureimg4,
  Deyorfeatureline1,
  Deyorfeatureline2,
  Deyorfeatureline3,
  Deyorworkscreen1,
  Deyorworkscreen2,
  Deyorworkscreen3,
  Deyorworkscreen4,
  Deyorworkscreen5,
  Deyorworkscreen6,
  Deyorworkscreen7,
  Deyorworkscreen8,
  Samagri,
  Samagribannerimg,
  Samagrihalfelipse,
  Samagriaboutuslogo,
  Samagriaboutusimg,
  Samagriaboutussm1,
  Samagriaboutussm2,
  Samagriaboutussm3,
  Samagriaboutussm4,
  Samagriprojecthighlights,
  Samagriphighlightcircle,
  Samagrihighlight1,
  Samagrihighlight2,
  Samagrihighlight3,
  Samagrihighlight4,
  Samagriworkscreens,
  Samagrifeatures3step,
  Samagrifeatureseasyreplacement,
  Samagrifeaturessecure,
  Samagrifeaturescustomerservice,
  Samagrifeaturesoffers,
  Samagrifeaturesreasonableprice,
  Samagrichallenges,
  Samagrifeaturesimg,
  Turningpointmainbanner,
  Turningpointlogo,
  Turningpointbannerimg,
  Turningpointintroimg,
  Turningpointfeaturesimg,
  Turningpointchallengessolutionsimg,
  Turningpointportfolioslide1,
  Turningpointportfolioslide2,
  Turningpointportfolioslide3,
  Turningpointportfolioslide4,
  Turningpointportfolioslide5,
  Turningpointportfolioslide6,
  Turningpointportfolioslide7,
  Turningpointportfolioslide8,
  Turningpointportfolioslide9,
  Turningpointportfolioslide10,
  Turningpointportfolioslide11,
  Turningpointportfolioslide12,
  Turningpointportfolioslide13,
  Turningpointportfolioslide14,
  Turningpointportfolioslide15,

  DigitalMarketingaboutus,
  Digitalmktwhychoosebrain,
  Digitalmktwhychooseteam,
  Digitalmktwhychoosetrackrecord,
  Digitalmktwhychoosestrategies,
  Digitalmktwhychoosedatadriven,
  Digitalmktwhychoosetransparentreport,
  Digitalmktwhychoosecustomercentric,
  Digitalmktwhychoosecomprehensive,
  Digitalmktservicesvideoicon,
  Digitalmktservicesimg,
  Digitalmktbenefitsimg,
  Digitalmktanalytics,
  Digitalmktgoogleanalytics,
  Digitalmktsearchconsole,
  Digitalmkttagmanager,
  Digitalmktdatastudio,
  Digitalmktgoogleads,
  Digitalmktgooglebusiness,
  Digitalmktcasestudyimg,
  Digitalmktwhiteseo,
  Digitalmktdmsnew,
  Digitalmktleadgeneration,
  Digitalmktwebdesign,
  Digitalmktonlinereputation,
  Digitalmktconversion,
  Digitalmktboostup,
  Digitalmktsocialmedia,
  Digitalmktsocialmediainsta,
  Digitalmktsocialmediafb,
  Digitalmktsocialmediatw,
  Digitalmktsocialmedialinkedin,
  Digitalmktsocialmediawhatsapp,
  //  fintech image changed
  alliance1,
  capability1,
  functional1,
  collaboration1,
  accesscontrol1,
  certified1,
  ecosystem1,
  chooseicon1,
  realestmobappcustomize,
  realestmobappsprite,
  realestappdevelopmentimg,
  realestexplore,
  realestexplorey,
};
