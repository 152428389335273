import React from 'react'

import SoftwareAboutus from './_SoftwareDevelopmentComp/SoftwareAboutus/SoftwareAboutus'
// import SoftwareServices from './_SoftwareDevelopmentComp/SoftwareServices/SoftwareServices'
import SoftwareWhyChoose from './_SoftwareDevelopmentComp/SoftwareWhyChoose/SoftwareWhyChoose'
import SoftwareBenefits from './_SoftwareDevelopmentComp/SoftwareBenefits/SoftwareBenefits'
import WebTechnologies from './_SupplyChainManagement/Components/WebTechs/WebTechnologies'
import Industries from './_SoftwareDevelopmentComp/Industries/Industries'
import SuccessStories from './WebDevelopment/Components/SuccessStories/SuccessStories'
import SoftwareFaq from './_SoftwareDevelopmentComp/SoftwareFAQ/SoftwareFAQ'
import { Helmet } from 'react-helmet'
import Formfooter from './FormFooter'
import { images } from './Utility/Images'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SoftwareBanner from './_SoftwareDevelopmentComp/_CustomSoftwareBanner/_SoftwareBanner'


function SoftwareDevelopment() {
  return (
   <>
       <Helmet>
              <link rel="canonical" href="https://ebslon.com/custom-software-development" />
                <title>Custom Software Development Company in India</title>
    
                <meta
      name="description"
      content="Ebslon Infotech is the custom software development company in india
   that provide custom software, custom CRM, ERP software and IT Solutions in Delhi at Best prices."
    />
   


    <meta property="og:title" content="Custom Software Development Company in India" />
    <meta property="og:description" content="Ebslon Infotech is the Custom Software Development Company in India
   that provide custom software, custom CRM, ERP software and IT Solutions in Delhi at Best prices." />
    <meta property="og:type" content="Ebslon Infotech" />
    <meta property="og:url" content="https://ebslon.com/custom-software-development" />
    <meta property="og:image" content="https://ebslon.com/static/media/logo.7c0796c57daf5a0334b0.png" />
            </Helmet>

<SoftwareBanner/>
   <SoftwareAboutus/>
   {/* <SoftwareServices/> */}
<SoftwareWhyChoose/>
<SoftwareBenefits/>
<WebTechnologies/>
<Industries/>
<SuccessStories/>
<SoftwareFaq/>

          {/* discussSpecialists */}

          <section className="discuss-specialists py-40"
        style={{
          width: "100%",
          minHeight: "320px",
          backgroundImage: `url(${images.discussSpecialistsbg})`,
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row>
            <Col xl={10} className="discuss-specialists-content ">
              <h2 className="title">
              Ready for a Custom CRM Solution? Let's Talk About Your Project!
              </h2>
              <p className="desc">
                
              Connect with us to discuss your project's specific needs and requirements. At Ebslon Infotech, we're ready to help you simplify, engage, and grow.
              </p>

              <Link to="/contact-us" className="lets-talk-button">
              Reach Out Now
              </Link>
            </Col>
          </Row>
        </Container>
      </section>

    <div id='contactform'>

      <Formfooter />
    </div>
    
   </>
  )
}

export default SoftwareDevelopment
