
import React from 'react'
import styles from './styles.module.scss'
import Touch925 from '../../../../assets/images/WebDevelopment/successstories/Touch 925.webp'
import Brandcard from '../../../../assets/images/WebDevelopment/successstories/NFC Card.webp'
import StockTutor from '../../../../assets/images/WebDevelopment/successstories/Stock tutor.webp'
import { Link } from 'react-router-dom'

export default function SuccessStories() {

    const successarr = [
        {
            image:Touch925,
            name:"Touch925",
            f_tech:"ReactJs",
            b_tech:"NodeJs"

        },
        {
            image:Brandcard,
            name:"TheBrandCard",
            f_tech:"ReactJs",
            b_tech:"NodeJs"

        },
        {
            image:StockTutor,
            name:"StockTutor",
            f_tech:"ReactJs",
            b_tech:"NodeJs"
        },
    ]




  return (
    <>
    <section className={styles.success_sec}>
        <div className="container">
        <div className={`row ${styles.j_c_sb}`}>
<div className="col-xl-8 col-md-8">
    <div className={styles.heading_box}>
        <h2 className={styles.main_heading}>Success stories</h2>
       
    </div>
</div>
<div className={`col-xl-3 col-md-4 ${styles.j_c_e}`}>
    <div className={styles.buttn_holder}>
        <Link to="/case-studies" className={styles.em_buttn}>Explore More Stories</Link>
    </div>
</div>
            </div>

            <div className={`row ${styles.pt_40}`}>
        {
            successarr && successarr?.map((el,index)=>(
                <div className="col-xl-4 col-lg-4 col-md-6" key={index}>
                <div className={styles.success_card}>
    <div className={styles.image}>
        <img src={el.image} alt={`${el.name}`}  />
    </div>
    <div className={styles.content}>
        <h5>{el.name}</h5>
        <ul className={styles.buttns}>
       
    <li>
        <Link to="/portfolio">{el.f_tech} <span className={styles.circle}></span></Link>
    </li>
       
       <li>
        <Link to="/portfolio">{el.b_tech} <span className={styles.circle}></span></Link>
    </li>
       
    
    </ul>
    
    </div>
                </div>
              </div>
            ))
        }
              
           
            </div>
        </div>
    </section>
    
    </>
  )
}
